import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../../../utils/formatHelpers';

const styles = {
	tableHeadColor: {
		backgroundColor: 'rgba(0,0,0,0.05)',
	},
	descriptionCell: {},
	quantityCell: {},
	normalCell: {
		textAlign: 'right',
	},
	valueCell: {
		textAlign: 'right',
	},
};

const InvoicePreviewTable = ({
	classes,
	invoiceItems,
	totalVatHigh,
	totalVatLow,
	totalVatMarge,
	totalInclVat,
	totalExVat,
}) => (
	<React.Fragment>
		<Table>
			<TableHead className={classes.tableHeadColor}>
				<TableRow>
					<TableCell padding="none" colSpan="2">
						Omschrijving
					</TableCell>
					<TableCell padding="none" className={classes.normalCell}>
						Aantal
					</TableCell>
					<TableCell padding="none" className={classes.normalCell}>
						Prijs
					</TableCell>
					<TableCell padding="dense" className={classes.normalCell}>
						Subtotaal
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{invoiceItems.map((item, index) => (
					// eslint-disable-next-line
				<TableRow key={index}>
						<TableCell
							padding="none"
							className={classes.descriptionCell}
							colSpan="2"
						>
							{item.description}
						</TableCell>
						<TableCell padding="none" className={classes.valueCell}>
							{item.quantity}
						</TableCell>
						<TableCell padding="none" className={classes.valueCell}>
							{formatCurrency(parseInt(item.price, 10))}
						</TableCell>
						<TableCell padding="dense" className={classes.valueCell}>
							{formatCurrency(item.subtotal)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>

		<Table>
			<TableRow>
				<TableCell rowSpan={5} colSpan={1} />
				<TableCell rowSpan={5} colSpan={1} />
				<TableCell rowSpan={5} colSpan={1} />
				<TableCell colSpan={1}>
					<Typography variant="caption">Totaal exclusief BTW</Typography>
				</TableCell>
				<TableCell
					align="right"
					padding="dense"
					colSpan={2}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">
						{formatCurrency(totalExVat)}
					</Typography>
				</TableCell>
			</TableRow>
			{totalVatMarge ? (
				<TableRow>
					<TableCell colSpan={1}>
						<Typography variant="caption">BTW 0%</Typography>
					</TableCell>
					<TableCell
						align="right"
						padding="dense"
						colSpan={1}
						className="p-right-medium"
					>
						<Typography variant="subtitle2">
							{formatCurrency(totalVatMarge)}
						</Typography>
					</TableCell>
					<TableCell
						align="right"
						padding="dense"
						colSpan={1}
						className="p-right-medium"
					>
						<Typography variant="subtitle2">{formatCurrency(0)}</Typography>
					</TableCell>
				</TableRow>
			) : (
				<React.Fragment />
			)}
			{totalVatLow ? (
				<TableRow>
					<TableCell colSpan={1}>
						<Typography variant="caption">BTW 9%</Typography>
					</TableCell>
					<TableCell
						align="right"
						padding="dense"
						colSpan={2}
						className="p-right-medium"
					>
						<Typography variant="subtitle2">
							{formatCurrency(totalVatLow)}
						</Typography>
					</TableCell>
				</TableRow>
			) : (
				<React.Fragment />
			)}
			{totalVatHigh ? (
				<TableRow>
					<TableCell colSpan={1}>
						<Typography variant="caption">BTW 21%</Typography>
					</TableCell>
					<TableCell
						align="right"
						padding="dense"
						colSpan={2}
						className="p-right-medium"
					>
						<Typography variant="subtitle2">
							{formatCurrency(totalVatHigh)}
						</Typography>
					</TableCell>
				</TableRow>
			) : (
				<React.Fragment />
			)}
			<TableRow>
				<TableCell colSpan={1}>
					<Typography variant="caption">Totaal inclusief BTW</Typography>
				</TableCell>
				<TableCell
					align="right"
					padding="dense"
					colSpan={2}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">
						{formatCurrency(totalInclVat)}
					</Typography>
				</TableCell>
			</TableRow>
		</Table>
	</React.Fragment>
);

InvoicePreviewTable.propTypes = {
	classes: PropTypes.object.isRequired,
	invoiceItems: PropTypes.array.isRequired,
	totalVatHigh: PropTypes.string.isRequired,
	totalVatLow: PropTypes.string.isRequired,
	totalVatMarge: PropTypes.string.isRequired,
	totalInclVat: PropTypes.string.isRequired,
	totalExVat: PropTypes.string.isRequired,
};

export default withStyles(styles)(InvoicePreviewTable);
