import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	companyLogo: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		height: '200px',
		width: '250px',
	},
};

const InvoiceCompanyData = ({
	company: {
		name,
		companyImage,
		postalCode,
		tel,
		email,
		houseNumber,
		street,
		city,
		kvk,
		btw,
		iban,
		web,
	},
	preview,
	classes,
}) => (
	<Grid>
		{companyImage && !preview && (
			<div
				className={classes.companyLogo}
				style={{ backgroundImage: `url(${companyImage})` }}
			/>
		)}
		<Typography variant="h6">{name}</Typography>
		<Typography>{`${street} ${houseNumber}`}</Typography>
		<Typography>{city}</Typography>
		<Typography>{postalCode}</Typography>
		<Typography>{tel}</Typography>
		<Typography>{email}</Typography>
		<Typography>{web}</Typography>
		<div className="m-top-medium" />
		<div className="disp-flex">
			<Typography variant="subtitle2" className="m-right-xx-small">
				KVK Nr.
			</Typography>
			<Typography>{kvk}</Typography>
		</div>
		<div className="disp-flex">
			<Typography variant="subtitle2" className="m-right-xx-small">
				BTW Nr.
			</Typography>
			<Typography>{btw}</Typography>
		</div>
		<div className="disp-flex">
			<Typography variant="subtitle2" className="m-right-xx-small">
				Rekening IBAN
			</Typography>
			<Typography>{iban}</Typography>
		</div>
	</Grid>
);

InvoiceCompanyData.propTypes = {
	classes: PropTypes.object.isRequired,
	company: PropTypes.shape({
		name: PropTypes.string,
		street: PropTypes.string,
		houseNumber: PropTypes.string,
		city: PropTypes.string,
		postalCode: PropTypes.string,
		tel: PropTypes.string,
		email: PropTypes.string,
		web: PropTypes.string,
		kvk: PropTypes.string,
		iban: PropTypes.string,
	}),
	preview: PropTypes.bool,
};

InvoiceCompanyData.defaultProps = {
	company: {},
	preview: false,
};

export default withStyles(styles)(InvoiceCompanyData);
