import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import FirstTimeStepper from './FirstTimeStepper';
import initialSettings from '../../../utils/initialSettings';
import { storageRef } from '../../../services/firebase/firebase';

class FistTimeWizard extends React.Component {
	state = {
		open: true,
		activeStep: 0,
		name: '',
		street: '',
		postalCode: '',
		city: '',
		houseNumber: '',
		tel: '',
		email: '',
		web: '',
		kvk: '',
		btw: '',
		iban: '',
		loading: false,
	};

	componentDidMount = () => {
		const {
			loggedInUser: { email },
		} = this.props;

		this.setState({
			email,
		});
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleNext = () => {
		const steps = this.getSteps();
		if (steps.length - 1 === this.state.activeStep) {
			this.submitOrganisation();
		} else {
			this.setState((state) => ({
				activeStep: state.activeStep + 1,
			}));
		}
	};

	handleBack = () => {
		this.setState((state) => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
		});
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleChangeKeyValuePair = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	getSteps = () => {
		return [
			'Welkom',
			'Bedrijfsgegevens',
			'Contactgegevens',
			'Financielegegevens',
			'Logo uploaden',
			'Bevestigen',
		];
	};

	submitOrganisation = async () => {
		const { updateFirstTime } = this.props;
		const {
			name,
			street,
			postalCode,
			city,
			houseNumber,
			tel,
			email,
			web,
			kvk,
			btw,
			iban,
			companyImage,
		} = this.state;

		let organisation = {
			name,
			street,
			postalCode,
			city,
			houseNumber,
			tel,
			email,
			web,
			kvk,
			btw,
			iban,
		};
		const { saveOrganisation, userId, getAccountInfo } = this.props;

		if (companyImage) {
			await storageRef.child(`${userId}/companyImage.jpg`).put(companyImage);
			const url = await storageRef
				.child(`${userId}/companyImage.jpg`)
				.getDownloadURL();
			organisation = { ...organisation, companyImage: url };
		}
		this.setState({ loading: true });
		try {
			await saveOrganisation(
				organisation,
				'organisations',
				userId,
				'SAVE_ORGANISATION'
			);
			await updateFirstTime(userId);
			getAccountInfo();
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({ loading: false });
			this.handleClose();
		}
	};

	readyForNext = (activeStep) => {
		const {
			name,
			street,
			postalCode,
			city,
			houseNumber,
			tel,
			email,
			web,
			kvk,
			btw,
			iban,
		} = this.state;

		if (activeStep === 0) {
			return false;
		}
		if (activeStep === 1) {
			const step1valid =
				name === '' ||
				street === '' ||
				postalCode === '' ||
				city === '' ||
				houseNumber === '';
			return step1valid;
		}
		if (activeStep === 2) {
			const step2Valid =
				email === '' || tel === '' || !initialSettings.emailRegExp.test(email);
			return step2Valid;
		}
		if (activeStep === 3) {
			const step3Valid =
				kvk === '' ||
				btw === '' ||
				iban === '' ||
				!initialSettings.emailRegExp.test(email);
			return step3Valid;
		}
		return null;
	};

	render() {
		const steps = this.getSteps();
		const {
			activeStep,
			name,
			street,
			postalCode,
			city,
			houseNumber,
			tel,
			email,
			web,
			kvk,
			btw,
			iban,
			companyImage,
			loading,
		} = this.state;
		const { loggedInUser } = this.props;
		return (
			<Dialog open={this.state.open} fullWidth maxWidth="md">
				<FirstTimeStepper
					steps={steps}
					activeStep={activeStep}
					handleChange={this.handleChange}
					name={name}
					street={street}
					postalCode={postalCode}
					city={city}
					houseNumber={houseNumber}
					tel={tel}
					email={email}
					web={web}
					kvk={kvk}
					btw={btw}
					iban={iban}
					handleNext={this.handleNext}
					handleBack={this.handleBack}
					submitOrganisation={this.submitOrganisation}
					handleChangeKeyValuePair={this.handleChangeKeyValuePair}
					loggedInUser={loggedInUser}
					companyImage={companyImage}
					loading={loading}
					readyForNext={this.readyForNext}
				/>
			</Dialog>
		);
	}
}

FistTimeWizard.propTypes = {
	loggedInUser: PropTypes.object.isRequired,
	saveOrganisation: PropTypes.func.isRequired,
	updateFirstTime: PropTypes.func.isRequired,
	getAccountInfo: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

export default FistTimeWizard;
