import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
	tableHeadColor: {
		backgroundColor: 'rgba(0,0,0,0.05)',
	},
	descriptionCell: {
		width: '40%',
		paddingRight: '15px',
		paddingLeft: '10px',
	},
	quantityCell: {
		width: '8%',
		paddingRight: '15px',
	},
	normalCell: {
		width: '10%',
		paddingRight: '15px',
	},
};

const InvoiceEditTableHeader = ({ classes, typesOfWork }) => (
	<TableHead className={classes.tableHeadColor}>
		<TableRow>
			<TableCell className={classes.descriptionCell} padding="none">
				Omschrijving
			</TableCell>
			{typesOfWork && <TableCell padding="none">Type</TableCell>}
			<TableCell padding="none">BTW</TableCell>
			<TableCell padding="none" className={classes.quantityCell}>
				Aantal
			</TableCell>
			<TableCell padding="none" className={classes.normalCell}>
				Stukprijs
			</TableCell>
			<TableCell padding="none" className={classes.normalCell}>
				Korting
			</TableCell>
			<TableCell padding="none" className={classes.normalCell}>
				Subtotaal
			</TableCell>
			<TableCell padding="none" className={classes.normalCell} />
		</TableRow>
	</TableHead>
);

InvoiceEditTableHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	typesOfWork: PropTypes.array,
};

InvoiceEditTableHeader.defaultProps = {
	typesOfWork: null,
};

export default withStyles(styles)(InvoiceEditTableHeader);
