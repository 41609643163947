/* eslint-disable */

const initialSettings = {
	specialCharRegexp: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
	emailRegExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	containsNumber: /\d/,
	userRoles: [ 'werknemer', 'planner'],
};

export default initialSettings;
