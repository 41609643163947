import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import InvoiceEditTableHeader from '../../createInvoiceComponents/InvoiceEditTable/InvoiceEditTableHeader';
import InvoiceEditTableItem from '../../createInvoiceComponents/InvoiceEditTable/InvoiceEditTableItem';
import InvoiceEditTotals from '../../createInvoiceComponents/InvoiceEditTable/InvoiceEditTotals';

const styles = {
	formContainer: {
		height: '400px',
		minHeight: '400px',
		overflowY: 'scroll',
	},
};

const QuoteEditTable = ({
	invoiceItems,
	classes,
	removeInvoiceItem,
	addInvoiceItem,
	handleChangeInvoiceItem,
	totals,
	vatPercentages,
	typesOfWork,
}) => (
	<React.Fragment>
		<div className={classes.formContainer}>
			<Table>
				<InvoiceEditTableHeader typesOfWork={typesOfWork} />
				<TableBody>
					{invoiceItems.map((item, index) => (
						<InvoiceEditTableItem
							// eslint-disable-next-line
							key={index}
							item={item}
							index={index}
							removeInvoiceItem={removeInvoiceItem}
							addInvoiceItem={addInvoiceItem}
							handleChangeInvoiceItem={handleChangeInvoiceItem}
							vatPercentages={vatPercentages}
							typesOfWork={typesOfWork}
						/>
					))}
					<InvoiceEditTotals {...totals} typesOfWork={typesOfWork} />
				</TableBody>
			</Table>
		</div>
	</React.Fragment>
);

QuoteEditTable.propTypes = {
	classes: PropTypes.object.isRequired,
	totals: PropTypes.object.isRequired,
	invoiceItems: PropTypes.array.isRequired,
	vatPercentages: PropTypes.array.isRequired,
	removeInvoiceItem: PropTypes.func.isRequired,
	addInvoiceItem: PropTypes.func.isRequired,
	handleChangeInvoiceItem: PropTypes.func.isRequired,
	typesOfWork: PropTypes.array.isRequired,
};

export default withStyles(styles)(QuoteEditTable);
