import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { formatCurrency } from '../../../../utils/formatHelpers';

const QuoteItem = ({ onderdeel, styles }) => {
	return (
		<View style={styles.tableRow}>
			<Text style={styles.invoiceItemWidthLarge}>{onderdeel.description}</Text>
			<Text style={styles.invoiceItemWidthSmall}>{onderdeel.quantity}</Text>
			<Text style={styles.invoiceItemWidthSmall}>
				{formatCurrency(parseFloat(onderdeel.price))}
			</Text>
			<Text style={styles.invoiceItemWidthSmall}>
				{formatCurrency(onderdeel.subtotal)}
			</Text>
		</View>
	);
};

QuoteItem.propTypes = {
	onderdeel: PropTypes.object.isRequired,
	styles: PropTypes.object.isRequired,
};

export default QuoteItem;
