import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { formatDate } from '../../../../utils/formatHelpers';

const styles = StyleSheet.create({
	image: {
		maxWidth: 200,
		maxHeight: 150,
		marginBottom: 10,
	},
	title: {
		fontSize: 12,
		marginBottom: 2,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
	viewMargin: {
		marginBottom: 10,
	},
	text: {
		fontSize: 10,
		marginBottom: 2,
		fontFamily: 'Lato',
	},
	labelBold: {
		fontSize: 10,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
	invoiceDate: {
		fontSize: 8,
		marginBottom: 18,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 700,
	},
});

const CompanyPDFInfo = ({ account, documentToRender, documentType }) => {
	return (
		<View>
			<View>
				<Text style={styles.invoiceDate}>
					{documentType === 'quote'
						? `Offertedatum ${formatDate(documentToRender.quoteDate)}`
						: `Factuurdatum ${formatDate(documentToRender.invoiceDate)}`}
				</Text>
			</View>
			<View>
				{/* enable cors / add domain to google storage */}
				{account.companyImage && (
					<Image style={styles.image} src={account.companyImage} />
				)}
			</View>

			<View style={styles.viewMargin}>
				<Text style={styles.title}>{account.name}</Text>
				<Text
					style={styles.text}
				>{`${account.street} ${account.houseNumber}`}</Text>
				<Text style={styles.text}>
					{account.postalCode} {account.city}
				</Text>
				<Text style={styles.text}>{account.email}</Text>
				<Text style={styles.text}>{account.tel}</Text>
				<Text style={styles.text}>{account.web}</Text>
			</View>

			<View>
				<Text style={styles.text}>
					<Text style={styles.labelBold}>KVK Nr. </Text>
					{account.kvk}
				</Text>
				<Text style={styles.text}>
					<Text style={styles.labelBold}>BTW Nr. </Text>
					{account.btw}
				</Text>
				<Text style={styles.text}>
					<Text style={styles.labelBold}>Rekening Nr. </Text>
					{account.iban}
				</Text>
			</View>
		</View>
	);
};

CompanyPDFInfo.propTypes = {
	account: PropTypes.object.isRequired,
	documentToRender: PropTypes.object.isRequired,
	documentType: PropTypes.string.isRequired,
};

export default CompanyPDFInfo;
