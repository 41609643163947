import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';

const Header = ({
	classes,
	open,
	handleDrawerOpen,
	history,
	doSignOut,
	loggedInUser,
}) => {
	return (
		<AppBar
			position="fixed"
			className={classNames(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar
				disableGutters={!open}
				className={classNames(classes.container, {
					[classes.containerShift]: open,
				})}
			>
				<IconButton
					color="inherit"
					aria-label="Open drawer"
					onClick={handleDrawerOpen}
					className={classNames(classes.menuButton, {
						[classes.hide]: open,
					})}
				>
					<MenuIcon />
				</IconButton>
				<UserMenu
					history={history}
					doSignOut={doSignOut}
					loggedInUser={loggedInUser}
				/>
			</Toolbar>
		</AppBar>
	);
};

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	handleDrawerOpen: PropTypes.func.isRequired,
	doSignOut: PropTypes.func.isRequired,
	loggedInUser: PropTypes.object.isRequired,
};

export default Header;
