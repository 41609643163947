const vatPercentages = [
	{
		percentage: 1.21,
		label: '21% BTW hoog',
	},
	{
		percentage: 1.09,
		label: '9% BTW laag',
	},
	{
		percentage: 1,
		label: '0% BTW Marge',
	},
	{
		percentage: 1,
		label: '0% Geen BTW',
	},
];

export default vatPercentages;
