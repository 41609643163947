import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
	saveToCollection,
	updateDocument,
	deleteDocument,
} from '../services/firebase/firebaseMutations';
import { getDocument } from '../services/firebase/firebaseQueries';
import CreateQuote from './CreateQuote';
import ActionPaper from '../components/actionPaper/ActionPaper';
import EnhancedTable from '../components/enhancedTable/EnhancedTable';
import { openSnackbar } from '../redux/actions/snackbarActions';
import { rowsQuote } from '../utils/TableHeads';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import { QUOTES, LANDING, CREATEQUOTE, EDITQUOTE } from '../constants/routes';

class Quotes extends React.Component {
	state = {};

	render() {
		const {
			history,
			quotes,
			clients,
			account,
			authUser,
			saveQuote,
			updateQuote,
			deleteDoc,
			getNewQuote,
			doSave,
			getNewClient,
			authUser: { uid },
		} = this.props;
		return (
			<Switch>
				<Route
					exact
					path={QUOTES.path}
					component={() => (
						<React.Fragment>
							<Breadcrumbs
								pathArray={[
									{
										link: LANDING.path,
										title: LANDING.label,
									},
									{
										link: QUOTES.path,
										title: QUOTES.label,
									},
								]}
							/>

							<ActionPaper
								action={() => history.push(CREATEQUOTE.path)}
								actionName="Nieuwe Offerte"
								title="Offertes"
								icon="Add"
							>
								<EnhancedTable
									data={quotes}
									rows={rowsQuote}
									datatype="quotes"
									deleteDoc={deleteDoc}
									getNewQuote={getNewQuote}
									account={account}
								/>
							</ActionPaper>
						</React.Fragment>
					)}
				/>
				<Route
					exact
					path={EDITQUOTE.path}
					component={(props) => (
						<CreateQuote
							{...props}
							clients={clients}
							account={account}
							authUser={authUser}
							saveQuote={saveQuote}
							updateQuote={updateQuote}
							quotes={quotes}
							getNewQuote={getNewQuote}
							doSave={doSave}
							getNewClient={getNewClient}
							uid={uid}
						/>
					)}
				/>
				<Route
					exact
					path={CREATEQUOTE.path}
					component={(props) => (
						<CreateQuote
							{...props}
							clients={clients}
							account={account}
							authUser={authUser}
							saveQuote={saveQuote}
							updateQuote={updateQuote}
							quotes={quotes}
							getNewQuote={getNewQuote}
							doSave={doSave}
							getNewClient={getNewClient}
							uid={uid}
						/>
					)}
				/>
			</Switch>
		);
	}
}

Quotes.propTypes = {
	quotes: PropTypes.array.isRequired,
	clients: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	account: PropTypes.object,
	authUser: PropTypes.object.isRequired,
	saveQuote: PropTypes.func.isRequired,
	deleteDoc: PropTypes.func.isRequired,
	updateQuote: PropTypes.func.isRequired,
	getNewQuote: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	doSave: PropTypes.func.isRequired,
};

Quotes.defaultProps = {
	account: {},
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	account: state.Session.account,
	loggedInUser: state.Session.loggedInUser,
	clients: state.Clients.clients,
	quotes: state.Quotes.quotes,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	saveQuote: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	updateQuote: (data, collection, documentId) =>
		dispatch(updateDocument(data, collection, documentId)),
	deleteDoc: (collection, document, actionName) =>
		dispatch(deleteDocument(collection, document, actionName)),
	getNewQuote: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
	doSave: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	getNewClient: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Quotes);
