import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// relative imports
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import CookieConsent from 'react-cookie-consent';
import LandingPage from '../pages/Landing';
import Invoices from '../pages/Invoices';
import * as routes from '../constants/routes';
import PageLayout from '../components/pageLayout/PageLayout';
import withAuthentication from '../components/session/withAuthentication';
import SnackbarComponent from '../components/snackbarNotifications/SnackbarNotifications';
import CreateClient from '../pages/CreateClient';
import Clients from '../pages/Clients';
import Quotes from '../pages/Quotes';
import Login from '../pages/Login';
import Settings from '../pages/Settings';
import PrivacyPolicy from '../pages/PrivacyPolicy';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://1be0992543f441c49151e118c346ea39@sentry.io/1504205',
	});
	ReactGA.initialize('UA-111490263-1');
	ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends React.Component {
	state = {};

	componentDidCatch(error, errorInfo) {
		if (process.env.NODE_ENV === 'production') {
			Sentry.withScope((scope) => {
				Object.keys(errorInfo).forEach((key) => {
					scope.setExtra(key, errorInfo[key]);
				});
				Sentry.captureException(error);
			});
		}
	}

	render() {
		return (
			<Router basename="/fatura">
				<CookieConsent buttonText="Accepteren">
					<p style={{ textAlign: 'center' }}>
						Deze pagina gebruikt cookies om de gebruikerservaring te verbeteren
					</p>
				</CookieConsent>
				<Switch>
					<Route
						path={routes.LANDING.path}
						component={(props) => (
							<PageLayout>
								<Switch>
									<Route
										exact
										path={routes.LANDING.path}
										component={() => <LandingPage {...props} />}
									/>
									<Route
										path={routes.INVOICES.path}
										component={(routeProps) => <Invoices {...routeProps} />}
									/>
									<Route
										path={routes.QUOTES.path}
										component={(routeProps) => <Quotes {...routeProps} />}
									/>
									<Route
										exact
										path={routes.CLIENTS.path}
										component={() => <Clients {...props} />}
									/>
									<Route
										exact
										path={routes.CREATECLIENT.path}
										component={() => <CreateClient {...props} />}
									/>
									<Route
										exact
										path={routes.EDITCLIENT.path}
										component={(routeProps) => <CreateClient {...routeProps} />}
									/>
									<Route
										exact
										path={routes.SETTINGS.path}
										component={() => <Settings {...props} />}
									/>
									<Route component={() => <LandingPage {...props} />} />
								</Switch>
							</PageLayout>
						)}
					/>
					<Route
						path={routes.LOGIN.path}
						component={(props) => <Login {...props} />}
					/>
					<Route
						path={routes.PRIVACY.path}
						component={(props) => <PrivacyPolicy {...props} />}
					/>
					<Route component={(props) => <Login {...props} />} />
				</Switch>
				<SnackbarComponent />
			</Router>
		);
	}
}

export default withAuthentication(App);
