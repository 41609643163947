import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../../../utils/formatHelpers';
import AutoCompleteField from '../../AutoCompleteField';

const styles = {
	descriptionCell: {
		width: '40%',
		paddingRight: '15px',
		paddingLeft: '10px',
	},
	quantityCell: {
		width: '8%',
		paddingRight: '15px',
	},
	normalCell: {
		width: '10%',
		paddingRight: '15px',
	},
};

const InvoiceEditTableItem = ({
	item: { description, VAT, quantity, price, discount, subtotal, typeOfWork },
	addInvoiceItem,
	removeInvoiceItem,
	index,
	handleChangeInvoiceItem,
	handleAutoComplete,
	invoiceOptions,
	classes,
	vatPercentages,
	typesOfWork,
}) => (
	<TableRow>
		<TableCell padding="none" className={classes.descriptionCell}>
			{typesOfWork ? (
				<TextField
					id="description"
					value={description}
					placeholder="omschrijving"
					fullWidth
					margin="normal"
					onChange={handleChangeInvoiceItem(index, 'description')}
				/>
			) : (
				<AutoCompleteField
					handleChangeInvoiceItem={handleChangeInvoiceItem}
					handleAutoComplete={handleAutoComplete}
					index={index}
					description={description}
					invoiceOptions={invoiceOptions}
					value={description}
				/>
			)}
		</TableCell>
		{typesOfWork && (
			<TableCell padding="none" className={classes.normalCell}>
				<Select
					native
					value={typeOfWork}
					onChange={handleChangeInvoiceItem(index, 'typeOfWork')}
					style={{ marginTop: '8px' }}
				>
					{typesOfWork.map((type) => (
						<option key={type} value={type}>
							{type}
						</option>
					))}
				</Select>
			</TableCell>
		)}
		<TableCell padding="none" className={classes.normalCell}>
			<Select
				native
				value={VAT}
				onChange={handleChangeInvoiceItem(index, 'VAT')}
				style={{ marginTop: '8px' }}
			>
				{vatPercentages.map((vatItem) => (
					<option key={vatItem.label} value={vatItem.percentage}>
						{vatItem.label}
					</option>
				))}
			</Select>
		</TableCell>
		<TableCell padding="none" className={classes.quantityCell}>
			<TextField
				id="quantity"
				value={quantity}
				type="number"
				onChange={handleChangeInvoiceItem(index, 'quantity')}
				placeholder="aantal"
				className="input-right"
				margin="normal"
			/>
		</TableCell>
		<TableCell padding="none" className={classes.normalCell}>
			<TextField
				id="price"
				value={price}
				onChange={handleChangeInvoiceItem(index, 'price')}
				placeholder="prijs"
				InputProps={{
					startAdornment: <InputAdornment position="start">€</InputAdornment>,
				}}
				margin="normal"
				className="input-right"
				type="number"
			/>
		</TableCell>
		<TableCell padding="none" className={classes.normalCell}>
			<TextField
				id="discount"
				placeholder="korting"
				value={discount}
				onChange={handleChangeInvoiceItem(index, 'discount')}
				margin="normal"
				type="number"
				className="input-right"
				InputProps={{
					endAdornment: <InputAdornment position="start">%</InputAdornment>,
				}}
			/>
		</TableCell>
		<TableCell padding="none" className={classes.normalCell}>
			<TextField
				id="subtotal-ex"
				value={subtotal ? formatCurrency(subtotal, 'noEuro') : '0,00'}
				margin="normal"
				className="input-right"
				InputProps={{
					startAdornment: <InputAdornment position="start">€</InputAdornment>,
				}}
				style={{ pointerEvents: 'none' }}
			/>
		</TableCell>
		<TableCell padding="none" className={classes.normalCell}>
			<div className="disp-flex">
				<IconButton color="primary" onClick={addInvoiceItem}>
					<AddIcon fontSize="small" />
				</IconButton>
				<IconButton color="primary" onClick={() => removeInvoiceItem(index)}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</div>
		</TableCell>
	</TableRow>
);

InvoiceEditTableItem.propTypes = {
	item: PropTypes.shape({
		description: PropTypes.string,
		VAT: PropTypes.string,
		quantity: PropTypes.string,
		price: PropTypes.string,
		discount: PropTypes.string,
	}),
	addInvoiceItem: PropTypes.func.isRequired,
	removeInvoiceItem: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	handleChangeInvoiceItem: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	vatPercentages: PropTypes.array.isRequired,
	typesOfWork: PropTypes.array,
};

InvoiceEditTableItem.defaultProps = {
	item: {
		description: '',
		VAT: '',
		quantity: '',
		price: '',
		discount: '',
		typeOfWork: '',
	},
	typesOfWork: null,
};

export default withStyles(styles)(InvoiceEditTableItem);
