import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, StyleSheet, Font, View } from '@react-pdf/renderer';
import CompanyPDFInfo from './components/CompanyPDFInfo';
import ClientPDFInfo from './components/ClientPDFInfo';
import InvoicePDFTable from './components/InvoicePDFTable';
import InvoiceRemarks from './components/InvoiceRemarks';
import InvoicePDFFooter from './components/InvoicePDFFooter';
import RenderDiscount from './components/RenderDiscount';
import colors from '../../../utils/colors';

const styles = StyleSheet.create({
	page: {
		padding: 30,
	},
	companyClientContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		marginBottom: 10,
	},
	border: {
		marginTop: 10,
		borderBottomWidth: 2,
		borderBottomColor: colors.primary,
		borderBottomStyle: 'solid',
	},
	invoiceDate: {
		fontSize: 8,
		marginBottom: 18,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 700,
	},
});

Font.register({
	family: 'Lato',
	fonts: [
		{ src: 'https://egemen.nl/assets/Fonts/Lato-Regular.ttf' }, // font-style: normal, font-weight: normal
		{
			src: 'https://egemen.nl/assets/Fonts/Lato-Italic.ttf',
			fontStyle: 'italic',
		},
		{
			src: 'https://egemen.nl/assets/Fonts/Lato-Bold.ttf',
			fontStyle: 'bold',
			fontWeight: 500,
		},
		{
			src: 'https://egemen.nl/assets/Fonts/Lato-Black.ttf',
			fontStyle: 'bold',
			fontWeight: 700,
		},
		{
			src: 'https://egemen.nl/assets/Fonts/Lato-Light.ttf',
			fontWeight: 200,
		},
	],
});

const InvoiceTemplate = ({ invoiceToRender, account }) => {
	return (
		<Document>
			<Page style={styles.page} size="A4" wrap>
				<View style={styles.companyClientContainer}>
					<CompanyPDFInfo
						account={account}
						documentToRender={invoiceToRender}
						documentType="invoice"
					/>
					<ClientPDFInfo
						documentToRender={invoiceToRender}
						documentType="invoice"
					/>
				</View>
				<InvoicePDFTable invoiceToRender={invoiceToRender} />
				<View style={styles.border} />
				<InvoiceRemarks invoiceToRender={invoiceToRender} />
				<RenderDiscount invoiceToRender={invoiceToRender} />
				<InvoicePDFFooter invoiceToRender={invoiceToRender} account={account} />
			</Page>
		</Document>
	);
};

InvoiceTemplate.propTypes = {
	invoiceToRender: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
};

export default InvoiceTemplate;
