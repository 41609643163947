import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const ContactInfo = ({ account }) => {
	return (
		<React.Fragment>
			<Typography variant="subtitle2">Contact gegevens:</Typography>
			<Typography variant="caption">
				{account.email}
				<br />
				{account.tel}
				<br />
				{account.web}
			</Typography>
		</React.Fragment>
	);
};

ContactInfo.propTypes = {
	account: PropTypes.object.isRequired,
};

export default ContactInfo;
