import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CLIENTS } from '../../constants/routes';

class ClientCreateForm extends Component {
	state = {
		name: '',
		street: '',
		houseNumber: '',
		city: '',
		postalCode: '',
		country: '',
		email: '',
		editMode: false,
		// contactPerson: {
		// 	firstName: '',
		// 	initials: '',
		// 	lastName: '',
		// 	email: '',
		// 	phoneNumber: '',
		// 	cellNumber: '',
		// },
	};

	componentDidMount = () => {
		const { clientId } = this.props;
		if (clientId) {
			this.filterClients();
			this.setState({ editMode: true });
		}
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.clientId) {
			if (prevProps.clients.length !== this.props.clients.length) {
				this.filterClients();
			}
		}
	};

	filterClients = () => {
		const { clients, clientId } = this.props;
		const clientToEdit = clients.filter((client) => client.id === clientId);
		this.setState({ ...clientToEdit[0] });
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	saveClient = async () => {
		const {
			doSave,
			uid,
			doEdit,
			clientId,
			getNewClient,
			history,
			fromDialog,
		} = this.props;
		const {
			name,
			street,
			houseNumber,
			city,
			postalCode,
			country,
			email,
			editMode,
		} = this.state;
		const client = {
			name,
			street,
			houseNumber,
			city,
			postalCode,
			country,
			email,
		};
		let id = '';
		if (!editMode) {
			id = await doSave(client, 'clients', uid, 'save_client');
			await getNewClient('clients', id, 'new_client');
		} else {
			doEdit(client, 'clients', clientId);
			await getNewClient('clients', clientId, 'updated_client');
		}
		if (!fromDialog) {
			history.push(CLIENTS.path);
		}
	};

	controlButtonState = () => {
		const { name, street, houseNumber, city, postalCode, country } = this.state;
		if (name && street && houseNumber && city && postalCode && country) {
			return false;
		}
		return true;
	};

	render() {
		const { fromDialog, handleAddClientDialogClose } = this.props;
		const {
			name,
			street,
			houseNumber,
			city,
			postalCode,
			country,
			email,
		} = this.state;
		const buttonDisabled = this.controlButtonState();
		return (
			<Grid container>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="name"
						label="Naam / Bedrijf"
						value={name}
						onChange={this.handleChange('name')}
						margin="normal"
						type="text"
					/>
				</Grid>
				<Grid item xs={8}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="email"
						label="E-mail"
						value={email}
						onChange={this.handleChange('email')}
						margin="normal"
						type="text"
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid xs={6} item>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="street"
								label="Straat"
								value={street}
								onChange={this.handleChange('street')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid xs={2} item>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="houseNumber"
								label="Huis Nr."
								value={houseNumber}
								onChange={this.handleChange('houseNumber')}
								margin="normal"
								type="text"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="postalCode"
								label="Postcode"
								value={postalCode}
								onChange={this.handleChange('postalCode')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="city"
								label="Stad"
								value={city}
								onChange={this.handleChange('city')}
								margin="normal"
								type="text"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="country"
						label="Land"
						value={country}
						onChange={this.handleChange('country')}
						margin="normal"
						type="text"
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="m-top-medium m-bottom-medium" />
					<Grid container justify="flex-end">
						{fromDialog ? (
							<React.Fragment>
								<Button onClick={handleAddClientDialogClose} color="primary">
									Annuleren
								</Button>
								<Button
									onClick={this.saveClient}
									disabled={buttonDisabled}
									color="primary"
								>
									Opslaan
								</Button>
							</React.Fragment>
						) : (
							<Button
								variant="contained"
								color="primary"
								disabled={buttonDisabled}
								onClick={this.saveClient}
							>
								Opslaan
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

ClientCreateForm.propTypes = {
	fromDialog: PropTypes.bool,
	handleAddClientDialogClose: PropTypes.func,
	clientId: PropTypes.string,
	clients: PropTypes.array.isRequired,
	uid: PropTypes.string.isRequired,
	doSave: PropTypes.func.isRequired,
	doEdit: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

ClientCreateForm.defaultProps = {
	fromDialog: false,
	handleAddClientDialogClose: () => {},
	clientId: '',
};

export default ClientCreateForm;
