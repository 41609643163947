import React from 'react';
import { Grid, Fade, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const Step5 = ({ handleChange, tel, email, web }) => {
	return (
		<Fade in timeout={200}>
			<Grid container>
				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								id="email"
								readOnly
								label="E-mail"
								value={email}
								onChange={handleChange('email')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="telephone"
								label="Telefoonnummer"
								value={tel}
								onChange={handleChange('tel')}
								margin="normal"
								type="text"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="website"
						label="Website"
						value={web}
						onChange={handleChange('web')}
						margin="normal"
						type="text"
					/>
				</Grid>
			</Grid>
		</Fade>
	);
};

Step5.propTypes = {
	handleChange: PropTypes.func.isRequired,
	tel: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	web: PropTypes.string.isRequired,
};

export default Step5;
