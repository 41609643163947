import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import { BlobProvider } from '@react-pdf/renderer';
import sendMail from '../../../services/emailService/sendMail';
import { formatCurrency } from '../../../utils/formatHelpers';
import InvoiceTemplate from '../../../services/pdfService/invoiceTemplate/InvoiceTemplate';
import QuoteTemplate from '../../../services/pdfService/invoiceTemplate/QuoteTemplate';
import initialSettings from '../../../utils/initialSettings';

const SendEmailDialog = ({
	openEmailDialog,
	closeDialog,
	invoiceToEmail,
	account,
	datatype,
}) => {
	const [values, setValues] = React.useState({
		emailTo: invoiceToEmail.client.value.email,
		emailBody: '',
		subject: '',
	});

	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleSubmit = (blob) => {
		const emailData = {
			accountName: account.name,
			emailFrom: account.email,
			emailTo: values.emailTo,
			emailBody: values.emailBody,
			subject: values.subject,
			clientLabel: invoiceToEmail.client.label,
			fileName: `${datatype === 'invoices' ? 'Factuur' : 'Offerte'} ${
				datatype === 'invoices'
					? invoiceToEmail.invoiceNumber
					: invoiceToEmail.quoteNumber
			}.pdf`,
			totalInvoiceAmount: formatCurrency(invoiceToEmail.totalInclVat),
		};
		sendMail(emailData, blob);
	};
	const emailValid = !initialSettings.emailRegExp.test(values.emailTo);
	return (
		<Dialog open={openEmailDialog} onClose={closeDialog}>
			<DialogTitle id="alert-dialog-title">Email Versturen</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Email Versturen
				</DialogContentText>
				<TextField
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					fullWidth
					id="emailTo"
					label="Naar"
					value={values.emailTo}
					onChange={handleChange('emailTo')}
					margin="normal"
					type="text"
					error={emailValid}
					helperText={emailValid && 'controleer e-mail adres'}
				/>
				<TextField
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					fullWidth
					id="subject"
					label="Onderwerp"
					value={values.subject}
					onChange={handleChange('subject')}
					margin="normal"
					type="text"
				/>
				<TextField
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					fullWidth
					multiline
					rows="4"
					id="emailBody"
					label="Bericht"
					value={values.emailBody}
					onChange={handleChange('emailBody')}
					margin="normal"
					type="text"
				/>
			</DialogContent>
			{invoiceToEmail && (
				<BlobProvider
					document={
						datatype === 'invoices' ? (
							<InvoiceTemplate
								invoiceToRender={invoiceToEmail}
								account={account}
							/>
						) : (
							<QuoteTemplate quoteToRender={invoiceToEmail} account={account} />
						)
					}
				>
					{({ blob, url, loading, error }) => (
						<React.Fragment>
							<DialogActions>
								<Button onClick={closeDialog} color="primary">
									Annuleren
								</Button>
								<Button
									onClick={() => handleSubmit(blob)}
									disabled={loading || emailValid}
									color="primary"
									autoFocus
								>
									Verzenden
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				</BlobProvider>
			)}
		</Dialog>
	);
};

SendEmailDialog.propTypes = {
	openEmailDialog: PropTypes.bool.isRequired,
	closeDialog: PropTypes.func.isRequired,
	invoiceToEmail: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
	datatype: PropTypes.string.isRequired,
};

export default SendEmailDialog;
