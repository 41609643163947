import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const AdresInfo = ({ account }) => {
	return (
		<React.Fragment>
			<Typography variant="subtitle2" component="div">
				Adresgegevens:
			</Typography>
			<Typography variant="caption" component="div">
				{`${account.street} ${account.houseNumber}`}
				<br />
				{account.postalCode}
				<br />
				{account.city}
			</Typography>
		</React.Fragment>
	);
};

AdresInfo.propTypes = {
	account: PropTypes.object.isRequired,
};

export default AdresInfo;
