import React from 'react';
import PropTypes from 'prop-types';
import {
	DialogActions,
	Dialog,
	Button,
	DialogContent,
} from '@material-ui/core';
import EditAccountDetailsForm from './EditAccountDetailsForm';

const EditAccountDetails = ({
	editMode,
	toggleEditMode,
	accountInfo,
	handleChange,
	handleChangeKeyValuePair,
	updateAccountDetails,
}) => (
	<Dialog open={editMode} onClose={toggleEditMode} fullWidth maxWidth="lg">
		<DialogContent>
			<EditAccountDetailsForm
				accountInfo={accountInfo}
				handleChange={handleChange}
				handleChangeKeyValuePair={handleChangeKeyValuePair}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={toggleEditMode} color="default">
				Annuleren
			</Button>
			<Button
				onClick={updateAccountDetails}
				color="primary"
				variant="contained"
			>
				Wijzigen
			</Button>
		</DialogActions>
	</Dialog>
);

EditAccountDetails.propTypes = {
	editMode: PropTypes.bool.isRequired,
	toggleEditMode: PropTypes.func.isRequired,
	accountInfo: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	updateAccountDetails: PropTypes.func.isRequired,
	handleChangeKeyValuePair: PropTypes.func.isRequired,
};

export default EditAccountDetails;
