import {
	updateObjectInArray,
	insertItem,
	removeItem,
} from '../../helpers/immutableHelpers';
// Don't forget to update the defaultState when adding new props
const defaultState = {
	invoices: [],
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function invoicesReducer(state = defaultState, action) {
	switch (action.type) {
		case 'GET_ALL_INVOICES_DONE':
			return {
				...state,
				invoices: action.data,
			};
		case 'NEW_INVOICE_DONE':
			return {
				...state,
				invoices: insertItem(state.invoices, action.data),
			};
		case 'UPDATED_INVOICE_DONE':
			return {
				...state,
				invoices: updateObjectInArray(state.invoices, action.data),
			};
		case 'DELETE_DOC_FROM_INVOICES_DONE':
			return {
				...state,
				invoices: removeItem(state.invoices, action.data),
			};
		default:
			return state;
	}
}

export default invoicesReducer;
