import generateActionTypes from '../../utils/generateActionTypes';
import { dbf } from './firebase';
import { openSnackbar } from '../../redux/actions/snackbarActions';

export const getAllFromColl = (collection, actionName) => async (dispatch) => {
	const actionTypes = generateActionTypes(actionName);
	dispatch({
		type: actionTypes.REQUEST,
	});
	const collectionRef = dbf.collection(collection);
	const collectionArray = [];
	collectionRef
		.get()
		.then((snapshot) => {
			snapshot.forEach((doc) => {
				const withId = {
					docId: doc.id,
					...doc.data(),
				};
				collectionArray.push(withId);
			});
			return dispatch({
				type: actionTypes.DONE,
				data: collectionArray,
			});
		})
		.catch((error) => {
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};

export const getDocument = (collectionName, documentId, actionName) => async (
	dispatch
) => {
	const actionTypes = generateActionTypes(actionName);
	dispatch({
		type: actionTypes.REQUEST,
	});
	const docRef = dbf.collection(collectionName).doc(documentId);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				const withId = {
					id: doc.id,
					...doc.data(),
				};
				dispatch({
					type: actionTypes.DONE,
					data: withId,
				});
				return doc.data();
			}
			dispatch(openSnackbar(`No document found`, 'error'));
			return false;
		})
		.catch((error) => {
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};

export const queryByOwner = (userId, collection, actionName) => async (
	dispatch
) => {
	const actionTypes = generateActionTypes(actionName);
	dispatch({
		type: actionTypes.REQUEST,
	});
	const docRef = dbf.collection(collection).where('owner', '==', userId);
	const collectionArray = [];
	return docRef
		.get()
		.then((snapshot) => {
			snapshot.forEach((doc) => {
				const withId = {
					id: doc.id,
					...doc.data(),
				};
				collectionArray.push(withId);
			});
			return dispatch({
				type: actionTypes.DONE,
				data: collectionArray,
			});
		})
		.catch((error) => {
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};
