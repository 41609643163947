import Login from './loginReducer';
import Session from './sessionReducer';
import Clients from './clientsReducer';
import Invoices from './invoicesReducer';
import Snackbar from './snackbarReducer';
import Quotes from './quotesReducer';

// Combine all the reducers in this file
// Note that the name you give it here is important,
// since that will be the reference to that reducer.
export default {
	Login,
	Session,
	Snackbar,
	Clients,
	Invoices,
	Quotes,
};
