import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { NavLink, withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import InvoicesIcon from '@material-ui/icons/Inbox';
import QuotesIcon from '@material-ui/icons/Receipt';
import ClientsIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import { Typography } from '@material-ui/core';
import * as routes from '../../constants/routes';

const menuItems = [
	{
		text: routes.LANDING.label,
		link: routes.LANDING.path,
		icon: <DashboardOutlined />,
	},
	{
		text: routes.INVOICES.label,
		link: routes.INVOICES.path,
		icon: <InvoicesIcon />,
	},
	{
		text: routes.QUOTES.label,
		link: routes.QUOTES.path,
		icon: <QuotesIcon />,
	},
	{
		text: routes.CLIENTS.label,
		link: routes.CLIENTS.path,
		icon: <ClientsIcon />,
	},
];

const subMenuItems = [
	{
		text: routes.SETTINGS.label,
		link: routes.SETTINGS.path,
		icon: <SettingsIcon />,
	},
];

const SideDrawer = ({ classes, open, theme, handleDrawerClose, history }) => {
	return (
		<Drawer
			variant="permanent"
			className={classNames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: classNames({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
			open={open}
		>
			<div className={classes.toolbar}>
				<div>
					<Typography variant="caption">Autobedrijf Admin</Typography>
					<Typography variant="caption">v0.1</Typography>
				</div>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? (
						<ChevronRightIcon />
					) : (
						<ChevronLeftIcon />
					)}
				</IconButton>
			</div>
			<Divider />
			<List>
				{menuItems.map((menuItem) => (
					<NavLink
						activeClassName="active"
						key={menuItem.text}
						to={menuItem.link}
					>
						<ListItem button>
							<ListItemIcon>
								<React.Fragment>{menuItem.icon}</React.Fragment>
							</ListItemIcon>
							<ListItemText primary={menuItem.text} />
						</ListItem>
					</NavLink>
				))}
			</List>
			<Divider />
			<List>
				{subMenuItems.map((menuItem) => (
					<NavLink
						activeClassName="active"
						key={menuItem.text}
						to={menuItem.link}
					>
						<ListItem button>
							<ListItemIcon>
								<React.Fragment>{menuItem.icon}</React.Fragment>
							</ListItemIcon>
							<ListItemText primary={menuItem.text} />
						</ListItem>
					</NavLink>
				))}
			</List>
		</Drawer>
	);
};

SideDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	theme: PropTypes.object.isRequired,
	handleDrawerClose: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withRouter(SideDrawer);
