import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Fade, TextField } from '@material-ui/core';

const FinancialInfoStep = ({ handleChange, kvk, btw, iban }) => {
	return (
		<Fade in timeout={200}>
			<Grid container>
				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="kvk"
								label="KVK nummer"
								value={kvk}
								onChange={handleChange('kvk')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="btw"
								label="BTW nummer"
								value={btw}
								onChange={handleChange('btw')}
								margin="normal"
								type="text"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="iban"
						label="Rekening nummer IBAN"
						value={iban}
						onChange={handleChange('iban')}
						margin="normal"
						type="text"
					/>
				</Grid>
				<Grid item xs={6} />
			</Grid>
		</Fade>
	);
};

FinancialInfoStep.propTypes = {
	handleChange: PropTypes.func.isRequired,
	kvk: PropTypes.string.isRequired,
	btw: PropTypes.string.isRequired,
	iban: PropTypes.string.isRequired,
};

export default FinancialInfoStep;
