import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles, Snackbar } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';

const styles = {
	message: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '5px',
	},
	warning: {
		color: amber[700],
	},
	icon: {
		fontSize: 20,
	},
};

const errorMessagesQuotes = {
	missingNumber: 'Er is geen offertenummer ingevoerd',
	missingDate: 'Er is geen offertedatum ingevoerd',
	missingExpirationDate: 'Er is geen vervalvaldatum ingevoerd',
	missingClient: 'Er is geen klant geselecteerd',
	missingFieldsInItems:
		'Artikelen zonder prijs, hoeveelheid en omschrijving zijn niet toegestaan',
	numberInUse: 'Het offertenummer is al gebruikt, voer een ander nummer in',
};
const errorMessagesInvoices = {
	missingNumber: 'Er is geen offertenummer ingevoerd',
	missingDate: 'Er is geen offertedatum ingevoerd',
	missingExpirationDate: 'Er is geen vervalvaldatum ingevoerd',
	missingClient: 'Er is geen klant geselecteerd',
	missingFieldsInItems:
		'Artikelen zonder prijs, hoeveelheid en omschrijving zijn niet toegestaan',
	numberInUse: 'Het factuurnummer is al gebruikt, voer een ander nummer in',
};

function getErrorMessage(error, datatype) {
	if (datatype === 'invoice') {
		return errorMessagesInvoices[error];
	}
	return errorMessagesQuotes[error];
}

const FormSnackbar = ({ errorArray, classes, datatype }) => {
	return (
		<Snackbar
			open={errorArray.length > 0}
			className="p-around-medium"
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			message={errorArray.map((err) => (
				<span key={err} className={classes.message}>
					<WarningIcon
						className={`${classes.warning} ${classes.icon}  m-right-small`}
					/>
					{getErrorMessage(err, datatype)}
				</span>
			))}
		/>
	);
};

FormSnackbar.propTypes = {
	errorArray: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	datatype: PropTypes.string.isRequired,
};

export default withStyles(styles)(FormSnackbar);
