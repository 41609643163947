export const restrictedRoute = '/dashboard';
export const LANDING = { path: restrictedRoute, label: 'Home' };
export const LOGIN = { path: '/login', label: 'Login' };
export const SIGNIN = { path: '/login/signIn', label: 'Aanmelden' };
export const SIGNUP = { path: '/login/signUp', label: 'Account aanmaken' };
export const RESETPASSWORD = { path: '/login/reset', label: 'Aanmelden' };
export const PRIVACY = { path: '/privacy', label: 'Privacy Policy' };

// ------- Invoices ------- \\
export const INVOICES = {
	path: `${restrictedRoute}/invoices`,
	label: 'Facturen',
};
export const CREATEINVOICE = {
	path: `${restrictedRoute}/invoices/createInvoice`,
	label: 'Nieuwe Factuur',
};
export const EDITINVOICE = {
	path: `${restrictedRoute}/invoices/createInvoice/:invoiceId`,
	pathWithoutParam: `${restrictedRoute}/invoices/createInvoice`,
	label: 'Factuur Bewerken',
};
export const VIEWINVOICE = {
	path: `${restrictedRoute}/viewInvoice/:invoiceId`,
	label: 'Bekijk Factuur',
};

// ------- Quotes ------- \\
export const QUOTES = { path: `${restrictedRoute}/quotes`, label: 'Offertes' };
export const CREATEQUOTE = {
	path: `${restrictedRoute}/quotes/createQuote`,
	label: 'Nieuwe Offerte',
};
export const EDITQUOTE = {
	path: `${restrictedRoute}/quotes/createQuote/:quoteId`,
	pathWithoutParam: `${restrictedRoute}/quotes/createQuote`,
	label: 'Offerte Bewerken',
};
export const VIEWQUOTES = {
	path: `${restrictedRoute}/viewQuote/:quoteId`,
	label: 'Bekijk Offerte',
};

// ------- Clients ------- \\
export const CLIENTS = { path: `${restrictedRoute}/clients`, label: 'Klanten' };
export const CREATECLIENT = {
	path: `${restrictedRoute}/createClient`,
	label: 'Nieuwe Klant',
};
export const EDITCLIENT = {
	path: `${restrictedRoute}/editClient/:clientId`,
	pathWithoutParam: `${restrictedRoute}/editClient`,
	label: 'Klant Bewerken',
};
export const VIEWCLIENT = {
	path: `${restrictedRoute}/viewClient/:clientId`,
	label: 'Klant bekijken',
};

// ------- Account ------- \\
export const ACCOUNT = { path: `${restrictedRoute}/account`, label: 'Account' };

// ------- Settings ------- \\
export const SETTINGS = {
	path: `${restrictedRoute}/settings`,
	label: 'Instellingen',
};
