import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClientCreateForm from './ClientCreateForm';

const ClientCreateDialog = ({
	addClientOpen,
	handleAddClientDialogClose,
	doSave,
	getNewClient,
	uid,
}) => (
	<Dialog open={addClientOpen} onClose={handleAddClientDialogClose}>
		<DialogTitle id="form-dialog-title">Nieuwe klant</DialogTitle>
		<DialogContent>
			<ClientCreateForm
				fromDialog
				doSave={doSave}
				getNewClient={getNewClient}
				uid={uid}
				handleAddClientDialogClose={handleAddClientDialogClose}
			/>
		</DialogContent>
	</Dialog>
);

ClientCreateDialog.propTypes = {
	handleAddClientDialogClose: PropTypes.func.isRequired,
	doSave: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	addClientOpen: PropTypes.bool.isRequired,
	uid: PropTypes.string.isRequired,
};

export default ClientCreateDialog;
