import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/CreateOutlined';
import SaveIcon from '@material-ui/icons/SaveAltOutlined';
import MailIcon from '@material-ui/icons/EmailOutlined';
import PreviewIcon from '@material-ui/icons/VisibilityOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { formatDate, formatCurrency } from '../../utils/formatHelpers';
import { EDITQUOTE } from '../../constants/routes';

const styles = {
	tableRow: {
		minHeight: '64px',
	},
	tableItem: {
		flex: '1 0 auto',
	},
	icons: {
		fontSize: '1px',
		padding: '3px',
	},
};

const QuotesRow = ({
	quote,
	classes,
	handleOpenDeletionDialog,
	handleOpenQuotePreviewDialog,
	history,
	handleOpenEmailDialog,
	handleOpenQuotePDFGeneration,
}) => {
	const buttons = [
		{
			label: 'Downloaden',
			icon: <SaveIcon fontSize="default" />,
			action: handleOpenQuotePDFGeneration(quote),
		},
		{
			label: 'Preview',
			icon: <PreviewIcon fontSize="default" />,
			action: handleOpenQuotePreviewDialog(quote),
		},
		{
			label: 'Email versturen',
			icon: <MailIcon fontSize="default" />,
			action: handleOpenEmailDialog(quote),
		},
		{
			label: 'Bewerken',
			icon: <EditIcon fontSize="default" />,
			action: () => history.push(`${EDITQUOTE.pathWithoutParam}/${quote.id}`),
		},
		{
			label: 'Verwijderen',
			icon: <DeleteIcon fontSize="default" />,
			action: handleOpenDeletionDialog(quote.id),
		},
	];

	return (
		<React.Fragment>
			<Grid
				container
				className={`p-around-medium table-row ${classes.tableRow}`}
				alignItems="center"
				spacing={0}
			>
				<Grid item xs={2}>
					<Typography variant="body2">{quote.quoteNumber}</Typography>
				</Grid>
				<Grid item xs={2} className="p-left-large">
					<Typography variant="body2">{formatDate(quote.quoteDate)}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography variant="body2">
						{formatDate(quote.expirationDate)}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="body2">{quote.client.label}</Typography>
				</Grid>
				<Grid item xs={2}>
					<div className="container-center">
						<div className="label-payment">
							<Typography variant="body2">
								{formatCurrency(quote.totalInclVat)}
							</Typography>
						</div>
						<div className="action-buttons-row">
							{buttons.map((button) => (
								<IconButton
									key={button.label}
									color="inherit"
									className={classes.icons}
									onClick={button.action}
								>
									<Tooltip title={button.label}>{button.icon}</Tooltip>
								</IconButton>
							))}
						</div>
					</div>
				</Grid>
			</Grid>
			<Divider />
		</React.Fragment>
	);
};

QuotesRow.propTypes = {
	quote: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	handleOpenDeletionDialog: PropTypes.func.isRequired,
	handleOpenQuotePreviewDialog: PropTypes.func.isRequired,
	handleOpenEmailDialog: PropTypes.func.isRequired,
	handleOpenQuotePDFGeneration: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(QuotesRow));
