import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import colors from '../../utils/colors';

const styles = {
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	backgroundColorFab: {
		backgroundColor: colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '56px',
		height: '56px',
		borderRadius: '50%',
		boxShadow:
			'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
	},
	progressColor: {
		color: colors.primary,
	},
};

const LoadingComponent = ({ classes }) => (
	<Fade in timeout={{ enter: 1000 }}>
		<div className={classes.loadingContainer}>
			<div className={classes.backgroundColorFab}>
				<CircularProgress className={classes.progressColor} />
			</div>
		</div>
	</Fade>
);

LoadingComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingComponent);
