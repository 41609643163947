export const rowsInvoice = [
	{
		id: 'status',
		xs: 1,
		label: 'Status',
		padding: '',
	},
	{
		id: 'invoiceNumber',
		xs: 1,
		label: 'Fact Nr.',
		padding: '',
	},
	{
		id: 'invoiceDate',
		xs: 2,
		label: 'Factuurdatum',
		padding: 'p-left-large',
	},
	{
		id: 'expirationDate',
		xs: 2,
		label: 'Vervaldatum',
		padding: '',
	},
	{
		id: 'label',
		xs: 2,
		label: 'Klant',
		padding: '',
	},
	{
		id: 'totalInclVat',
		xs: 2,
		label: 'Totaal bedrag',
		padding: '',
	},
	{
		id: 'reedsBetaald',
		xs: 2,
		label: 'Reeds Betaald',
		padding: '',
	},
];

export const rowsClient = [
	{
		id: 'name',
		xs: 4,
		label: 'Naam',
	},
	{
		id: 'address',
		xs: 4,
		label: 'Adres',
	},
	{
		id: 'email',
		xs: 3,
		label: 'E-mail',
	},
];

export const rowsQuote = [
	{
		id: 'quoteNumber',
		xs: 2,
		label: 'Offerte Nr.',
	},
	{
		id: 'quoteDate',
		xs: 2,
		label: 'Datum',
		padding: 'p-left-large',
	},
	{
		id: 'expirationDate',
		xs: 2,
		label: 'Vervaldatum',
	},
	{
		id: 'label',
		xs: 3,
		label: 'Klant',
	},
	{
		id: 'totalInclVat',
		xs: 2,
		label: 'Bedrag',
	},
];
