import React from 'react';
import { Grid, Fade, withStyles, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const styles = {
	uploadBoxContainer: {
		height: '50%',
		width: '50%',
	},
	imageContainer: {
		width: '300px',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
	},
	placeholder: {
		height: 0,
		paddingBottom: '56.25%',
		width: '100%',
		border: '2px dashed #ddd',
		textAlign: 'center',
		lineHeight: '173px',
		color: '#ddd',
		fontSize: 40,
		backgroundSize: 'cover !important',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
	},
};

const UploadLogoStep = ({ classes, handleChangeKeyValuePair }) => {
	let imageContainer;
	return (
		<Fade in timeout={200}>
			<Grid container>
				<Typography variant="caption" className="m-bottom-small">
					Upload een logo, deze stap is niet verplicht en kan worden
					overgeslagen
				</Typography>
				<Grid item xs={12}>
					<Dropzone
						accept="image/jpeg, image/png, image/bmp"
						className={classes.uploadBoxContainer}
						onDrop={(accepted) => {
							imageContainer.style.backgroundImage = `url(${accepted[0].preview})`;
							imageContainer.style.backgroundSize = 'contain';
							handleChangeKeyValuePair('companyImage', accepted[0]);
						}}
					>
						<div
							ref={(el) => {
								imageContainer = el;
							}}
							className={classes.placeholder}
						>
							+
						</div>
					</Dropzone>
				</Grid>
			</Grid>
		</Fade>
	);
};

UploadLogoStep.propTypes = {
	classes: PropTypes.object.isRequired,
	handleChangeKeyValuePair: PropTypes.func.isRequired,
	companyImage: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadLogoStep);
