import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	image: {
		width: 150,
		height: 150,
	},
	viewMargin: {
		marginBottom: 10,
	},
	text: {
		fontSize: 10,
		marginBottom: 2,
		fontFamily: 'Lato',
	},
	labelBold: {
		fontSize: 10,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
});

const ClientPDFInfo = ({
	documentToRender: {
		client,
		vehicleType,
		licensePlate,
		milage,
		chassisNumber,
	},
}) => {
	return (
		<View>
			<View style={styles.viewMargin}>
				<Text style={styles.labelBold}>{client.value.name}</Text>
				<Text style={styles.text}>{`${client.value.street} ${
					client.value.houseNumber
				}`}</Text>
				<Text style={styles.text}>{`${client.value.postalCode} ${
					client.value.city
				}`}</Text>
			</View>

			<View>
				{vehicleType ? (
					<Text style={styles.text}>
						<Text style={styles.labelBold}>Type: </Text>
						{vehicleType}
					</Text>
				) : (
					<Text />
				)}
				{licensePlate ? (
					<Text style={styles.text}>
						<Text style={styles.labelBold}>Kenteken: </Text>
						{licensePlate}
					</Text>
				) : (
					<Text />
				)}
				{milage ? (
					<Text style={styles.text}>
						<Text style={styles.labelBold}>Kilometerstand: </Text>
						{milage}
					</Text>
				) : (
					<Text />
				)}
				{chassisNumber ? (
					<Text style={styles.text}>
						<Text style={styles.labelBold}>Chassisnummer: </Text>
						{chassisNumber}
					</Text>
				) : (
					<Text />
				)}
			</View>
		</View>
	);
};

ClientPDFInfo.propTypes = {
	documentToRender: PropTypes.object.isRequired,
};

export default ClientPDFInfo;
