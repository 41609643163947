/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FreeSolo({
	handleChangeInvoiceItem,
	index,
	description,
	handleAutoComplete,
	invoiceOptions,
}) {
	return (
		<Autocomplete
			freeSolo
			id="free-solo-2-demo"
			disableClearable
			value={description}
			onChange={(event, newValue) => {
				handleAutoComplete(index, 'description', newValue);
			}}
			size="small"
			options={invoiceOptions.map((option) => option)}
			renderInput={(params) => (
				<TextField
					fullWidth
					{...params}
					label="Omschrijving"
					id="standard-basic"
					margin="none"
					variant="standard"
					InputProps={{ ...params.InputProps, type: 'search' }}
					onChange={handleChangeInvoiceItem(index, 'description')}
				/>
			)}
		/>
	);
}
