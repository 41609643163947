import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import InvoiceSave from '../components/createInvoiceComponents/InvoiceSave';
import ActionPaper from '../components/actionPaper/ActionPaper';
import QuoteForm from '../components/createQuoteComponents/QuoteForm';
import InvoiceBaseData from '../components/createInvoiceComponents/InvoiceBaseData';
import QuoteEditTable from '../components/createQuoteComponents/quoteEditTable/QuoteEditTable';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import FormSnackbar from '../components/formSnackbar/FormSnackbar';
import calculateTotals from '../helpers/calculateTotals';
import { LANDING, QUOTES, CREATEQUOTE, EDITQUOTE } from '../constants/routes';
import vatPercentages from '../constants/vatPercentages';
import typesOfWork from '../constants/typesOfWork';
import getNewNumber from '../helpers/getNewNumber';
import quoteItemTemplate from '../constants/quoteItemTemplate';
import validateInvoiceOrQuote from '../utils/invoiceAndQuoteValidation';
import ClientCreateDialog from '../components/createClientComponents/ClientCreateDialog';
import TotalDiscountRececeived from '../components/TotalDiscountReceived';
import InvoiceRemarks from '../components/createInvoiceComponents/InvoiceRemarks';

const styles = {};

const now = moment().format('YYYY-MM-DD');

const datatype = 'quote';

class CreateQuote extends React.Component {
	state = {
		quoteNumber: 1,
		client: {},
		quoteDate: now,
		expirationDate: '',
		vehicleType: '',
		licensePlate: '',
		remarks: '',
		chassisNumber: '',
		milage: '',
		errorArray: [],
		quoteItems: [
			{
				...quoteItemTemplate,
			},
		],
		addClientOpen: false,
		editMode: false,
	};

	componentDidMount = () => {
		const {
			match: {
				params: { quoteId },
			},
			quotes,
		} = this.props;
		if (quoteId) {
			this.setState({
				editMode: true,
				quoteId,
			});
			const quoteToEdit = quotes.filter((quote) => quote.id === quoteId);
			this.setState({
				...quoteToEdit[0],
			});
		} else {
			this.setState({
				quoteNumber: getNewNumber(quotes, 'quoteNumber'),
				expirationDate: moment()
					.add(14, 'days')
					.format('YYYY-MM-DD'),
			});
		}
	};

	setClient = (clientToSet) => {
		this.setState({
			client: { ...clientToSet },
		});
	};

	handleAddClientDialogOpen = () => {
		this.setState({ addClientOpen: true });
	};

	handleAddClientDialogClose = () => {
		this.setState({ addClientOpen: false });
	};

	addInvoiceItem = () => {
		this.setState((prevState) => ({
			quoteItems: [...prevState.quoteItems, { ...quoteItemTemplate }],
		}));
	};

	removeInvoiceItem = (index) => {
		if (this.state.quoteItems.length > 1) {
			this.setState((prevState) => ({
				quoteItems: [
					...prevState.quoteItems.slice(0, index),
					...prevState.quoteItems.slice(index + 1),
				],
			}));
		}
	};

	handleChangeQuoteItem = (index, name) => (event) => {
		const { value } = event.target;
		this.setState((prevState) => {
			const newItems = [...prevState.quoteItems];
			newItems[index][name] = value;
			if (newItems[index].price && newItems[index].quantity) {
				const { discount, quantity, price } = newItems[index];
				const subtotal = quantity * price * (1 - discount / 100);
				newItems[index].subtotal = subtotal;
			}
			return { quoteItems: newItems };
		});
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
		if (name === 'quoteDate') {
			return this.setState({
				expirationDate: moment(event.target.value)
					.add(28, 'days')
					.format('YYYY-MM-DD'),
			});
		}
		return '';
	};

	saveQuote = async () => {
		const totals = calculateTotals(this.state.quoteItems);
		const {
			saveQuote,
			authUser,
			updateQuote,
			history,
			getNewQuote,
			quotes,
		} = this.props;
		const {
			quoteItems,
			quoteNumber,
			quoteDate,
			expirationDate,
			vehicleType,
			licensePlate,
			remarks,
			editMode,
			quoteId,
			client,
			chassisNumber,
			milage,
		} = this.state;
		const quote = {
			quoteItems,
			quoteNumber: parseInt(quoteNumber, 10),
			quoteDate,
			chassisNumber,
			milage,
			expirationDate,
			vehicleType,
			licensePlate,
			remarks,
			client,
			...totals,
		};
		let id = '';
		const errorArray = validateInvoiceOrQuote(
			quoteItems,
			quoteNumber,
			quoteDate,
			expirationDate,
			client,
			quotes,
			editMode,
			datatype
		);
		this.setState({ errorArray });
		if (errorArray.length > 0) {
			return;
		}
		if (!editMode) {
			id = await saveQuote(quote, 'quotes', authUser.uid, 'save_quote');
			await getNewQuote('quotes', id, 'new_quote');
		} else {
			updateQuote(quote, 'quotes', quoteId);
			await getNewQuote('quotes', quoteId, 'updated_quotes');
		}
		history.push(QUOTES.path);
	};

	render() {
		const {
			quoteNumber,
			quoteDate,
			quoteItems,
			expirationDate,
			vehicleType,
			licensePlate,
			addClientOpen,
			editMode,
			chassisNumber,
			milage,
			errorArray,
			remarks,
		} = this.state;
		const { account, clients, doSave, uid, getNewClient } = this.props;
		const totals = calculateTotals(this.state.quoteItems);
		return (
			<div className="m-top-large">
				<Breadcrumbs
					pathArray={[
						{
							link: LANDING.path,
							title: LANDING.label,
						},
						{
							link: QUOTES.path,
							title: QUOTES.label,
						},
						{
							link: editMode ? EDITQUOTE.path : CREATEQUOTE.path,
							title: editMode ? EDITQUOTE.label : CREATEQUOTE.label,
						},
					]}
				/>
				<ActionPaper
					title={editMode ? 'Bewerk Offerte' : 'Nieuwe Offerte'}
					className="p-left-xx-large p-right-xx-large p-top-large p-bottom-large"
				>
					<InvoiceSave saveInvoice={this.saveQuote} />
					<InvoiceBaseData
						company={account}
						clients={clients}
						client={this.state.client}
						vehicleType={vehicleType}
						licensePlate={licensePlate}
						setClient={this.setClient}
						handleChange={this.handleChange}
						addClientOpen={addClientOpen}
						editMode={editMode}
						datatype="quote"
						handleAddClientDialogClose={this.handleAddClientDialogClose}
						handleAddClientDialogOpen={this.handleAddClientDialogOpen}
						chassisNumber={chassisNumber}
						milage={milage}
					/>
					<div className="m-top-medium m-bottom-medium" />
					<QuoteForm
						handleChange={this.handleChange}
						quoteNumber={quoteNumber}
						quoteDate={quoteDate}
						editMode={editMode}
						expirationDate={expirationDate}
					/>
					<div className="m-top-medium m-bottom-medium" />
					<QuoteEditTable
						invoiceItems={quoteItems}
						addInvoiceItem={this.addInvoiceItem}
						removeInvoiceItem={this.removeInvoiceItem}
						handleChangeInvoiceItem={this.handleChangeQuoteItem}
						handleChange={this.handleChange}
						totals={totals}
						vatPercentages={vatPercentages}
						typesOfWork={typesOfWork}
					/>
					<TotalDiscountRececeived discount={totals.totalDiscount} quote />
					<div className="m-top-medium m-bottom-medium" />
					<InvoiceRemarks handleChange={this.handleChange} remarks={remarks} />
					<div className="m-top-medium m-bottom-medium" />
				</ActionPaper>
				<FormSnackbar errorArray={errorArray} datatype={datatype} />
				<ClientCreateDialog
					fromDialog
					doSave={doSave}
					addClientOpen={addClientOpen}
					handleAddClientDialogClose={this.handleAddClientDialogClose}
					getNewClient={getNewClient}
					uid={uid}
				/>
			</div>
		);
	}
}

CreateQuote.propTypes = {
	match: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
	authUser: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	quotes: PropTypes.array.isRequired,
	clients: PropTypes.array.isRequired,
	saveQuote: PropTypes.func.isRequired,
	updateQuote: PropTypes.func.isRequired,
	getNewQuote: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	doSave: PropTypes.func.isRequired,
	uid: PropTypes.string.isRequired,
};

export default withStyles(styles)(CreateQuote);
