import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PaymentIcon from '@material-ui/icons/CreditCardOutlined';
import EditIcon from '@material-ui/icons/CreateOutlined';
import SaveIcon from '@material-ui/icons/SaveAltOutlined';
import MailIcon from '@material-ui/icons/EmailOutlined';
import PreviewIcon from '@material-ui/icons/VisibilityOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { EDITINVOICE } from '../../constants/routes';
import InvoiceStatus from '../invoiceStatus/InvoiceStatus';
import { formatDate, formatCurrency } from '../../utils/formatHelpers';
import calculateTotalPayment from '../../helpers/caclulateTotalPayment';

const styles = {
	tableRow: {
		minHeight: '64px',
	},
	tableItem: {
		flex: '1 0 auto',
	},
	icons: {
		fontSize: '1px',
		padding: '3px',
	},
};

const InvoicesRow = ({
	invoice,
	classes,
	handleOpenDeletionDialog,
	handleOpenPaymentDialog,
	handleOpenPreviewInvoiceDialog,
	handleOpenInvoicePDFGeneration,
	handleOpenEmailDialog,
	history,
}) => {
	const buttons = [
		{
			label: 'Downloaden',
			icon: <SaveIcon fontSize="default" />,
			action: handleOpenInvoicePDFGeneration(invoice),
		},
		{
			label: 'Preview',
			icon: <PreviewIcon fontSize="default" />,
			action: handleOpenPreviewInvoiceDialog(invoice),
		},
		{
			label: 'Email versturen',
			icon: <MailIcon fontSize="default" />,
			action: handleOpenEmailDialog(invoice),
		},
		{
			label: 'Betaling invoeren',
			icon: <PaymentIcon fontSize="default" />,
			action: handleOpenPaymentDialog(invoice),
		},
		{
			label: 'Bewerken',
			icon: <EditIcon fontSize="default" />,
			action: () =>
				history.push(`${EDITINVOICE.pathWithoutParam}/${invoice.id}`),
		},
		{
			label: 'Verwijderen',
			icon: <DeleteIcon fontSize="default" />,
			action: handleOpenDeletionDialog(invoice.id),
		},
	];

	return (
		<React.Fragment>
			<Grid
				container
				className={`p-around-medium table-row ${classes.tableRow}`}
				alignItems="center"
				spacing={0}
			>
				<Grid item xs={1}>
					<Typography variant="body2">
						<InvoiceStatus invoice={invoice} />
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<Typography variant="body2">{invoice.invoiceNumber}</Typography>
				</Grid>
				<Grid item xs={2} className="p-left-large">
					<Typography variant="body2">
						{formatDate(invoice.invoiceDate)}
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography variant="body2">
						{formatDate(invoice.expirationDate)}
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography variant="body2">{invoice.client.label}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography variant="body2">
						{formatCurrency(invoice.totalInclVat)}
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<div className="container-center">
						<div className="label-payment">
							<Typography variant="body2">
								{formatCurrency(
									invoice.payments.length > 0
										? calculateTotalPayment(invoice.payments)
										: 0
								)}
							</Typography>
						</div>
						<div className="action-buttons-row">
							{buttons.map((button) => (
								<IconButton
									key={button.label}
									color="inherit"
									className={classes.icons}
									onClick={button.action}
								>
									<Tooltip title={button.label}>{button.icon}</Tooltip>
								</IconButton>
							))}
						</div>
					</div>
				</Grid>
			</Grid>
			<Divider />
		</React.Fragment>
	);
};

InvoicesRow.propTypes = {
	invoice: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	handleOpenDeletionDialog: PropTypes.func.isRequired,
	handleOpenPreviewInvoiceDialog: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	handleOpenPaymentDialog: PropTypes.func.isRequired,
	handleOpenInvoicePDFGeneration: PropTypes.func.isRequired,
	handleOpenEmailDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(InvoicesRow));
