import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	IconButton,
	Menu,
	MenuItem,
	withStyles,
	Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import colors from '../../utils/colors';
import { auth } from '../../services/firebase';
import { SIGNIN } from '../../constants/routes';

const styles = {
	userMenu: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fill: `${colors.white} !important`,
		fontSize: '36px !important',
	},
	iconHome: {
		fill: 'white !important',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
	},
};

class UserMenu extends Component {
	state = {
		isMenuOpen: false,
	};

	anchorEl = undefined;

	componentDidMount() {
		this.anchorEl = document.getElementById('userMenu');
	}

	toggleMenu = () => {
		this.setState((prevState) => ({
			isMenuOpen: !prevState.isMenuOpen,
		}));
	};

	handleSignOut = () => {
		const { doSignOut, history } = this.props;
		auth.doSignOut();
		doSignOut();
		history.push(SIGNIN.path);
	};

	render() {
		const { isMenuOpen } = this.state;
		const { classes, loggedInUser } = this.props;
		return (
			<div id="userMenu" className={classes.container}>
				{loggedInUser.firstName && (
					<Typography className="text-color">
						{`${loggedInUser.firstName} ${loggedInUser.lastName}`}
					</Typography>
				)}
				<div className={classes.userMenu}>
					<IconButton color="primary" onClick={this.toggleMenu}>
						<AccountCircle className={classes.icon} />
					</IconButton>
					<Menu
						id="user-menu"
						open={isMenuOpen}
						anchorEl={this.anchorEl}
						onClose={this.toggleMenu}
					>
						<MenuItem onClick={this.handleSignOut}>Log Uit</MenuItem>
					</Menu>
				</div>
			</div>
		);
	}
}

UserMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	doSignOut: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserMenu);
