import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatCurrency } from '../utils/formatHelpers';

const TotalDiscountReceived = ({ discount, quote }) => (
	<div>
		{discount ? (
			<Typography variant="body2">
				{`Totaal korting op ${quote ? 'offerte' : 'factuur'}: ${formatCurrency(
					discount
				)}`}
			</Typography>
		) : (
			<></>
		)}
	</div>
);

TotalDiscountReceived.propTypes = {
	discount: PropTypes.number.isRequired,
	quote: PropTypes.string.isRequired,
};

export default TotalDiscountReceived;
