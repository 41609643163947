import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Route, withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { openSnackbar } from '../redux/actions/snackbarActions';
import bgImage from '../static/sign.jpg';
import SignIn from '../components/signIn/SignIn';
import PasswordForgotten from '../components/passwordForgotten/PasswordForgotten';
import SignUp from '../components/signUp/SignUp';
import { RESETPASSWORD, LANDING, SIGNUP } from '../constants/routes';
import { doSignIn } from '../redux/actions/sessionActions';

const styles = {
	mainContainer: {
		width: '100%',
		minHeight: '100vh',
		height: '100vh',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		position: 'relative',
		background: `url(${bgImage}) no-repeat center center`,
		backgroundSize: 'cover',
		'&:before': {
			content: '',
			width: '100%',
			height: '100%',
			display: 'flex',
			backgroundColor: 'rgba(0, 0, 0, 0.6)',
			position: 'absolute',
			zIndex: 1,
			top: 0,
		},
	},
	isoLoginContentWrapper: {
		width: '500px',
		height: '100%',
		overflowY: 'auto',
		zIndex: '10',
		position: 'relative',
	},

	login: {
		minHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '50px 50px 0px 50px',
		position: 'relative',
		backgroundColor: '#ffffff',
	},
};

class Login extends React.Component {
	state = {};

	checkUser = () => {
		const { history, authUser } = this.props;
		if (authUser) {
			history.push(LANDING.path);
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.authUser !== this.props.authUser) {
			this.checkUser();
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.mainContainer}>
				<div className={classes.isoLoginContentWrapper}>
					<div className={classes.login}>
						<Switch>
							<Route
								exact
								path={RESETPASSWORD.path}
								component={() => <PasswordForgotten {...this.props} />}
							/>
							<Route
								exact
								path={SIGNUP.path}
								component={() => <SignUp {...this.props} />}
							/>
							<Route component={() => <SignIn {...this.props} />} />
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	authUser: PropTypes.object,
};
Login.defaultProps = {
	authUser: null,
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	checkedForAuth: state.Session.checkedForAuth,
	loggedInUser: state.Session.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
	doSignIn: () => dispatch(doSignIn()),
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withRouter(Login)));
