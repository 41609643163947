import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = {
	clientData: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	companyData: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
};

const InvoiceClientDialog = ({
	clientToPreview,
	openClientPreview,
	handleClosePreviewDialog,
	// classes,
}) => (
	<Dialog
		open={openClientPreview}
		onClose={handleClosePreviewDialog}
		fullWidth
		maxWidth="xs"
	>
		{openClientPreview && (
			<DialogContent>
				<div className="m-bottom-medium">
					<Typography variant="caption">Naam:</Typography>
					<Typography variant="body2">{clientToPreview.name}</Typography>
				</div>
				<div className="m-bottom-medium">
					<Typography variant="caption">E-mail:</Typography>
					<Typography variant="body2">{clientToPreview.email}</Typography>
				</div>
				<div className="m-bottom-medium">
					<Typography variant="caption">Adres:</Typography>
					<Typography variant="body2">{`${clientToPreview.street} ${
						clientToPreview.houseNumber
					}`}</Typography>
					<Typography variant="body2">{`${clientToPreview.postalCode} ${
						clientToPreview.city
					}`}</Typography>
				</div>
			</DialogContent>
		)}
	</Dialog>
);

InvoiceClientDialog.propTypes = {
	clientToPreview: PropTypes.object,
	// classes: PropTypes.object.isRequired,
	openClientPreview: PropTypes.bool.isRequired,
	handleClosePreviewDialog: PropTypes.func.isRequired,
};

InvoiceClientDialog.defaultProps = {
	clientToPreview: {},
};

export default withStyles(styles)(InvoiceClientDialog);
