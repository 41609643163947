import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import colors from '../../../../utils/colors';
import { formatCurrency } from '../../../../utils/formatHelpers';

const invoiceItem = {
	flex: '0 0',
	fontFamily: 'Lato',
	fontWeight: 200,
	fontSize: 10,
};

const tableHeadText = {
	fontSize: 8,
	marginBottom: 2,
	fontFamily: 'Lato',
	fontStyle: 'bold',
	fontWeight: 700,
	color: colors.white,
};

const rowPadding = {
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5,
};

const styles = StyleSheet.create({
	tableHead: {
		backgroundColor: colors.headerPaper,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		...rowPadding,
	},
	viewMargin: {
		marginBottom: 10,
	},
	labelBold: {
		fontSize: 10,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
	invoiceNumber: {
		fontSize: 8,
		marginBottom: 18,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 700,
	},
	tableRow: {
		display: 'flex',
		flex: '0 0',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '1 solid #E5E5E5',
		...rowPadding,
	},

	tableHeadTextWidthLarge: {
		width: '50%',
		...tableHeadText,
	},
	tableHeadTextWidthSmall: {
		width: '15%',
		textAlign: 'right',
		...tableHeadText,
	},

	invoiceItemWidthLarge: {
		width: '50%',
		...invoiceItem,
	},
	invoiceItemWidthSmall: {
		width: '15%',
		textAlign: 'right',
		...invoiceItem,
	},

	labelValueContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: 250,
		...rowPadding,
		borderBottom: '1 solid #E5E5E5',
	},
	invoiceTotalContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	totalText: {
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
		fontSize: 8,
	},
	mainCont: {
		height: '100%',
	},
});

const InvoicePDFTable = ({
	invoiceToRender: {
		invoiceNumber,
		invoiceItems,
		totalExVat,
		totalInclVat,
		totalVatHigh,
		totalVatLow,
		totalVatMarge,
	},
}) => {
	return (
		<View>
			<Text style={styles.invoiceNumber}>Factuurnummer: {invoiceNumber}</Text>

			<View style={styles.tableHead}>
				<Text style={styles.tableHeadTextWidthLarge}>Omschrijving</Text>
				<Text style={styles.tableHeadTextWidthSmall}>Aantal</Text>
				<Text style={styles.tableHeadTextWidthSmall}>Prijs</Text>
				<Text style={styles.tableHeadTextWidthSmall}>Subtotaal</Text>
			</View>

			{invoiceItems.map((item) => (
				// eslint-disable-next-line
				<View style={styles.tableRow}>
					<Text style={styles.invoiceItemWidthLarge}>{item.description}</Text>
					<Text style={styles.invoiceItemWidthSmall}>{item.quantity}</Text>
					<Text style={styles.invoiceItemWidthSmall}>
						{formatCurrency(parseFloat(item.price))}
					</Text>
					<Text style={styles.invoiceItemWidthSmall}>
						{formatCurrency(item.subtotal)}
					</Text>
				</View>
			))}

			<View style={styles.invoiceTotalContainer}>
				<View />
				<View>
					<View style={styles.labelValueContainer}>
						<Text style={styles.totalText}>Totaal exclusief BTW</Text>
						<Text style={styles.totalText}>{formatCurrency(totalExVat)}</Text>
					</View>
					{totalVatMarge ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 0%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatMarge)}
							</Text>
							<Text style={styles.totalText}>{formatCurrency(0)}</Text>
						</View>
					) : (
						<View />
					)}
					{totalVatLow ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 9%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatLow)}
							</Text>
						</View>
					) : (
						<View />
					)}
					{totalVatHigh ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 21%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatHigh)}
							</Text>
						</View>
					) : (
						<View />
					)}
					<View style={styles.labelValueContainer}>
						<Text style={styles.totalText}>Totaal inclusief BTW</Text>
						<Text style={styles.totalText}>{formatCurrency(totalInclVat)}</Text>
					</View>
				</View>
			</View>
		</View>
	);
};

InvoicePDFTable.propTypes = {
	invoiceToRender: PropTypes.object.isRequired,
};

export default InvoicePDFTable;
