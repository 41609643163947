import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
	withStyles,
	TextField,
	Button,
	Typography,
	Checkbox,
	Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { auth, db } from '../../services/firebase';
import { SIGNIN, LANDING } from '../../constants/routes';
import initialSettings from '../../utils/initialSettings';
import AccountCreateHelper from '../accountCreateHelper/AccountCreateHelper';

const styles = {
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	formRow: {
		display: 'flex',
		alignItems: 'center',
	},
};

const INITIAL_STATE = {
	firstName: '',
	lastName: '',
	email: '',
	passwordOne: '',
	passwordTwo: '',
	termsCheck: false,
	error: null,
};

class SignUp extends React.Component {
	state = { ...INITIAL_STATE };

	handleChange = (name) => (event) => {
		if (name === 'termsCheck') {
			return this.setState({ [name]: event.target.checked });
		}
		return this.setState({
			[name]: event.target.value,
		});
	};

	onSubmit = (event) => {
		const { firstName, lastName, email, passwordOne } = this.state;
		const { openSnackbar, history } = this.props;

		const userData = { firstName, lastName, email, firstTimeLogin: true };

		auth
			.doCreateUserWithEmailAndPassword(email, passwordOne)
			.then((authUser) => {
				db.doCreateFirestoreUser(authUser.user.uid, userData)
					.then(() => {
						openSnackbar(`Account is aangemaakt`, 'success');
					})
					.catch((error) => {
						openSnackbar(error.message, 'error');
					});
			})
			.then(() => {
				auth
					.doSignInWithEmailAndPassword(email, passwordOne)
					.then(() => {
						return history.push(LANDING.path);
					})
					.catch((error) => {
						this.setState({ error });
					});
			})
			.catch((error) => {
				openSnackbar(error.message, 'error');
			});
		event.preventDefault();
	};

	validateEmail = () => {
		if (!initialSettings.emailRegExp.test(this.state.email)) {
			this.setState({
				emailError: true,
			});
		} else {
			this.setState({
				emailError: false,
			});
		}
	};

	render() {
		const { classes } = this.props;
		const {
			firstName,
			lastName,
			email,
			passwordOne,
			passwordTwo,
			error,
			termsCheck,
			emailError,
		} = this.state;

		const isInvalid =
			passwordOne !== passwordTwo ||
			passwordOne === '' ||
			passwordOne.length < 6 ||
			firstName === '' ||
			lastName === '' ||
			termsCheck === false ||
			!initialSettings.emailRegExp.test(email) ||
			!initialSettings.containsNumber.test(passwordOne);

		return (
			<React.Fragment>
				<Typography variant="overline" className="m-bottom-small">
					AUTOBEDRIJF ADMINISTRATIE
				</Typography>
				<form onSubmit={this.onSubmit} className={classes.formContainer}>
					<div className={classes.formRow}>
						<TextField
							id="firstName"
							label="Voornaam"
							value={firstName}
							onChange={this.handleChange('firstName')}
							margin="normal"
							type="text"
							variant="outlined"
							className="m-right-small"
						/>
						<TextField
							id="lastName"
							label="Achternaam"
							value={lastName}
							onChange={this.handleChange('lastName')}
							margin="normal"
							type="text"
							variant="outlined"
						/>
					</div>
					<TextField
						id="email"
						label="E-mail"
						value={email}
						onChange={this.handleChange('email')}
						margin="normal"
						type="email"
						variant="outlined"
						error={emailError}
						onBlur={this.validateEmail}
					/>

					<TextField
						id="passwordOne"
						label="Wachtwoord"
						value={passwordOne}
						onChange={this.handleChange('passwordOne')}
						margin="normal"
						type="password"
						variant="outlined"
					/>
					<TextField
						id="passwordTwo"
						label="Bevestig wachtwoord"
						value={passwordTwo}
						onChange={this.handleChange('passwordTwo')}
						margin="normal"
						type="password"
						variant="outlined"
					/>
					<AccountCreateHelper
						passwordOne={passwordOne}
						passwordTwo={passwordTwo}
					/>
					<FormControlLabel
						className="m-top-x-small m-bottom-x-small"
						control={
							<Checkbox
								checked={termsCheck}
								onChange={this.handleChange('termsCheck')}
								value="termsCheck"
							/>
						}
						label="Ik ga akkoord met de algemene voorwaarden"
					/>
					<Button
						disabled={isInvalid}
						variant="contained"
						color="primary"
						type="submit"
					>
						Account aanmaken
					</Button>
					{error && <Typography>{error.message}</Typography>}
				</form>
				<Link
					href={SIGNIN.path}
					variant="body2"
					color="secondary"
					className="m-top-xx-large text-center-imp center-center"
				>
					Heb je al een Account, meld je dan hier aan <ArrowRight />
				</Link>
			</React.Fragment>
		);
	}
}

SignUp.propTypes = {
	classes: PropTypes.object.isRequired,
	openSnackbar: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUp);
