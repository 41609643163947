import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class EnhancedTableHead extends React.Component {
	createSortHandler = (property) => (event) => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy, rows } = this.props;

		return (
			<React.Fragment>
				<Grid container className="p-around-medium">
					{rows.map(
						(row) => (
							<Grid key={row.id} item xs={row.xs} className={row.padding}>
								<Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										<Typography variant="caption">{row.label}</Typography>
									</TableSortLabel>
								</Tooltip>
							</Grid>
						),
						this
					)}
				</Grid>
				<Divider />
			</React.Fragment>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rows: PropTypes.array.isRequired,
};

export default EnhancedTableHead;
