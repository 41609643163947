import React from 'react';
import PropTypes from 'prop-types';
import {
	Typography,
	TextField,
	Button,
	withStyles,
	Fab,
} from '@material-ui/core';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { auth } from '../../services/firebase';
import * as routes from '../../constants/routes';

const styles = {
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
};

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
};

class SignIn extends React.Component {
	state = { ...INITIAL_STATE };

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	onSubmit = (event) => {
		const { email, password } = this.state;

		const { history } = this.props;

		auth
			.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				this.setState(() => ({ ...INITIAL_STATE }));
				history.push(routes.LANDING.path);
			})
			.catch((error) => {
				this.setState({ error });
			});

		event.preventDefault();
	};

	render() {
		const { history, classes } = this.props;
		const { email, password, error } = this.state;
		const isInvalid = password === '' || email === '';

		return (
			<React.Fragment>
				<Typography variant="overline">AUTOBEDRIJF ADMINISTRATIE</Typography>
				<form
					onSubmit={this.onSubmit}
					noValidate
					autoComplete="off"
					className={`${classes.formContainer} m-bottom-small`}
				>
					<TextField
						id="email"
						variant="outlined"
						label="E-Mail"
						value={email}
						onChange={this.handleChange('email')}
						margin="normal"
						type="email"
					/>
					<TextField
						id="password"
						variant="outlined"
						label="Password"
						value={password}
						onChange={this.handleChange('password')}
						margin="normal"
						type="password"
					/>
					{error && <Typography>{error.message}</Typography>}

					<Button
						disabled={isInvalid}
						variant="contained"
						color="primary"
						type="submit"
						size="large"
					>
						Log In
					</Button>
				</form>
				<Button
					size="medium"
					onClick={() => history.push(routes.RESETPASSWORD.path)}
					className="m-bottom-small"
				>
					Wachtwoord vergeten ?
				</Button>
				<Fab
					variant="extended"
					size="small"
					color="secondary"
					className={classes.margin}
					onClick={() => history.push(routes.SIGNUP.path)}
				>
					Maak een Account aan
					<VerifiedUser className="m-left-xx-small" />
				</Fab>
			</React.Fragment>
		);
	}
}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
