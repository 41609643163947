import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import colors from '../../../../utils/colors';
import { formatCurrency } from '../../../../utils/formatHelpers';
import QuoteItem from './QuoteItem';
import QuoteTypeOfWorkHead from './QuoteTypeOfWorkHead';
import calculateTotals from '../../../../helpers/calculateTotals';

const invoiceItem = {
	flex: '0 0',
	fontFamily: 'Lato',
	fontWeight: 200,
	fontSize: 10,
};

const tableHeadText = {
	fontSize: 8,
	marginBottom: 2,
	fontFamily: 'Lato',
	fontStyle: 'bold',
	fontWeight: 700,
	color: colors.white,
};

const rowPadding = {
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5,
};

const styles = StyleSheet.create({
	tableHead: {
		backgroundColor: colors.headerPaper,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		...rowPadding,
	},
	viewMargin: {
		marginBottom: 10,
	},
	labelBold: {
		fontSize: 10,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
	invoiceNumber: {
		fontSize: 8,
		marginBottom: 18,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 700,
	},
	tableRow: {
		display: 'flex',
		flex: '0 0',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '1 solid #E5E5E5',
		...rowPadding,
	},

	tableRowNoPad: {
		display: 'flex',
		flex: '0 0',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '1 solid #E5E5E5',
		paddingTop: 5,
	},

	totalRow: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
	},

	tableHeadTextWidthLarge: {
		width: '50%',
		...tableHeadText,
	},
	tableHeadTextWidthSmall: {
		width: '15%',
		textAlign: 'right',
		...tableHeadText,
	},

	invoiceItemWidthLarge: {
		width: '50%',
		...invoiceItem,
	},
	invoiceItemWidthSmall: {
		width: '15%',
		textAlign: 'right',
		...invoiceItem,
	},
	labelValueContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: 250,
		...rowPadding,
		borderBottom: '1 solid #E5E5E5',
	},
	totalLabel: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '1 solid #E5E5E5',
	},
	invoiceTotalContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	totalText: {
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
		fontSize: 8,
	},
	subTotalText: {
		fontFamily: 'Lato',
		fontWeight: 200,
		fontSize: 10,
	},
	mainCont: {
		height: '100%',
	},
});

function groupTypesOfWork(
	quoteItems,
	totalExVat,
	totalInclVat,
	totalVatHigh,
	totalVatLow,
	totalVatMarge
) {
	const onderdelen = quoteItems.filter(
		(item) => item.typeOfWork === 'ONDERDELEN'
	);
	const spuitWerk = quoteItems.filter(
		(item) => item.typeOfWork === 'SPUITWERK'
	);
	const plaatWerk = quoteItems.filter(
		(item) => item.typeOfWork === 'PLAATWERK'
	);
	const montage = quoteItems.filter((item) => item.typeOfWork === 'MONTAGE');

	const totalOnderdelen = onderdelen.length > 0 && calculateTotals(onderdelen);
	const totalSpuitwerk = spuitWerk.length > 0 && calculateTotals(spuitWerk);
	const totalPlaatWerk = plaatWerk.length > 0 && calculateTotals(plaatWerk);
	const totalMontage = montage.length > 0 && calculateTotals(montage);

	return (
		<View>
			{onderdelen.length > 0 && (
				<View>
					<QuoteTypeOfWorkHead typeOfWork="Onderdelen" styles={styles} />
					{onderdelen.map((onderdeel) => (
						// eslint-disable-next-line
						<QuoteItem onderdeel={onderdeel} styles={styles} />
					))}
				</View>
			)}
			{spuitWerk.length > 0 && (
				<View>
					<QuoteTypeOfWorkHead typeOfWork="Spuitwerk" styles={styles} />
					{spuitWerk.map((onderdeel) => (
						// eslint-disable-next-line
						<QuoteItem onderdeel={onderdeel} styles={styles} />
					))}
				</View>
			)}
			{plaatWerk.length > 0 && (
				<View>
					<QuoteTypeOfWorkHead typeOfWork="Plaatwerk" styles={styles} />
					{plaatWerk.map((onderdeel) => (
						// eslint-disable-next-line
						<QuoteItem onderdeel={onderdeel} styles={styles} />
					))}
				</View>
			)}
			{montage.length > 0 && (
				<View>
					<QuoteTypeOfWorkHead typeOfWork="Montage" styles={styles} />
					{montage.map((onderdeel) => (
						// eslint-disable-next-line
						<QuoteItem onderdeel={onderdeel} styles={styles} />
					))}
				</View>
			)}

			<View style={styles.tableHead}>
				<Text style={styles.tableHeadTextWidthLarge}>Totalen</Text>
			</View>
			{totalOnderdelen && (
				<View style={styles.totalRow}>
					<Text style={styles.subTotalText}>Onderdelen</Text>
					<Text style={styles.subTotalText}>
						{formatCurrency(totalOnderdelen.totalExVat)}
					</Text>
				</View>
			)}
			{totalSpuitwerk && (
				<View style={styles.totalRow}>
					<Text style={styles.subTotalText}>Spuitwerk</Text>
					<Text style={styles.subTotalText}>
						{formatCurrency(totalSpuitwerk.totalExVat)}
					</Text>
				</View>
			)}
			{totalPlaatWerk && (
				<View style={styles.totalRow}>
					<Text style={styles.subTotalText}>Plaatwerk</Text>
					<Text style={styles.subTotalText}>
						{formatCurrency(totalPlaatWerk.totalExVat)}
					</Text>
				</View>
			)}
			{totalMontage && (
				<View style={styles.totalRow}>
					<Text style={styles.subTotalText}>Montage</Text>
					<Text style={styles.subTotalText}>
						{formatCurrency(totalMontage.totalExVat)}
					</Text>
				</View>
			)}
			<View style={styles.tableRowNoPad} />
			<View style={styles.invoiceTotalContainer}>
				<View />
				<View>
					<View style={styles.labelValueContainer}>
						<Text style={styles.totalText}>Totaal exclusief BTW</Text>
						<Text style={styles.totalText}>{formatCurrency(totalExVat)}</Text>
					</View>
					{totalVatMarge ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 0%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatMarge)}
							</Text>
							<Text style={styles.totalText}>{formatCurrency(0)}</Text>
						</View>
					) : (
						<View />
					)}
					{totalVatLow ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 9%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatLow)}
							</Text>
						</View>
					) : (
						<View />
					)}
					{totalVatHigh ? (
						<View style={styles.labelValueContainer}>
							<Text style={styles.totalText}>BTW 21%</Text>
							<Text style={styles.totalText}>
								{formatCurrency(totalVatHigh)}
							</Text>
						</View>
					) : (
						<View />
					)}
					<View style={styles.labelValueContainer}>
						<Text style={styles.totalText}>Totaal inclusief BTW</Text>
						<Text style={styles.totalText}>{formatCurrency(totalInclVat)}</Text>
					</View>
				</View>
			</View>
		</View>
	);
}

const QuotePDFTable = ({
	quoteToRender: {
		quoteNumber,
		quoteItems,
		totalExVat,
		totalInclVat,
		totalVatHigh,
		totalVatLow,
		totalVatMarge,
	},
}) => {
	return (
		<View>
			<Text style={styles.invoiceNumber}>Offertenummer: {quoteNumber}</Text>

			{groupTypesOfWork(
				quoteItems,
				totalExVat,
				totalInclVat,
				totalVatHigh,
				totalVatLow,
				totalVatMarge
			)}
		</View>
	);
};

QuotePDFTable.propTypes = {
	quoteToRender: PropTypes.object.isRequired,
};

export default QuotePDFTable;
