import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Table, TableBody } from '@material-ui/core';

import InvoiceEditTableHeader from './InvoiceEditTableHeader';
import InvoiceEditTableItem from './InvoiceEditTableItem';
import InvoiceEditTotals from './InvoiceEditTotals';

const styles = {
	formContainer: {
		height: '400px',
		minHeight: '400px',
		overflowY: 'scroll',
	},
};

const InvoiceEditTable = ({
	invoiceItems,
	classes,
	removeInvoiceItem,
	addInvoiceItem,
	handleChangeInvoiceItem,
	totals,
	handleAutoComplete,
	invoiceOptions,
	vatPercentages,
}) => (
	<React.Fragment>
		<div className={classes.formContainer}>
			<Table>
				<InvoiceEditTableHeader />
				<TableBody>
					{invoiceItems.map((item, index) => (
						<InvoiceEditTableItem
							// eslint-disable-next-line
							key={index}
							item={item}
							index={index}
							removeInvoiceItem={removeInvoiceItem}
							addInvoiceItem={addInvoiceItem}
							handleChangeInvoiceItem={handleChangeInvoiceItem}
							vatPercentages={vatPercentages}
							handleAutoComplete={handleAutoComplete}
							invoiceOptions={invoiceOptions}
						/>
					))}
					<InvoiceEditTotals {...totals} />
				</TableBody>
			</Table>
		</div>
	</React.Fragment>
);

InvoiceEditTable.propTypes = {
	classes: PropTypes.object.isRequired,
	totals: PropTypes.object.isRequired,
	invoiceItems: PropTypes.array.isRequired,
	vatPercentages: PropTypes.array.isRequired,
	removeInvoiceItem: PropTypes.func.isRequired,
	addInvoiceItem: PropTypes.func.isRequired,
	handleChangeInvoiceItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvoiceEditTable);
