/* eslint-disable */

import {
	applyMiddleware, compose, createStore, combineReducers,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';

const defaultState = {};

const composeEnhancers =	typeof window === 'object'
	&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	}) : compose;

const middlewares = [
	thunkMiddleware,
];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(combineReducers(reducers), defaultState, enhancer);

export default store;
