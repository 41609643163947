const quoteItemTemplate = {
	description: '',
	VAT: '1.21',
	quantity: '',
	price: '',
	discount: '',
	typeOfWork: 'ONDERDELEN',
};

export default quoteItemTemplate;
