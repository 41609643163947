import React from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	CardMedia,
	CardHeader,
	withStyles,
	IconButton,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import AdresInfo from './detailComponents/AdresInfo';
import ContactInfo from './detailComponents/ContactInfo';
import FinancialInfo from './detailComponents/FinancialInfo';

const styles = {
	media: {
		height: 140,
		backgroundSize: 'contain',
	},
	contentDataSet: {
		display: 'flex',
		justifyContent: 'space-between',
	},
};

const AccountDetails = ({ account, loggedInUser, classes, toggleEditMode }) => {
	return (
		<Card className="full-width" elevation={1}>
			<CardHeader
				action={
					<IconButton onClick={toggleEditMode}>
						<Edit color="secondary" />
					</IconButton>
				}
				title={account.name}
				subheader={`${loggedInUser.firstName} ${loggedInUser.lastName}`}
			/>
			<CardMedia
				className={classes.media}
				image={account.companyImage}
				title="Company Logo"
			/>
			<CardContent>
				<div
					className={` ${classes.contentDataSet} p-left-large p-right-large `}
				>
					<div>
						<AdresInfo account={account} />
					</div>

					<div>
						<FinancialInfo account={account} />
					</div>
				</div>

				<div className="p-left-large p-right-large p-top-medium">
					<ContactInfo account={account} />
				</div>
			</CardContent>
		</Card>
	);
};

AccountDetails.propTypes = {
	account: PropTypes.object.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	toggleEditMode: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountDetails);
