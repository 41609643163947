import vatPercentages from '../constants/vatPercentages';

export default function calculateTotals(invoiceItems) {
	const discount = 1;
	let totalExVat = 0;
	let totalInclVat = 0;
	let totalVatHigh = 0;
	let totalVatLow = 0;
	let totalVatMarge = 0;
	let totalDiscount = 0;
	invoiceItems.map((item) => {
		if (item.price) {
			const discountFraction = discount - item.discount / 100;
			const subtotal = item.price * item.quantity;
			const withDiscount = subtotal * discountFraction;
			totalDiscount += subtotal * discountFraction - subtotal;
			totalExVat += subtotal * discountFraction;
			totalInclVat += subtotal * discountFraction * item.VAT;
			switch (parseFloat(item.VAT, 10)) {
				case parseFloat(vatPercentages[0].percentage, 10):
					totalVatHigh += withDiscount * item.VAT - withDiscount;
					break;
				case parseFloat(vatPercentages[1].percentage, 10):
					totalVatLow += withDiscount * item.VAT - withDiscount;
					break;
				case parseFloat(vatPercentages[3].percentage, 10):
					totalVatMarge += withDiscount * item.VAT - withDiscount;
					break;
				default:
					return item.VAT;
			}
		}
		return { totalExVat, totalInclVat, totalDiscount };
	});
	return {
		totalExVat,
		totalInclVat,
		totalVatHigh,
		totalVatLow,
		totalVatMarge,
		totalDiscount,
	};
}
