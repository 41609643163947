import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateInvoice from './CreateInvoice';
import ActionPaper from '../components/actionPaper/ActionPaper';
import EnhancedTable from '../components/enhancedTable/EnhancedTable';
import { openSnackbar } from '../redux/actions/snackbarActions';
import { rowsInvoice } from '../utils/TableHeads';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import {
	saveToCollection,
	updateDocument,
	deleteDocument,
	pushPayment,
} from '../services/firebase/firebaseMutations';
import { getDocument } from '../services/firebase/firebaseQueries';
import {
	INVOICES,
	LANDING,
	CREATEINVOICE,
	EDITINVOICE,
} from '../constants/routes';

class Invoices extends React.Component {
	state = {};

	render() {
		const {
			history,
			invoices,
			saveInvoice,
			updateInvoice,
			doPayment,
			account,
			clients,
			authUser,
			deleteDoc,
			getNewInvoice,
			doSave,
			getNewClient,
			authUser: { uid },
		} = this.props;
		return (
			<Switch>
				<Route
					exact
					path={INVOICES.path}
					component={() => (
						<React.Fragment>
							<Breadcrumbs
								pathArray={[
									{
										link: LANDING.path,
										title: LANDING.label,
									},
									{
										link: INVOICES.path,
										title: INVOICES.label,
									},
								]}
							/>
							<ActionPaper
								action={() => history.push(CREATEINVOICE.path)}
								actionName="Nieuwe Factuur"
								title="Facturen"
								icon="Add"
							>
								<EnhancedTable
									data={invoices}
									rows={rowsInvoice}
									datatype="invoices"
									deleteDoc={deleteDoc}
									doPayment={doPayment}
									getNewInvoice={getNewInvoice}
									account={account}
								/>
							</ActionPaper>
						</React.Fragment>
					)}
				/>
				<Route
					exact
					path={EDITINVOICE.path}
					component={(props) => (
						<CreateInvoice
							{...props}
							saveInvoice={saveInvoice}
							updateInvoice={updateInvoice}
							clients={clients}
							account={account}
							authUser={authUser}
							invoices={invoices}
							getNewInvoice={getNewInvoice}
							getNewClient={getNewClient}
							uid={uid}
						/>
					)}
				/>
				<Route
					exact
					path={CREATEINVOICE.path}
					component={(props) => (
						<CreateInvoice
							{...props}
							saveInvoice={saveInvoice}
							updateInvoice={updateInvoice}
							clients={clients}
							account={account}
							authUser={authUser}
							invoices={invoices}
							deleteDoc={deleteDoc}
							getNewInvoice={getNewInvoice}
							doSave={doSave}
							getNewClient={getNewClient}
							uid={uid}
						/>
					)}
				/>
			</Switch>
		);
	}
}

Invoices.propTypes = {
	invoices: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	authUser: PropTypes.object.isRequired,
	saveInvoice: PropTypes.func.isRequired,
	updateInvoice: PropTypes.func.isRequired,
	deleteDoc: PropTypes.func.isRequired,
	doPayment: PropTypes.func.isRequired,
	getNewInvoice: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	doSave: PropTypes.func.isRequired,
	account: PropTypes.object,
	clients: PropTypes.array.isRequired,
};

Invoices.defaultProps = {
	account: {},
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	loggedInUser: state.Session.loggedInUser,
	invoices: state.Invoices.invoices,
	clients: state.Clients.clients,
	account: state.Session.account,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	saveInvoice: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	updateInvoice: (data, collection, userId) =>
		dispatch(updateDocument(data, collection, userId)),
	deleteDoc: (collection, document, actionName) =>
		dispatch(deleteDocument(collection, document, actionName)),
	getNewInvoice: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
	doPayment: (payment, document) => dispatch(pushPayment(payment, document)),
	doSave: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	getNewClient: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Invoices);
