import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatCurrency, formatDate } from '../../../../utils/formatHelpers';

const styles = StyleSheet.create({
	text: {
		fontSize: 10,
		marginBottom: 2,
		fontFamily: 'Lato',
	},
	textBold: {
		fontSize: 10,
		marginBottom: 2,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 500,
	},
	viewMargin: {
		marginTop: 10,
		marginBottom: 10,
	},
});

const InvoicePDFFooter = ({
	invoiceToRender: { totalInclVat, expirationDate, invoiceNumber },
	account: { iban },
}) => {
	return (
		<View style={styles.viewMargin}>
			<Text style={styles.text}>
				Gelieve het bedrag van{' '}
				<Text style={styles.textBold}>
					{(totalInclVat && formatCurrency(totalInclVat)) || '€ FACTUUR_TOTAAL'}
				</Text>{' '}
				voor de vervaldatum{' '}
				<Text style={styles.textBold}>
					{(expirationDate && formatDate(expirationDate)) || 'VERVAL_DATUM'}
				</Text>{' '}
				overmaken op rekening{' '}
				<Text style={styles.textBold}>{iban || 'IBAN'}</Text> onder vermelding{' '}
				van uw factuurnummer:{' '}
				<Text style={styles.textBold}>{invoiceNumber || 'FACTUUR_NUMMER'}</Text>
			</Text>
		</View>
	);
};

InvoicePDFFooter.propTypes = {
	invoiceToRender: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
};

export default InvoicePDFFooter;
