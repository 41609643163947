import {
	OPEN_SNACKBAR,
	CLOSE_SNACKBAR,
	API_MESSAGE,
} from '../actions/snackbarActions';

// Don't forget to update the defaultState when adding new props

const defaultState = {
	snackbarOpen: false,
	snackbarMessage: '',
	variant: 'info',
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function loginReducer(state = defaultState, action) {
	switch (action.type) {
		case OPEN_SNACKBAR:
			return {
				...state,
				snackbarOpen: true,
				snackbarMessage: action.message,
				variant: action.variant,
			};
		case CLOSE_SNACKBAR:
			return {
				...state,
				snackbarOpen: false,
			};
		case API_MESSAGE: {
			return {
				...state,
				snackbarMessage: action.message,
				snackbarOpen: true,
				variant: action.variant,
			};
		}
		default:
			return state;
	}
}

export default loginReducer;
