import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '../../utils/formatHelpers';

const InvoiceFooter = ({
	iban,
	expirationDate,
	invoiceNumber,
	totalInclVat,
}) => (
	<div>
		<Typography variant="caption">
			{`Gelieve het bedrag van ${(totalInclVat &&
				formatCurrency(totalInclVat)) ||
				'€ FACTUUR_TOTAAL'} voor de vervaldatum ${expirationDate ||
				'VERVAL_DATUM'} overmaken op rekening ${iban ||
				'IBAN'} onder vermelding van uw factuurnummer: ${invoiceNumber ||
				'FACTUUR_NUMMER'}`}
		</Typography>
	</div>
);

InvoiceFooter.propTypes = {
	iban: PropTypes.string,
	expirationDate: PropTypes.string,
	invoiceNumber: PropTypes.number.isRequired,
	totalInclVat: PropTypes.number,
};

InvoiceFooter.defaultProps = {
	iban: '',
	expirationDate: '',
	totalInclVat: '',
};

export default InvoiceFooter;
