import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

const prodConfig = {
	apiKey: 'AIzaSyBIXtJ4YQgOhFx7iJbU5jk_W7lmxhzOtn0',
	authDomain: 'autobedrijfadministratienl.firebaseapp.com',
	databaseURL: 'https://autobedrijfadministratienl.firebaseio.com',
	projectId: 'autobedrijfadministratienl',
	storageBucket: 'autobedrijfadministratienl.appspot.com',
	messagingSenderId: '110860690364',
};

const devConfig = {
	apiKey: 'AIzaSyBIXtJ4YQgOhFx7iJbU5jk_W7lmxhzOtn0',
	authDomain: 'autobedrijfadministratienl.firebaseapp.com',
	databaseURL: 'https://autobedrijfadministratienl.firebaseio.com',
	projectId: 'autobedrijfadministratienl',
	storageBucket: 'autobedrijfadministratienl.appspot.com',
	messagingSenderId: '110860690364',
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}
const secondaryApp = firebase.initializeApp(config, 'Secondary');

const db = firebase.database();
const auth = firebase.auth();
const dbf = firebase.firestore();
const storageService = firebase.storage();
const storageRef = storageService.ref();
const secondaryAppAuth = secondaryApp.auth();

export {
	db,
	auth,
	dbf,
	storageService,
	storageRef,
	secondaryAppAuth,
	firebase,
};
