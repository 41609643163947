import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withRouter } from 'react-router';
import FirstTimeWizard from '../dialogs/firstTimeWizard/FirstTimeWizard';
import AccessControl from '../accessControl/AccessControl';
import * as routes from '../../constants/routes';
import Drawer from '../navigation/SideDrawer';
import Header from '../navigation/Header';
import { doSignOut as SignOut } from '../../redux/actions/sessionActions';
import {
	saveToCollection,
	updateFirstTime,
} from '../../services/firebase/firebaseMutations';
import {
	getDocument,
	queryByOwner,
} from '../../services/firebase/firebaseQueries';

const drawerWidth = 200;

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 7 + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		overflowX: 'hidden',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		padding: '0px 10px',
	},
	containerShift: {
		justifyContent: 'flex-end',
	},
});

class PageLayout extends React.Component {
	state = {
		open: false,
	};

	componentDidMount = () => {
		const { authUser, checkedForAuth, history } = this.props;
		if (!authUser && checkedForAuth) {
			history.push(routes.SIGNIN.path);
		}
		if (authUser) {
			this.setState({ uid: authUser.uid }, () => {
				this.getLoggedInUser();
				this.getAccountInfo();
				this.getAllInvoices();
				this.getAllClients();
				this.getAllQuotes();
			});
		}
	};

	componentDidUpdate = (prevProps) => {
		const { authUser } = this.props;
		if (prevProps.authUser !== authUser) {
			this.setState({ uid: authUser.uid }, () => {
				this.getLoggedInUser();
				this.getAccountInfo();
				this.getAllInvoices();
				this.getAllClients();
				this.getAllQuotes();
			});
		}
	};

	getLoggedInUser = () => {
		const { uid } = this.state;
		const { getUser } = this.props;
		getUser('users', uid, 'get_logged_in_user');
	};

	getAccountInfo = () => {
		const { uid } = this.state;
		const { getByOwner } = this.props;
		getByOwner(uid, 'organisations', 'get_account_info');
	};

	getAllInvoices = () => {
		const { uid } = this.state;
		const { getByOwner } = this.props;
		getByOwner(uid, 'invoices', 'get_all_invoices');
	};

	getAllClients = () => {
		const { uid } = this.state;
		const { getByOwner } = this.props;
		getByOwner(uid, 'clients', 'get_all_clients');
	};

	getAllQuotes = () => {
		const { uid } = this.state;
		const { getByOwner } = this.props;
		getByOwner(uid, 'quotes', 'get_all_quotes');
	};

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	render() {
		const {
			classes,
			theme,
			children,
			authUser,
			history,
			checkedForAuth,
			doSignOut,
			loggedInUser: { firstTimeLogin },
			loggedInUser,
			saveOrganisation,
		} = this.props;
		const { open } = this.state;
		return (
			<AccessControl
				authUser={authUser}
				checkedForAuth={checkedForAuth}
				history={history}
			>
				<div className={classes.root}>
					<CssBaseline />
					{firstTimeLogin && loggedInUser && authUser && (
						<FirstTimeWizard
							loggedInUser={loggedInUser}
							saveOrganisation={saveOrganisation}
							userId={authUser.uid}
							updateFirstTime={updateFirstTime}
							getAccountInfo={this.getAccountInfo}
						/>
					)}
					<Header
						classes={classes}
						open={open}
						handleDrawerOpen={this.handleDrawerOpen}
						history={history}
						doSignOut={doSignOut}
						loggedInUser={loggedInUser}
					/>
					<Drawer
						classes={classes}
						open={open}
						theme={theme}
						handleDrawerClose={this.handleDrawerClose}
					/>
					<main className={`${classes.content} m-top-xx-large`}>
						{children}
					</main>
				</div>
			</AccessControl>
		);
	}
}

PageLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUser: PropTypes.shape({
		firstTimeLogin: PropTypes.bool,
	}),
	theme: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired,
	history: PropTypes.object.isRequired,
	checkedForAuth: PropTypes.bool.isRequired,
	authUser: PropTypes.object,
	doSignOut: PropTypes.func.isRequired,
	saveOrganisation: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
	getByOwner: PropTypes.func.isRequired,
};

PageLayout.defaultProps = {
	authUser: false,
	loggedInUser: { firstTimeLogin: false },
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	checkedForAuth: state.Session.checkedForAuth,
	loggedInUser: state.Session.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
	doSignOut: () => dispatch(SignOut()),
	getUser: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
	saveOrganisation: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	getByOwner: (userId, collection, actionName) =>
		dispatch(queryByOwner(userId, collection, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(PageLayout)));
