import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openSnackbar } from '../redux/actions/snackbarActions';
import ActionPaper from '../components/actionPaper/ActionPaper';
import EnhancedTable from '../components/enhancedTable/EnhancedTable';
import { rowsClient } from '../utils/TableHeads';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import { CLIENTS, LANDING, CREATECLIENT } from '../constants/routes';
import { deleteDocument } from '../services/firebase/firebaseMutations';

class Clients extends React.Component {
	state = {};

	render() {
		const { history, clients, deleteDoc } = this.props;
		return (
			<React.Fragment>
				<Breadcrumbs
					pathArray={[
						{
							link: LANDING.path,
							title: LANDING.label,
						},
						{
							link: CLIENTS.path,
							title: CLIENTS.label,
						},
					]}
				/>
				<ActionPaper
					action={() => history.push(CREATECLIENT.path)}
					actionName="Nieuwe Klant"
					title="Klanten"
					icon="Add"
				>
					<EnhancedTable
						data={clients}
						rows={rowsClient}
						datatype="clients"
						deleteDoc={deleteDoc}
					/>
				</ActionPaper>
			</React.Fragment>
		);
	}
}

Clients.propTypes = {
	history: PropTypes.object.isRequired,
	clients: PropTypes.array.isRequired,
	deleteDoc: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	loggedInUser: state.Session.loggedInUser,
	clients: state.Clients.clients,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	deleteDoc: (collection, document, actionName) =>
		dispatch(deleteDocument(collection, document, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Clients);
