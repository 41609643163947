import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Typography, Grid } from '@material-ui/core';
import { openSnackbar } from '../redux/actions/snackbarActions';
import EnhancedTable from '../components/enhancedTable/EnhancedTable';
import ActionPaper from '../components/actionPaper/ActionPaper';
import {
	deleteDocument,
	pushPayment,
} from '../services/firebase/firebaseMutations';
import { rowsInvoice } from '../utils/TableHeads';
import { CREATEINVOICE } from '../constants/routes';
import { getDocument } from '../services/firebase/firebaseQueries';
import {
	calculateTotalInvoiced,
	calculateTotalReceivedPayments,
	numberOfExpiredInvoices,
} from '../utils/invoiceCalculations';
import { formatCurrency } from '../utils/formatHelpers';

class LandingPage extends React.Component {
	state = {};

	componentDidMount = () => {};

	render() {
		const {
			history,
			invoices,
			deleteDoc,
			account,
			getNewInvoice,
			doPayment,
		} = this.props;
		return (
			<React.Fragment>
				<Grid container spacing={4} className="m-bottom-large">
					<Grid item xs={3}>
						<Paper className="p-around-large">
							<Typography variant="body1">Totaal gefactureerd:</Typography>
							<Typography variant="caption">
								{formatCurrency(calculateTotalInvoiced(invoices))}
							</Typography>
							<Typography variant="body1" className="m-top-xx-small">
								Totaal betaald:
							</Typography>
							<Typography variant="caption">
								{formatCurrency(calculateTotalReceivedPayments(invoices))}
							</Typography>
							<Typography variant="body1" className="m-top-xx-small">
								Totaal openstaand:
							</Typography>
							<Typography variant="caption">
								{formatCurrency(
									calculateTotalInvoiced(invoices) -
										calculateTotalReceivedPayments(invoices)
								)}{' '}
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper className="p-around-large">
							<Typography variant="body1" className="m-top-xx-small">
								Verlopen Facturen
							</Typography>
							<Typography variant="caption">
								{numberOfExpiredInvoices(invoices).length}
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper className="p-around-large">element</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper className="p-around-large">
							<Typography>Element</Typography>
						</Paper>
					</Grid>
				</Grid>
				<ActionPaper
					action={() => history.push(CREATEINVOICE.path)}
					actionName="Nieuwe Factuur"
					title="Recente Facturen"
					icon="Add"
				>
					<EnhancedTable
						data={invoices}
						rows={rowsInvoice}
						datatype="invoices"
						deleteDoc={deleteDoc}
						doPayment={doPayment}
						getNewInvoice={getNewInvoice}
						account={account}
					/>
				</ActionPaper>
			</React.Fragment>
		);
	}
}

LandingPage.propTypes = {
	history: PropTypes.object.isRequired,
	invoices: PropTypes.array.isRequired,
	deleteDoc: PropTypes.func.isRequired,
	doPayment: PropTypes.func.isRequired,
	getNewInvoice: PropTypes.func.isRequired,
	account: PropTypes.object,
};

LandingPage.defaultProps = {
	account: {},
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	loggedInUser: state.Session.loggedInUser,
	invoices: state.Invoices.invoices,
	account: state.Session.account,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	deleteDoc: (collection, document, actionName) =>
		dispatch(deleteDocument(collection, document, actionName)),
	getNewInvoice: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
	doPayment: (payment, document) => dispatch(pushPayment(payment, document)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
