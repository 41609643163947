import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import InvoiceSave from '../components/createInvoiceComponents/InvoiceSave';
import ActionPaper from '../components/actionPaper/ActionPaper';
import InvoiceForm from '../components/createInvoiceComponents/InvoiceForm';
import InvoiceBaseData from '../components/createInvoiceComponents/InvoiceBaseData';
import InvoiceEditTable from '../components/createInvoiceComponents/InvoiceEditTable/InvoiceEditTable';
import InvoiceFooter from '../components/createInvoiceComponents/InvoiceFooter';
import InvoiceRemarks from '../components/createInvoiceComponents/InvoiceRemarks';
import FormSnackbar from '../components/formSnackbar/FormSnackbar';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import calculateTotals from '../helpers/calculateTotals';
import getNewNumber from '../helpers/getNewNumber';
import validateInvoiceOrQuote from '../utils/invoiceAndQuoteValidation';
import vatPercentages from '../constants/vatPercentages';
import invoiceItemTemplate from '../constants/invoiceItemTemplate';
import ClientCreateDialog from '../components/createClientComponents/ClientCreateDialog';
import TotalDiscountReceived from '../components/TotalDiscountReceived';

import {
	LANDING,
	INVOICES,
	CREATEINVOICE,
	EDITINVOICE,
} from '../constants/routes';

const styles = {};

const now = moment().format('YYYY-MM-DD');

const datatype = 'invoice';

class CreateInvoice extends React.Component {
	state = {
		invoiceNumber: 0,
		client: {},
		invoiceDate: now,
		invoiceId: null,
		expirationDate: '',
		vehicleType: '',
		licensePlate: '',
		milage: '',
		remarks: '',
		errorArray: [],
		invoiceItems: [
			{
				...invoiceItemTemplate,
			},
		],
		addClientOpen: false,
		editMode: false,
	};

	componentDidMount = () => {
		const {
			match: {
				params: { invoiceId },
			},
			invoices,
		} = this.props;
		if (invoiceId) {
			this.setState({
				editMode: true,
				invoiceId,
			});
			const invoiceToEdit = invoices.filter(
				(invoice) => invoice.id === invoiceId
			);
			this.setState({
				...invoiceToEdit[0],
			});
		} else {
			this.setState({
				expirationDate: moment()
					.add(14, 'days')
					.format('YYYY-MM-DD'),

				invoiceNumber: getNewNumber(invoices, 'invoiceNumber'),
			});
		}
	};

	setClient = (clientToSet) => {
		this.setState({
			client: { ...clientToSet },
		});
	};

	handleAddClientDialogOpen = () => {
		this.setState({ addClientOpen: true });
	};

	handleAddClientDialogClose = () => {
		this.setState({ addClientOpen: false });
	};

	addInvoiceItem = () => {
		this.setState((prevState) => ({
			invoiceItems: [...prevState.invoiceItems, { ...invoiceItemTemplate }],
		}));
	};

	removeInvoiceItem = (index) => {
		if (this.state.invoiceItems.length > 1) {
			this.setState((prevState) => ({
				invoiceItems: [
					...prevState.invoiceItems.slice(0, index),
					...prevState.invoiceItems.slice(index + 1),
				],
			}));
		}
	};

	handleChangeInvoiceItem = (index, name) => (event) => {
		const { value } = event.target;
		this.setState((prevState) => {
			const newItems = [...prevState.invoiceItems];
			newItems[index][name] = value;
			if (newItems[index].price && newItems[index].quantity) {
				const { discount, quantity, price } = newItems[index];
				const subtotal = quantity * price * (1 - discount / 100);
				newItems[index].subtotal = subtotal;
			}
			return { invoiceItems: newItems };
		});
	};

	handleAutoComplete = (index, name, value) => {
		this.setState((prevState) => {
			const newItems = [...prevState.invoiceItems];
			newItems[index][name] = value;
			if (newItems[index].price && newItems[index].quantity) {
				const { discount, quantity, price } = newItems[index];
				const subtotal = quantity * price * (1 - discount / 100);
				newItems[index].subtotal = subtotal;
			}
			return { invoiceItems: newItems };
		});
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
		if (name === 'invoiceDate') {
			return this.setState({
				expirationDate: moment(event.target.value)
					.add(14, 'days')
					.format('YYYY-MM-DD'),
			});
		}
		return '';
	};

	saveInvoice = async () => {
		const totals = calculateTotals(this.state.invoiceItems);
		const {
			saveInvoice,
			authUser,
			updateInvoice,
			getNewInvoice,
			history,
			invoices,
		} = this.props;
		const {
			invoiceItems,
			invoiceNumber,
			invoiceDate,
			invoiceId,
			expirationDate,
			vehicleType,
			licensePlate,
			milage,
			remarks,
			editMode,
			client,
		} = this.state;
		const invoice = {
			invoiceItems,
			invoiceNumber: parseInt(invoiceNumber, 10),
			invoiceDate,
			expirationDate,
			vehicleType,
			licensePlate,
			milage,
			remarks,
			client,
			payments: [],
			...totals,
		};
		let id = '';
		const errorArray = validateInvoiceOrQuote(
			invoiceItems,
			invoiceNumber,
			invoiceDate,
			expirationDate,
			client,
			invoices,
			editMode,
			datatype
		);
		this.setState({ errorArray });
		if (errorArray.length > 0) {
			return;
		}

		if (!editMode) {
			id = await saveInvoice(invoice, 'invoices', authUser.uid, 'save_invoice');
			await getNewInvoice('invoices', id, 'new_invoice');
		} else {
			updateInvoice(invoice, 'invoices', invoiceId);
			await getNewInvoice('invoices', invoiceId, 'updated_invoice');
		}
		history.push(INVOICES.path);
	};

	render() {
		const {
			invoiceNumber,
			invoiceDate,
			expirationDate,
			vehicleType,
			licensePlate,
			milage,
			invoiceItems,
			remarks,
			addClientOpen,
			editMode,
			errorArray,
		} = this.state;
		const {
			account,
			clients,
			doSave,
			getNewClient,
			uid,
			invoices,
		} = this.props;
		const totals = calculateTotals(this.state.invoiceItems);

		const invoiceOptions = [];
		if (invoices.length > 0) {
			invoices.map((invoice) => {
				invoice.invoiceItems.map((item) => {
					invoiceOptions.push(item.description);
				});
			});
		}

		return (
			<div className="m-top-large">
				<Breadcrumbs
					pathArray={[
						{
							link: LANDING.path,
							title: LANDING.label,
						},
						{
							link: INVOICES.path,
							title: INVOICES.label,
						},
						{
							link: editMode ? EDITINVOICE.path : CREATEINVOICE.path,
							title: editMode ? EDITINVOICE.label : CREATEINVOICE.label,
						},
					]}
				/>
				{account && (
					<ActionPaper
						title={editMode ? 'Bewerk Factuur' : 'Nieuwe Factuur'}
						className="p-left-xx-large p-right-xx-large p-top-large p-bottom-large"
					>
						<InvoiceSave saveInvoice={this.saveInvoice} />
						<InvoiceBaseData
							company={account}
							clients={clients}
							client={this.state.client}
							vehicleType={vehicleType}
							licensePlate={licensePlate}
							milage={milage}
							setClient={this.setClient}
							handleChange={this.handleChange}
							addClientOpen={addClientOpen}
							editMode={editMode}
							handleAddClientDialogClose={this.handleAddClientDialogClose}
							handleAddClientDialogOpen={this.handleAddClientDialogOpen}
						/>
						<div className="m-top-medium m-bottom-medium" />
						<InvoiceForm
							handleChange={this.handleChange}
							invoiceNumber={invoiceNumber}
							editMode={editMode}
							invoiceDate={invoiceDate}
							expirationDate={expirationDate}
						/>
						<div className="m-top-medium m-bottom-medium" />
						<InvoiceEditTable
							invoiceItems={invoiceItems}
							addInvoiceItem={this.addInvoiceItem}
							removeInvoiceItem={this.removeInvoiceItem}
							handleChangeInvoiceItem={this.handleChangeInvoiceItem}
							handleAutoComplete={this.handleAutoComplete}
							handleChange={this.handleChange}
							totals={totals}
							vatPercentages={vatPercentages}
							invoiceOptions={invoiceOptions}
						/>
						<TotalDiscountReceived discount={totals.totalDiscount} />
						<div className="m-top-medium m-bottom-medium" />
						<InvoiceRemarks
							handleChange={this.handleChange}
							remarks={remarks}
						/>
						<div className="m-top-medium m-bottom-medium" />
						<InvoiceFooter
							totalInclVat={totals.totalInclVat}
							invoiceNumber={invoiceNumber}
							iban={account.iban}
							expirationDate={moment(expirationDate).format('DD-MM-YYYY')}
						/>
					</ActionPaper>
				)}
				<FormSnackbar errorArray={errorArray} datatype={datatype} />
				<ClientCreateDialog
					fromDialog
					doSave={doSave}
					addClientOpen={addClientOpen}
					handleAddClientDialogClose={this.handleAddClientDialogClose}
					getNewClient={getNewClient}
					uid={uid}
				/>
			</div>
		);
	}
}

CreateInvoice.propTypes = {
	match: PropTypes.object.isRequired,
	invoices: PropTypes.array.isRequired,
	saveInvoice: PropTypes.func.isRequired,
	authUser: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateInvoice: PropTypes.func.isRequired,
	getNewInvoice: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	doSave: PropTypes.func.isRequired,
	uid: PropTypes.string.isRequired,
	account: PropTypes.object,
	clients: PropTypes.array,
};

CreateInvoice.defaultProps = {
	account: null,
	clients: [],
};

export default withStyles(styles)(CreateInvoice);
