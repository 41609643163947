import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { formatDate, formatCurrency } from '../../../utils/formatHelpers';

const PaymentHistoryTable = ({ payments }) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell align="left">Betaaldatum</TableCell>
					<TableCell align="right">Bedrag</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{payments.map((payment, index) => (
					// eslint-disable-next-line
					<TableRow key={`${payment.paymentDate}${payment.amount}${index}`}>
						<TableCell align="left">
							{formatDate(payment.paymentDate)}
						</TableCell>
						<TableCell align="right">
							{formatCurrency(payment.amount)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

PaymentHistoryTable.propTypes = {
	payments: PropTypes.array.isRequired,
};
export default PaymentHistoryTable;
