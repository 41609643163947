export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const openSnackbar = (message, variant) => ({
	type: OPEN_SNACKBAR,
	message,
	variant,
});

export const API_MESSAGE = 'API_MESSAGE';
export const openSnackbarApiMessage = (message, language, variant) => ({
	type: API_MESSAGE,
	message,
	language,
	variant,
});

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const closeSnackbar = () => ({
	type: CLOSE_SNACKBAR,
});
