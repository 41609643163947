export default function validateInvoiceOrQuote(
	items,
	number,
	date,
	expirationDate,
	client,
	list,
	editMode,
	datatype
) {
	const errorArray = [];

	const filteredEmptyItems = items.filter(
		(item) =>
			item.description === '' || item.price === '' || item.quantity === ''
	);

	if (!number) {
		errorArray.push('missingNumber');
	}
	if (!date) {
		errorArray.push('missingDate');
	}
	if (!expirationDate) {
		errorArray.push('missingExpirationDate');
	}

	if (Object.keys(client).length === 0) {
		errorArray.push('missingClient');
	}

	if (filteredEmptyItems.length > 0) {
		errorArray.push('missingFieldsInItems');
	}

	if (
		list.filter((item) => item[`${datatype}Number`] === parseInt(number, 10))
			.length > 0 &&
		!editMode
	) {
		errorArray.push('numberInUse');
	}

	return errorArray;
}
