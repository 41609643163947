import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../utils/formatHelpers';

const InvoiceEditTotals = ({
	totalExVat,
	totalInclVat,
	totalVatHigh,
	totalVatLow,
	totalVatMarge,
	typesOfWork,
}) => (
	<React.Fragment>
		<TableRow>
			<TableCell rowSpan={5} />
			<TableCell colSpan={3}>Totaal exclusief BTW</TableCell>
			<TableCell
				align="right"
				padding="none"
				colSpan={typesOfWork ? 3 : 2}
				className="p-right-medium"
			>
				<Typography variant="subtitle2">
					{formatCurrency(totalExVat)}
				</Typography>
			</TableCell>
		</TableRow>
		{totalVatMarge ? (
			<TableRow>
				<TableCell colSpan={3}>BTW 0%</TableCell>
				<TableCell
					align="right"
					padding="none"
					colSpan={0}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">
						{formatCurrency(totalVatMarge)}
					</Typography>
				</TableCell>
				<TableCell
					align="right"
					padding="none"
					colSpan={typesOfWork ? 2 : 0}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">{formatCurrency(0)}</Typography>
				</TableCell>
			</TableRow>
		) : (
			<React.Fragment />
		)}

		{totalVatLow ? (
			<TableRow>
				<TableCell colSpan={3}>BTW 9%</TableCell>
				<TableCell
					align="right"
					padding="none"
					colSpan={typesOfWork ? 3 : 2}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">
						{formatCurrency(totalVatLow)}
					</Typography>
				</TableCell>
			</TableRow>
		) : (
			<React.Fragment />
		)}

		{totalVatHigh ? (
			<TableRow>
				<TableCell colSpan={3}>BTW 21%</TableCell>
				<TableCell
					align="right"
					padding="none"
					colSpan={typesOfWork ? 3 : 2}
					className="p-right-medium"
				>
					<Typography variant="subtitle2">
						{formatCurrency(totalVatHigh)}
					</Typography>
				</TableCell>
			</TableRow>
		) : (
			<React.Fragment />
		)}

		<TableRow>
			<TableCell colSpan={3}>Totaal inclusief BTW</TableCell>
			<TableCell
				align="right"
				padding="none"
				colSpan={typesOfWork ? 3 : 2}
				className="p-right-medium"
			>
				<Typography variant="subtitle2">
					{formatCurrency(totalInclVat)}
				</Typography>
			</TableCell>
		</TableRow>
	</React.Fragment>
);

InvoiceEditTotals.propTypes = {
	totalExVat: PropTypes.number.isRequired,
	totalInclVat: PropTypes.number.isRequired,
	totalVatHigh: PropTypes.number.isRequired,
	totalVatLow: PropTypes.number.isRequired,
	totalVatMarge: PropTypes.number.isRequired,
	typesOfWork: PropTypes.array,
};

InvoiceEditTotals.defaultProps = {
	typesOfWork: null,
};

export default InvoiceEditTotals;
