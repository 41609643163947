import React from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceTemplate from './invoiceTemplate/InvoiceTemplate';

const CreateAndDownloadInvoice = ({ invoiceToRender, account, wipePDf }) => {
	return (
		<div id="divId">
			{invoiceToRender && (
				<PDFDownloadLink
					document={
						<InvoiceTemplate
							invoiceToRender={invoiceToRender}
							account={account}
						/>
					}
					fileName={`${invoiceToRender.client.value.name.replace(/\s/g, '')}-${
						invoiceToRender.invoiceNumber
					}.pdf`}
				>
					{({ blob, url, loading, error }) => {
						if (!loading) {
							setTimeout(() => {
								document.getElementById('divId').children[0].click();
								wipePDf();
							}, 3);
						}
					}}
				</PDFDownloadLink>
			)}
		</div>
	);
};

CreateAndDownloadInvoice.propTypes = {
	invoiceToRender: PropTypes.object.isRequired,
	wipePDf: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired,
};

export default CreateAndDownloadInvoice;
