const dateOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
};
const currencyOptions = {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
};

export const formatDate = (dateToFormat, clockTime) => {
	return new Date(dateToFormat).toLocaleDateString('nl-NL', {
		...dateOptions,
		...(clockTime && {
			minute: 'numeric',
			hour: 'numeric',
		}),
	});
};

export const formatCurrency = (numberToFormat, noEuro = false) => {
	return `${noEuro ? '' : `€ `}${numberToFormat.toLocaleString(
		'nl-NL',
		currencyOptions
	)}`;
};
