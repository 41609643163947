import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

const QuoteTypeOfWorkHead = ({ typeOfWork, styles }) => {
	return (
		<View style={styles.tableHead}>
			<Text style={styles.tableHeadTextWidthLarge}>{typeOfWork}</Text>
			<Text style={styles.tableHeadTextWidthSmall}>Aantal</Text>
			<Text style={styles.tableHeadTextWidthSmall}>Prijs</Text>
			<Text style={styles.tableHeadTextWidthSmall}>Subtotaal</Text>
		</View>
	);
};

QuoteTypeOfWorkHead.propTypes = {
	typeOfWork: PropTypes.string.isRequired,
	styles: PropTypes.object.isRequired,
};

export default QuoteTypeOfWorkHead;
