import { db, dbf } from './firebase';

// User API

export const doCreateUser = (id, username, email) =>
	db.ref(`users/${id}`).set({
		username,
		email,
	});

export const doCreateFirestoreUser = (id, data) =>
	dbf
		.collection('users')
		.doc(id)
		.set({
			...data,
		});
