import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	paper: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
});

const SimpleBreadcrumbs = ({ classes, pathArray }) => {
	const arrLength = pathArray.length;
	return (
		<div className={`${classes.root} m-bottom-medium`}>
			<Paper className={classes.paper}>
				<Breadcrumbs>
					{pathArray.map((pathItem, index) => {
						if (arrLength === index + 1) {
							return (
								<Typography
									color="textPrimary"
									variant="body2"
									key={pathItem.title}
								>
									{pathItem.title}
								</Typography>
							);
						}
						return (
							<Link
								component={RouterLink}
								key={`${pathItem.title}${pathItem.link}`}
								to={pathItem.link}
							>
								{pathItem.title}
							</Link>
						);
					})}
				</Breadcrumbs>
			</Paper>
		</div>
	);
};

SimpleBreadcrumbs.propTypes = {
	classes: PropTypes.object.isRequired,
	pathArray: PropTypes.array.isRequired,
};

export default withStyles(styles)(SimpleBreadcrumbs);
