// Don't forget to update the defaultState when adding new props
const defaultState = {
	authUser: null,
	checkedForAuth: false,
	loggedInUser: {},
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function sessionReducer(state = defaultState, action) {
	switch (action.type) {
		case 'AUTH_USER_SET':
			return {
				...state,
				authUser: action.authUser,
				checkedForAuth: true,
			};
		case 'SIGN_OUT':
			return {
				...state,
				authUser: null,
				checkedForAuth: false,
			};
		case 'GET_LOGGED_IN_USER_DONE':
			return {
				...state,
				loggedInUser: action.data,
			};
		case 'GET_SPECIFIC_USER_DONE':
			return {
				...state,
				loggedInUser: action.data,
			};
		case 'GET_ACCOUNT_INFO_DONE':
			return {
				...state,
				account: action.data[0],
			};
		case 'UPDATE_ACCOUNT_INFO_DONE':
			return { ...state, account: action.data };
		default:
			return state;
	}
}

export default sessionReducer;
