import { LOGIN_REQUEST_SENT } from '../actions/loginActions';

// Don't forget to update the defaultState when adding new props
const defaultState = {
	loginRequestSent: false,
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function loginReducer(state = defaultState, action) {
	switch (action.type) {
		case LOGIN_REQUEST_SENT:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default loginReducer;
