import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	inputStyle: {
		border: 'none',
		fontSize: '0.8rem',
		'&:focus': {
			outline: 'none',
		},
	},
	fullWidth: {
		width: '100%',
	},
	textarea: {
		resize: 'none',
	},
};

const BareInput = ({
	handleChange,
	placeholder,
	classes,
	value,
	field,
	fullWidth,
	disabled,
	type,
}) => (
	<input
		onChange={handleChange(field)}
		placeholder={placeholder}
		type={type}
		value={value}
		disabled={disabled}
		className={`${classes.inputStyle} ${fullWidth && classes.fullWidth}`}
	/>
);

BareInput.propTypes = {
	handleChange: PropTypes.func,
	placeholder: PropTypes.string,
	field: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	value: PropTypes.any,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string,
};

BareInput.defaultProps = {
	fullWidth: false,
	value: '',
	disabled: false,
	type: 'text',
	placeholder: '',
	handleChange: () => {},
};

export default withStyles(styles)(BareInput);
