import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { openSnackbar } from '../redux/actions/snackbarActions';
import ActionPaper from '../components/actionPaper/ActionPaper';
import ClientCreateForm from '../components/createClientComponents/ClientCreateForm';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import { getDocument } from '../services/firebase/firebaseQueries';
import {
	saveToCollection,
	updateDocument,
} from '../services/firebase/firebaseMutations';
import {
	LANDING,
	CLIENTS,
	CREATECLIENT,
	EDITCLIENT,
} from '../constants/routes';

const styles = {};

const CreateClient = ({
	clients,
	match: {
		params: { clientId },
	},
	doSave,
	authUser: { uid },
	doEdit,
	getNewClient,
	history,
}) => {
	return (
		<div className="m-top-large">
			<Breadcrumbs
				pathArray={[
					{
						link: LANDING.path,
						title: LANDING.label,
					},
					{
						link: CLIENTS.path,
						title: CLIENTS.label,
					},
					{
						link: clientId ? EDITCLIENT.path : CREATECLIENT.path,
						title: clientId ? EDITCLIENT.label : CREATECLIENT.label,
					},
				]}
			/>
			<ActionPaper
				title={clientId ? EDITCLIENT.label : CREATECLIENT.label}
				className="p-left-xx-large p-right-xx-large p-top-large p-bottom-large"
			>
				<ClientCreateForm
					clientId={clientId}
					clients={clients}
					doSave={doSave}
					doEdit={doEdit}
					uid={uid}
					getNewClient={getNewClient}
					history={history}
				/>
			</ActionPaper>
		</div>
	);
};

CreateClient.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	clients: PropTypes.array.isRequired,
	doSave: PropTypes.func.isRequired,
	doEdit: PropTypes.func.isRequired,
	getNewClient: PropTypes.func.isRequired,
	authUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	loggedInUser: state.Session.loggedInUser,
	clients: state.Clients.clients,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	doSave: (data, collection, userId, actionName) =>
		dispatch(saveToCollection(data, collection, userId, actionName)),
	doEdit: (data, collection, userId) =>
		dispatch(updateDocument(data, collection, userId)),
	getNewClient: (collection, document, actionName) =>
		dispatch(getDocument(collection, document, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(CreateClient));
