import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import classnames from 'classnames';
import initialSettings from '../../utils/initialSettings';

const styles = {
	iconNonActive: {
		color: '#cecece !important',
		fontSize: '20px',
	},
	iconActive: {
		color: 'green !important',
		transition: 'color 0.2s ease',
	},
};

const AccountCreateHelper = ({ classes, passwordOne, passwordTwo }) => {
	return (
		<Grid container>
			<Grid item md={6} xs={12}>
				<Typography className="flex-center m-bottom-xx-small" variant="caption">
					<CheckIcon
						className={classnames(classes.iconNonActive, {
							[classes.iconActive]: passwordOne.length >= 6,
						})}
					/>
					Minimaal 6 characters
				</Typography>
			</Grid>
			<Grid item md={6} xs={12}>
				<Typography className="flex-center m-bottom-xx-small" variant="caption">
					<CheckIcon
						className={classnames(classes.iconNonActive, {
							[classes.iconActive]: initialSettings.containsNumber.test(
								passwordOne
							),
						})}
					/>
					Bevat minimaal 1 nummer
				</Typography>
			</Grid>
			<Grid item md={6} xs={12}>
				<Typography className="flex-center" variant="caption">
					<CheckIcon
						className={classnames(classes.iconNonActive, {
							[classes.iconActive]: passwordOne && passwordOne === passwordTwo,
						})}
					/>
					Wachtwoorden komen overeen
				</Typography>
			</Grid>
		</Grid>
	);
};

AccountCreateHelper.propTypes = {
	classes: PropTypes.object.isRequired,
	passwordOne: PropTypes.string,
	passwordTwo: PropTypes.string,
};

AccountCreateHelper.defaultProps = {
	passwordOne: '',
	passwordTwo: '',
};

export default withStyles(styles)(AccountCreateHelper);
