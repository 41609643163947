import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	inputStyle: {
		border: 'none',
		'&:focus': {
			outline: 'none',
		},
	},
	fullWidth: {
		width: '100%',
	},
	textarea: {
		resize: 'none',
	},
};

const BareInput = ({
	handleChange,
	placeholder,
	classes,
	value,
	field,
	fullWidth,
}) => (
	<textarea
		onChange={handleChange(field)}
		placeholder={placeholder}
		value={value}
		className={`${classes.inputStyle} ${classes.textarea} ${fullWidth &&
			classes.fullWidth}`}
		rows={4}
	/>
);

BareInput.propTypes = {
	handleChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	field: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
};

BareInput.defaultProps = {
	fullWidth: false,
};

export default withStyles(styles)(BareInput);
