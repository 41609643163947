import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import calculateTotalPayment from '../../helpers/caclulateTotalPayment';
import colors from '../../utils/colors';

const styles = {
	done: {
		backgroundColor: colors.success,
		color: colors.white,
		textAlign: 'center',
		borderRadius: '5px',
		width: '60px',
	},
	pending: {
		backgroundColor: colors.pending,
		color: colors.white,
		textAlign: 'center',
		borderRadius: '3px',
		width: '60px',
	},
	expired: {
		backgroundColor: colors.failure,
		color: colors.white,
		textAlign: 'center',
		borderRadius: '3px',
		width: '60px',
	},
};

const InvoiceStatus = ({
	classes,
	invoice: { totalInclVat, payments, expirationDate },
}) => {
	if (totalInclVat === calculateTotalPayment(payments)) {
		return (
			<Typography
				className={`${classes.done} p-around-xx-small`}
				variant="caption"
				component="span"
			>
				betaald
			</Typography>
		);
	}
	if (
		calculateTotalPayment(payments) < totalInclVat &&
		new Date(expirationDate) < Date.now()
	) {
		return (
			<Typography
				className={`${classes.expired} p-around-xx-small`}
				variant="caption"
				component="span"
			>
				verlopen
			</Typography>
		);
	}
	return (
		<Typography
			className={`${classes.pending} p-around-xx-small`}
			variant="caption"
			component="span"
		>
			open
		</Typography>
	);
};

export default withStyles(styles)(InvoiceStatus);

InvoiceStatus.propTypes = {
	classes: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
};
