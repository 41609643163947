import React from 'react';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
	container: {
		right: '0',
		bottom: '0',
		zIndex: '10',
		position: 'fixed',
	},
	fab: {
		margin: '12px',
	},
	extendedIcon: {
		marginRight: '16px',
	},
};

const InvoiceSave = ({ classes, saveInvoice }) => (
	<div className={classes.container}>
		<Fab
			variant="extended"
			className={classes.fab}
			color="primary"
			onClick={saveInvoice}
		>
			<SaveIcon className={classes.extendedIcon} />
			Opslaan
		</Fab>
	</div>
);

InvoiceSave.propTypes = {
	classes: PropTypes.object.isRequired,
	saveInvoice: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvoiceSave);
