import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BareInput from '../bareInput/BareInput';

const styles = {
	pickerContainer: {
		marginTop: '13px',
		marginBottom: '8px',
	},
	containerInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
};

const InvoiceForm = ({
	handleChange,
	quoteNumber,
	quoteDate,
	expirationDate,
	classes,
	editMode,
}) => (
	<div className={classes.containerInput}>
		<div>
			<Typography variant="subtitle2">Offerte Nr. </Typography>
			<BareInput
				id="quoteNumber"
				type="number"
				handleChange={handleChange}
				disabled={editMode}
				value={quoteNumber}
				field="quoteNumber"
			/>
		</div>
		<div>
			<TextField
				id="quoteDate"
				label="Offertedatum"
				value={quoteDate}
				onChange={handleChange('quoteDate')}
				margin="normal"
				InputLabelProps={{ shrink: true }}
				type="date"
				className="m-right-medium"
			/>
			<TextField
				id="expirationDate"
				label="Vervaldatum"
				value={expirationDate}
				onChange={handleChange('expirationDate')}
				margin="normal"
				InputLabelProps={{ shrink: true }}
				type="date"
			/>
		</div>
	</div>
);

InvoiceForm.propTypes = {
	handleChange: PropTypes.func.isRequired,
	quoteNumber: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
	quoteDate: PropTypes.string.isRequired,
	expirationDate: PropTypes.string.isRequired,
	editMode: PropTypes.bool.isRequired,
};

export default withStyles(styles)(InvoiceForm);
