import React from 'react';
import {
	withStyles,
	TextField,
	Button,
	Typography,
	Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { auth } from '../../services/firebase';
import { SIGNIN } from '../../constants/routes';

const styles = {
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
};

const INITIAL_STATE = {
	email: '',
	error: null,
};

class PasswordForgotten extends React.Component {
	state = { ...INITIAL_STATE };

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	onSubmit = (event) => {
		const { email } = this.state;

		auth
			.doPasswordReset(email)
			.then(() => {
				this.setState(() => ({ ...INITIAL_STATE }));
			})
			.catch((error) => {
				this.setState({ error });
			});
		event.preventDefault();
	};

	render() {
		const { email, error } = this.state;
		const { classes } = this.props;

		const isInvalid = email === '';

		return (
			<React.Fragment>
				<Typography variant="overline" className="m-bottom-small">
					AUTOBEDRIJF ADMINISTRATIE
				</Typography>
				<Typography variant="body2" className="m-bottom-small">
					Wachtwoord vergeten ?
				</Typography>
				<Typography variant="caption">
					Geen probleem, voer je email in en ontvang een link om je wachtwoord
					te resetten.
				</Typography>
				<form onSubmit={this.onSubmit} className={classes.formContainer}>
					<TextField
						id="email"
						label="E-Mail"
						variant="outlined"
						value={email}
						onChange={this.handleChange('email')}
						margin="normal"
						type="email"
					/>
					{error && <Typography>{error.message}</Typography>}

					<Button
						disabled={isInvalid}
						variant="contained"
						color="primary"
						type="submit"
						size="large"
					>
						Reset wachtwoord
					</Button>
				</form>
				<Link
					href={SIGNIN.path}
					variant="body2"
					color="secondary"
					className="m-top-xx-large text-center-imp center-center"
				>
					Mocht je hem weer herinneren, klik dan hier <ArrowRight />
				</Link>
			</React.Fragment>
		);
	}
}

PasswordForgotten.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordForgotten);
