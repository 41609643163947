import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Grid, Paper } from '@material-ui/core';
import AdresInfo from '../../../accountOverview/detailComponents/AdresInfo';
import ContactInfo from '../../../accountOverview/detailComponents/ContactInfo';
import FinancialInfo from '../../../accountOverview/detailComponents/FinancialInfo';
import Loading from '../../../loading/Loading';

const styles = {
	root: {
		height: '100%',
	},
	companyLogo: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '100%',
		width: '170px',
	},
	// flexContainer: {
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	flexDirection: 'row',
	// 	flexWrap: 'wrap',
	// 	flexFlow: 'row wrap',
	// 	alignContent: 'flex-end',
	// },
	flexContainer: {
		display: 'flex',
	},
	detailsContainer: {
		width: '100%',
	},
	flexDetailsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
};

const FinalConfirmStep = ({ account, loggedInUser, classes, loading }) => {
	if (loading) {
		return <Loading />;
	}
	return (
		<Grid item xs={12}>
			<Typography
				variant="subtitle1"
				component="div"
				className="m-bottom-small"
			>
				We zijn bijna klaar, kloppen je gegevens ?
			</Typography>

			<Paper className={classes.flexContainer}>
				<div>
					<div
						className={classes.companyLogo}
						style={{ backgroundImage: `url(${account.companyImage})` }}
					/>
				</div>

				<div className={`p-around-medium ${classes.detailsContainer}`}>
					<Typography variant="subtitle2">{account.name}</Typography>
					<Typography variant="body2">
						{`${loggedInUser.firstName} ${loggedInUser.lastName}`}
					</Typography>
					<div className={classes.flexDetailsContainer}>
						<div className="m-top-medium">
							<AdresInfo account={account} />
						</div>
						<div className="m-top-medium">
							<ContactInfo account={account} />
						</div>
						<div className="m-top-medium">
							<FinancialInfo account={account} />
						</div>
					</div>
				</div>
			</Paper>
		</Grid>
	);
};

FinalConfirmStep.propTypes = {
	account: PropTypes.object.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FinalConfirmStep);
