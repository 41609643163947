import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import EnhancedTableHead from './EnhancedTableHead';
import InvoicesRow from './InvoicesRow';
import ClientsRow from './ClientsRow';
import QuotesRow from './QuotesRow';
import ConfirmDeletionDialog from '../dialogs/confirmDeletion/ConfirmDeletionDialog';
import EnterPaymentDialog from '../dialogs/enterPaymentDialog/EnterPaymentDialog';
import InvoicePreviewDialog from '../dialogs/previews/InvoicePreviewDialog';
import ClientPreviewDialog from '../dialogs/previews/ClientPreviewDialog';
import QuotePreviewDialog from '../dialogs/previews/QuotePreviewDialog';
import SendEmailDialog from '../dialogs/sendEmailDialog/SendEmailDialog';
import CreateAndDownloadInvoice from '../../services/pdfService/CreateAndDownloadInvoice';
import CreateAndDownloadQuote from '../../services/pdfService/CreateAndDownloadQuote';
import calculateTotalPayment from '../../helpers/caclulateTotalPayment';

function desc(a, b, orderBy) {
	if (orderBy === 'label') {
		if (b.client.label < a.client.label) {
			return -1;
		}
		if (b.client.label > a.client.label) {
			return 1;
		}
	}
	if (orderBy === 'reedsBetaald') {
		if (calculateTotalPayment(b.payments) < calculateTotalPayment(a.payments)) {
			return -1;
		}
		if (calculateTotalPayment(b.payments) > calculateTotalPayment(a.payments)) {
			return 1;
		}
	}
	if (orderBy === 'status') {
		const statusA = calculateTotalPayment(a.payments) === a.totalInclVat;
		const statusB = calculateTotalPayment(b.payments) === b.totalInclVat;
		if (statusA < statusB) {
			return -1;
		}
		if (statusA > statusB) {
			return 1;
		}
		return 0;
	}
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc'
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 1020,
	},
	tableWrapper: {
		overflowX: 'auto',
	},
});
const CLOSE_ALL_DIALOGS = {
	openDeletionDialog: false,
	openPaymentDialog: false,
	openInvoicePreview: false,
	openQuotePreview: false,
	openClientPreview: false,
	invoiceToRender: false,
	openInvoiceGeneration: false,
	openEmailDialog: false,
	openQuoteGeneration: false,
};

class EnhancedTable extends React.Component {
	state = {
		order: 'desc',
		orderBy: 'invoiceNumber',
		selected: [],
		page: 0,
		rowsPerPage: 10,
		activeId: 0,
		invoiceToRender: {},
		...CLOSE_ALL_DIALOGS,
	};

	componentDidMount = () => {
		this.setSortOrder();
	};

	setSortOrder = () => {
		switch (this.props.datatype) {
			case 'invoices':
				this.setState({ orderBy: 'invoiceNumber' });
				break;
			case 'quotes':
				this.setState({ orderBy: 'quoteNumber' });
				break;
			default:
				this.setState({ orderBy: 'name' });
				break;
		}
	};

	handleClose = () => {
		this.setState({
			...CLOSE_ALL_DIALOGS,
			invoiceToRender: null,
			clientToPreview: null,
			activeId: null,
		});
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value });
	};

	handleOpenDeletionDialog = (invoice) => () => {
		this.setState({
			openDeletionDialog: true,
			activeId: invoice,
		});
	};

	handleOpenPreviewInvoiceDialog = (invoice) => () => {
		this.setState({
			invoiceToRender: invoice,
			openInvoicePreview: true,
		});
	};

	handleOpenQuotePreviewDialog = (quote) => () => {
		this.setState({
			quoteToRender: quote,
			openQuotePreview: true,
		});
	};

	handleOpenInvoicePDFGeneration = (invoice) => () => {
		this.setState({
			invoiceToRender: invoice,
			openInvoiceGeneration: true,
		});
	};

	handleOpenQuotePDFGeneration = (quote) => () => {
		this.setState({
			quoteToRender: quote,
			openQuoteGeneration: true,
		});
	};

	wipePDf = () => {
		this.setState({
			invoiceToRender: null,
			quoteToRender: null,
			openInvoiceGeneration: false,
			openQuoteGeneration: false,
		});
	};

	handleOpenPreviewClientDialog = (client) => () => {
		this.setState({
			clientToPreview: client,
			openClientPreview: true,
		});
	};

	handleOpenPaymentDialog = (invoice) => () => {
		this.setState({
			openPaymentDialog: true,
			invoiceToRender: invoice,
		});
	};

	handleOpenEmailDialog = (invoice) => () => {
		this.setState({
			openEmailDialog: true,
			invoiceToRender: invoice,
		});
	};

	render() {
		const {
			classes,
			data,
			rows,
			datatype,
			deleteDoc,
			doPayment,
			getNewInvoice,
			account,
		} = this.props;
		const {
			order,
			orderBy,
			selected,
			rowsPerPage,
			page,
			activeId,
			openDeletionDialog,
			openPaymentDialog,
			openInvoicePreview,
			openQuotePreview,
			invoiceToRender,
			clientToPreview,
			openClientPreview,
			quoteToRender,
			openInvoiceGeneration,
			openEmailDialog,
			openQuoteGeneration,
		} = this.state;
		const emptyRows =
			rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		return (
			<div className={classes.tableWrapper}>
				<div className={classes.table}>
					<EnhancedTableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={this.handleRequestSort}
						rowCount={data.length}
						rows={rows}
					/>
					<div>
						{datatype === 'invoices' &&
							stableSort(data, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((invoice) => (
									<InvoicesRow
										invoice={invoice}
										key={invoice.id}
										handleOpenDeletionDialog={this.handleOpenDeletionDialog}
										handleOpenPaymentDialog={this.handleOpenPaymentDialog}
										handleOpenPreviewInvoiceDialog={
											this.handleOpenPreviewInvoiceDialog
										}
										handleOpenInvoicePDFGeneration={
											this.handleOpenInvoicePDFGeneration
										}
										handleOpenEmailDialog={this.handleOpenEmailDialog}
									/>
								))}
						{datatype === 'clients' &&
							stableSort(data, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((client) => (
									<ClientsRow
										client={client}
										key={client.id}
										handleOpenDeletionDialog={this.handleOpenDeletionDialog}
										handleOpenPreviewClientDialog={
											this.handleOpenPreviewClientDialog
										}
									/>
								))}
						{datatype === 'quotes' &&
							stableSort(data, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((quote) => (
									<QuotesRow
										quote={quote}
										key={quote.id}
										handleOpenDeletionDialog={this.handleOpenDeletionDialog}
										handleOpenQuotePreviewDialog={
											this.handleOpenQuotePreviewDialog
										}
										handleOpenQuotePDFGeneration={
											this.handleOpenQuotePDFGeneration
										}
										handleOpenEmailDialog={this.handleOpenEmailDialog}
									/>
								))}
						{emptyRows > 0 && <div style={{ height: rowsPerPage * 20 }} />}
					</div>
				</div>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
				{openDeletionDialog && (
					<ConfirmDeletionDialog
						activeId={activeId}
						openDeletionDialog={openDeletionDialog}
						handleCloseDeletionDialog={this.handleClose}
						deleteDoc={deleteDoc}
						datatype={datatype}
					/>
				)}
				{openPaymentDialog && (
					<EnterPaymentDialog
						invoiceToRender={invoiceToRender}
						openPaymentDialog={openPaymentDialog}
						handleClosePaymentDialog={this.handleClose}
						doPayment={doPayment}
						getNewInvoice={getNewInvoice}
					/>
				)}
				{openInvoicePreview && (
					<InvoicePreviewDialog
						openInvoicePreview={openInvoicePreview}
						invoiceToRender={invoiceToRender}
						preview
						handleClosePreviewDialog={this.handleClose}
					/>
				)}
				{openQuotePreview && (
					<QuotePreviewDialog
						openQuotePreview={openQuotePreview}
						quoteToRender={quoteToRender}
						preview
						handleClosePreviewDialog={this.handleClose}
					/>
				)}
				{openClientPreview && (
					<ClientPreviewDialog
						openClientPreview={openClientPreview}
						clientToPreview={clientToPreview}
						handleClosePreviewDialog={this.handleClose}
					/>
				)}
				{openInvoiceGeneration && (
					<CreateAndDownloadInvoice
						invoiceToRender={invoiceToRender}
						account={account}
						wipePDf={this.wipePDf}
					/>
				)}
				{openQuoteGeneration && (
					<CreateAndDownloadQuote
						quoteToRender={quoteToRender}
						account={account}
						wipePDf={this.wipePDf}
					/>
				)}
				{openEmailDialog && (
					<SendEmailDialog
						openEmailDialog={openEmailDialog}
						invoiceToEmail={invoiceToRender}
						datatype={datatype}
						account={account}
						closeDialog={this.handleClose}
					/>
				)}
			</div>
		);
	}
}

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	rows: PropTypes.array.isRequired,
	datatype: PropTypes.string.isRequired,
	data: PropTypes.array,
	deleteDoc: PropTypes.func.isRequired,
	doPayment: PropTypes.func,
	getNewInvoice: PropTypes.func,
	account: PropTypes.object.isRequired,
};

EnhancedTable.defaultProps = {
	data: [],
	doPayment: () => console.log('function not passed'),
	getNewInvoice: () => console.log('function not passed'),
};

export default withStyles(styles)(EnhancedTable);
