import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InvoiceClientData from './InvoiceClientData';
import InvoiceCompanyData from './InvoiceCompanyData';

const InvoiceBaseData = ({
	company,
	client,
	handleChange,
	handleAddClientDialogClose,
	handleAddClientDialogOpen,
	addClientOpen,
	setClient,
	editMode,
	clients,
	vehicleType,
	licensePlate,
	milage,
	chassisNumber,
	datatype,
}) => (
	<Grid container>
		<Grid item xs={6}>
			<InvoiceCompanyData company={company} />
		</Grid>
		<Grid item xs={6} className="full-height">
			{/* <ClientCreateDialog
				handleAddClientDialogClose={handleAddClientDialogClose}
				addClientOpen={addClientOpen}
			/> */}
			<Grid
				justify="flex-end"
				container
				alignItems="flex-end"
				direction="column"
				className="full-height"
			>
				<InvoiceClientData
					client={client}
					clients={clients}
					editMode={editMode}
					setClient={setClient}
					handleChange={handleChange}
					handleAddClientDialogClose={handleAddClientDialogClose}
					handleAddClientDialogOpen={handleAddClientDialogOpen}
					addClientOpen={addClientOpen}
					vehicleType={vehicleType}
					licensePlate={licensePlate}
					milage={milage}
					chassisNumber={chassisNumber}
					datatype={datatype}
				/>
			</Grid>
		</Grid>
	</Grid>
);

InvoiceBaseData.propTypes = {
	company: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleAddClientDialogClose: PropTypes.func.isRequired,
	handleAddClientDialogOpen: PropTypes.func.isRequired,
	addClientOpen: PropTypes.bool.isRequired,
	setClient: PropTypes.func.isRequired,
	editMode: PropTypes.bool.isRequired,
	clients: PropTypes.array,
	vehicleType: PropTypes.string.isRequired,
	licensePlate: PropTypes.string.isRequired,
	milage: PropTypes.string.isRequired,
};

InvoiceBaseData.defaultProps = {
	clients: [],
};

export default InvoiceBaseData;
