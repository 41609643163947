import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	Tabs,
	Tab,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import calculateTotalPayment from '../../../helpers/caclulateTotalPayment';
import { formatCurrency } from '../../../utils/formatHelpers';
import PaymentHistoryTable from './PaymentHistoryTable';

const now = moment().format('YYYY-MM-DD');

class EnterPaymentDialog extends React.Component {
	state = {
		tabVal: 0,
		fullPayment: true,
		disabledFullPayment: false,
		paymentDate: now,
		receivedAmount: null,
		amountLeftToPay: 0,
	};

	componentDidMount = () => {
		const {
			invoiceToRender: { payments },
		} = this.props;
		this.calcAmountLeftToPay();
		if (payments.length > 0) {
			this.setState({
				tabVal: 1,
				disabledFullPayment: true,
				fullPayment: false,
			});
		}
	};

	handleChangeTab = (event, value) => {
		this.setState({ tabVal: value });
		if (value > 0) {
			this.setState({ fullPayment: false });
		} else {
			this.setState({ fullPayment: true });
		}
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	calcAmountLeftToPay = () => {
		const {
			invoiceToRender: { payments, totalInclVat },
		} = this.props;
		const alreadyPaid = calculateTotalPayment(payments);
		const amountLeftToPay = totalInclVat - alreadyPaid;
		const n = amountLeftToPay.toFixed(2);
		this.setState({ amountLeftToPay: n });
	};

	enterPayment = async () => {
		const {
			fullPayment,
			paymentDate,
			receivedAmount,
			amountLeftToPay,
		} = this.state;
		const {
			invoiceToRender: { totalInclVat, id },
			doPayment,
			getNewInvoice,
		} = this.props;
		const parsedReceivedAmount = parseFloat(receivedAmount);
		let payment = {};
		if (fullPayment) {
			payment = {
				amount: totalInclVat,
				paymentDate,
			};
		} else {
			payment = {
				amount: parsedReceivedAmount,
				paymentDate,
			};
		}
		if (parsedReceivedAmount > amountLeftToPay) {
			return console.log('Bedrag is groter dan te betalen bedrag');
		}
		try {
			await doPayment(payment, id);
			getNewInvoice('invoices', id, 'updated_invoice');
		} catch (error) {
			console.log(error);
		}
		return true;
	};

	isButtonDisabled = () => {
		const { fullPayment, receivedAmount } = this.state;
		if (fullPayment) {
			return false;
		}
		if (!fullPayment && receivedAmount > 0) {
			return false;
		}
		return true;
	};

	render() {
		const {
			openPaymentDialog,
			handleClosePaymentDialog,
			invoiceToRender: { payments },
		} = this.props;
		const {
			paymentDate,
			tabVal,
			receivedAmount,
			disabledFullPayment,
			amountLeftToPay,
		} = this.state;
		return (
			<Dialog
				open={openPaymentDialog}
				onClose={handleClosePaymentDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogContent>
					{amountLeftToPay > 0 ? (
						<React.Fragment>
							<div>
								<Tabs
									value={tabVal}
									onChange={this.handleChangeTab}
									indicatorColor="primary"
									textColor="primary"
									centered
								>
									<Tab
										label="Volledige betaling"
										disabled={disabledFullPayment}
									/>
									<Tab label="Deelbetaling" />
								</Tabs>
							</div>
							{tabVal === 0 && (
								<div className="p-around-medium">
									<Typography variant="caption">
										De factuur is in zijn geheel voldaan
									</Typography>
								</div>
							)}
							{tabVal === 1 && (
								<div className="p-around-medium">
									<Typography variant="caption">
										De factuur is gedeeltelijk voldaan
									</Typography>
									<Typography variant="caption">{`Het openstaandbedrag is : ${formatCurrency(
										amountLeftToPay
									)}`}</Typography>
									<TextField
										id="invoiceDate"
										label="Ontvangen bedrag"
										variant="outlined"
										value={receivedAmount}
										onChange={this.handleChange('receivedAmount')}
										margin="normal"
										placeholder="vul het bedrag in"
										InputLabelProps={{ shrink: true }}
										type="number"
									/>
								</div>
							)}
							<div className="m-left-medium">
								<TextField
									id="invoiceDate"
									variant="outlined"
									label="Betaling ontvangen op:"
									value={paymentDate}
									onChange={this.handleChange('paymentDate')}
									margin="normal"
									InputLabelProps={{ shrink: true }}
									type="date"
								/>
							</div>
						</React.Fragment>
					) : (
						<Typography variant="body2">
							Factuur is in zijn geheel voldaan
						</Typography>
					)}
					{payments.length > 0 && <PaymentHistoryTable payments={payments} />}
				</DialogContent>
				<DialogActions>
					{amountLeftToPay > 0 ? (
						<React.Fragment>
							<Button onClick={handleClosePaymentDialog} color="primary">
								Annuleren
							</Button>
							<Button
								onClick={this.enterPayment}
								color="primary"
								disabled={this.isButtonDisabled()}
							>
								Bevestigen
							</Button>
						</React.Fragment>
					) : (
						<Button onClick={handleClosePaymentDialog} color="primary">
							Sluiten
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

EnterPaymentDialog.propTypes = {
	handleClosePaymentDialog: PropTypes.func.isRequired,
	openPaymentDialog: PropTypes.bool.isRequired,
	invoiceToRender: PropTypes.object.isRequired,
	doPayment: PropTypes.func.isRequired,
	getNewInvoice: PropTypes.func.isRequired,
};

export default EnterPaymentDialog;
