import {
	updateObjectInArray,
	insertItem,
	removeItem,
} from '../../helpers/immutableHelpers';
// Don't forget to update the defaultState when adding new props
const defaultState = {
	clients: [],
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function clientsReducer(state = defaultState, action) {
	switch (action.type) {
		case 'GET_ALL_CLIENTS_DONE':
			return {
				...state,
				clients: action.data,
			};
		case 'NEW_CLIENT_DONE':
			return {
				...state,
				clients: insertItem(state.clients, action.data),
			};
		case 'UPDATED_CLIENT_DONE':
			return {
				...state,
				clients: updateObjectInArray(state.clients, action.data),
			};
		case 'DELETE_DOC_FROM_CLIENTS_DONE':
			return {
				...state,
				clients: removeItem(state.clients, action.data),
			};
		default:
			return state;
	}
}

export default clientsReducer;
