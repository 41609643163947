import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import * as actionCreators from '../../redux/actions/snackbarActions';

const styles = {
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	error: {
		backgroundColor: '#d32f2f !important',
	},
	success: {
		backgroundColor: '#43a047 !important',
	},
	info: {
		backgroundColor: '#1976d2 !important',
	},
};

const variantIcon = {
	success: CheckCircleIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

class SnackbarNotifications extends Component {
	state = {};

	render() {
		const {
			classes,
			variant,
			closeSnackbar,
			snackbarMessage,
			snackbarOpen,
		} = this.props;
		const Icon = variantIcon[variant];
		return (
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={() => closeSnackbar()}
				>
					<SnackbarContent
						className={classes[variant]}
						aria-describedby="client-snackbar"
						message={
							<span id="message-id" className={classes.message}>
								<Icon />
								{snackbarMessage}
							</span>
						}
						action={[
							<IconButton
								key="close"
								aria-label="Close"
								color="inherit"
								onClick={() => closeSnackbar()}
							>
								<CloseIcon />
							</IconButton>,
						]}
					/>
				</Snackbar>
			</div>
		);
	}
}

SnackbarNotifications.propTypes = {
	classes: PropTypes.object.isRequired,
	snackbarMessage: PropTypes.string.isRequired,
	snackbarOpen: PropTypes.bool.isRequired,
	closeSnackbar: PropTypes.func.isRequired,
	variant: PropTypes.string,
};

SnackbarNotifications.defaultProps = {
	variant: 'info',
};

const mapStateToProps = (state) => ({ ...state.Snackbar });

export function mapDispatchToProps(dispatch) {
	const mergedActionCreators = { ...actionCreators };
	return {
		...bindActionCreators(mergedActionCreators, dispatch),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SnackbarNotifications));
