import generateActionTypes from '../../utils/generateActionTypes';
import { dbf, firebase } from './firebase';
import { openSnackbar } from '../../redux/actions/snackbarActions';

export const saveToCollection = (
	data,
	collection,
	userId,
	actionName
) => async (dispatch) => {
	const actionTypes = generateActionTypes(actionName);
	dispatch({
		type: actionTypes.REQUEST,
	});
	const docRef = dbf.collection(collection);
	return docRef
		.add({
			owner: userId,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			...data,
		})
		.then((ref) => {
			dispatch(openSnackbar(' Opgeslagen', 'success'));
			return ref.id;
		})
		.catch((error) => {
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
			console.log(error);
		});
};

export const updateFirstTime = (userId) => {
	const docRef = dbf.collection('users').doc(userId);
	docRef
		.set(
			{
				firstTimeLogin: false,
			},
			{ merge: true }
		)
		.then(() => {
			console.log('success');
		})
		.catch((error) => {
			console.log(error);
		});
};

export const updateDocument = (data, collection, documentId) => async (
	dispatch
) => {
	const docRef = dbf.collection(collection).doc(documentId);
	docRef
		.set({ ...data }, { merge: true })
		.then(() => {
			dispatch(openSnackbar('Updated', 'success'));
		})
		.catch((error) => {
			// console.log('Error getting documents: ', error);
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};

export const deleteDocument = (collection, documentId, actionName) => async (
	dispatch
) => {
	const actionTypes = generateActionTypes(actionName);
	dispatch({
		type: actionTypes.REQUEST,
	});
	const docRef = dbf.collection(collection).doc(documentId);
	docRef
		.delete()
		.then(() => {
			dispatch({
				type: actionTypes.DONE,
				data: documentId,
			});
			dispatch(openSnackbar('Deleted', 'success'));
		})
		.catch((error) => {
			// console.log('Error getting documents: ', error);
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};

export const pushPayment = (payment, document) => async (dispatch) => {
	const docRef = dbf.collection('invoices').doc(document);
	console.log(payment);
	docRef
		.update({
			payments: firebase.firestore.FieldValue.arrayUnion(payment),
		})
		.then(() => {
			dispatch(openSnackbar('Payment success', 'success'));
		})
		.catch((error) => {
			// console.log('Error getting documents: ', error);
			dispatch(openSnackbar(`Error getting document: ${error}`, 'error'));
		});
};
