/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Fade, withStyles } from '@material-ui/core';
import lottie from 'lottie-web';
import officeAnimation from '../../../../static/lottieFiles/office.json';

const styles = {
	root: {
		minHeight: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	lottieContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

const WelcomeStep = ({ functionThatTakesTime, classes }) => {
	const [welcome, setFadeWelcome] = React.useState(false);
	const [welcomeAnimation, setFadeWelcomeAnimation] = React.useState(false);
	const [welcomeText, setFadeWelcomeText] = React.useState(false);
	const [startText, setStartText] = React.useState(false);

	function fadeWelcome() {
		setFadeWelcome(true);
	}

	function lottieDone() {
		const lottieContainer = document.getElementById('lottie');
		lottieContainer.innerHTML = '';
		lottie.loadAnimation({
			container: lottieContainer, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: officeAnimation, // the path to the animation json
		});
	}

	function fadeAnimation() {
		setFadeWelcomeAnimation(true);
		lottieDone();
	}

	function fadeText() {
		setFadeWelcomeText(true);
	}

	function fadeStart() {
		setStartText(true);
	}

	React.useEffect(() => {
		functionThatTakesTime(fadeWelcome, 400);
		functionThatTakesTime(fadeText, 800);
		functionThatTakesTime(fadeStart, 1600);
		functionThatTakesTime(fadeAnimation, 700);
		return () => {
			lottie.destroy();
		};
	}, []);

	return (
		<div className="p-left-xx-large p-right-xx-large flex-column">
			<Fade in={welcome} timeout={800}>
				<Typography variant="h4" className="text-center-imp">
					Welkom
				</Typography>
			</Fade>
			<Fade in={welcomeAnimation} timeout={900}>
				<div className={classes.lottieContainer}>
					<div
						style={{
							maxWidth: '300px',
							maxHeight: '300px',
							minWidth: '300px',
							minHeight: '300px',
						}}
					>
						<div id="lottie" />
					</div>
				</div>
			</Fade>
			<Fade in={welcomeText} timeout={1500}>
				<Typography variant="body1" className="m-top-small text-center-imp">
					In vier simpele stappen ben je straks gereed om te starten met het
					maken van je eerste factuur of offerte.
				</Typography>
			</Fade>
			<Fade in={startText} timeout={2500}>
				<Typography variant="caption" className="m-top-x-large text-center-imp">
					Druk op start om te beginnen
				</Typography>
			</Fade>
			{/* <div className={classes.root}>

			</div> */}
		</div>
	);
};

WelcomeStep.propTypes = {
	functionThatTakesTime: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default React.memo(withStyles(styles)(WelcomeStep));
