export function updateObjectInArray(array, data) {
	return array.map((item) => {
		if (item.id !== data.id) {
			// This isn't the item we care about - keep it as-is
			return item;
		}
		// Otherwise, this is the one we want - return an updated value
		return {
			...item,
			...data,
		};
	});
}

export function insertItem(array, data) {
	const newArray = array.slice();
	newArray.splice(0, 0, data);
	return newArray;
}

export function removeItem(array, id) {
	const index = array.findIndex((obj) => obj.id === id);
	const newArray = array.slice();
	newArray.splice(index, 1);
	return newArray;
}
