import axios from 'axios';

const API_PATH =
	'https://autobedrijfadministratie.nl/fatura/api/contact/index.php';

const sendMail = async (emailData, invoiceFile) => {
	try {
		const formData = new FormData();
		formData.append('accountName', emailData.accountName);
		formData.append('emailFrom', emailData.emailFrom);
		formData.append('emailTo', emailData.emailTo);
		formData.append('emailBody', emailData.emailBody);
		formData.append('clientLabel', emailData.clientLabel);
		formData.append('subject', emailData.subject);
		formData.append('totalInvoiceAmount', emailData.totalInvoiceAmount);
		formData.append('file', invoiceFile);
		formData.append('fileName', emailData.fileName);
		const response = await axios({
			method: 'post',
			url: `${API_PATH}`,
			headers: { 'content-type': 'multipart/form-data' },
			data: formData,
		});
		console.log(response);
	} catch (error) {
		console.log('error');
	}
};

export default sendMail;
