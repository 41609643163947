export const LOGIN_REQUEST_SENT = 'LOGIN_REQUEST_SENT';
export const loginRequestSent = (value) => ({
	type: LOGIN_REQUEST_SENT,
	value,
});

export const SIGN_OUT = 'SIGN_OUT';
export const doSignOut = () => ({
	type: SIGN_OUT,
});
