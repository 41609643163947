/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import BareInput from '../bareInput/BareInput';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		height: 250,
	},
	input: {
		display: 'flex',
		padding: 0,
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
});

function Menu({
	selectProps: {
		classes: { paper },
		handleAddClientDialogOpen,
	},
	innerProps,
	children,
}) {
	// Menu.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.object.isRequired,
	// };
	return (
		<Paper square className={paper} {...innerProps}>
			{children}
			<div className="center-center p-bottom-xx-small p-top-xx-small">
				<Button onClick={handleAddClientDialogOpen} variant="outlined">
					Nieuwe Klant
				</Button>
			</div>
		</Paper>
	);
}

function inputComponent({ inputRef, ...props }) {
	inputComponent.propTypes = {
		inputRef: PropTypes.func.isRequired,
	};
	return <div ref={inputRef} {...props} />;
}

function Control({
	selectProps: {
		classes: { input },
		textFieldProps,
	},
	innerRef,
	innerProps,
	children,
}) {
	// Control.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.array.isRequired,
	// 	innerRef: PropTypes.object.isRequired,
	// };
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: input,
					inputRef: innerRef,
					children,
					...innerProps,
				},
			}}
			{...textFieldProps}
		/>
	);
}
function Option({ innerRef, innerProps, isFocused, children, isSelected }) {
	// Option.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.object.isRequired,
	// 	innerRef: PropTypes.object.isRequired,
	// 	isSelected: PropTypes.bool.isRequired,
	// 	isFocused: PropTypes.bool.isRequired,
	// };
	return (
		<MenuItem
			buttonRef={innerRef}
			selected={isFocused}
			component="div"
			style={{
				fontWeight: isSelected ? 500 : 400,
			}}
			{...innerProps}
		>
			{children}
		</MenuItem>
	);
}

function NoOptionsMessage({
	selectProps: {
		classes: { noOptionsMessage },
	},
	innerProps,
	children,
}) {
	// NoOptionsMessage.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.object.isRequired,
	// };
	return (
		<Typography
			color="textSecondary"
			className={noOptionsMessage}
			{...innerProps}
		>
			{children}
		</Typography>
	);
}

function SingleValue({
	selectProps: {
		classes: { singleValue },
	},
	innerProps,
	children,
}) {
	// SingleValue.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.object.isRequired,
	// };
	return (
		<Typography className={singleValue} {...innerProps}>
			{children}
		</Typography>
	);
}

function ValueContainer({
	selectProps: {
		classes: { valueContainer },
	},

	children,
}) {
	// ValueContainer.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	children: PropTypes.object.isRequired,
	// };
	return <div className={valueContainer}>{children}</div>;
}

function Placeholder({
	selectProps: {
		classes: { placeholder },
	},
	innerProps,
	children,
}) {
	// Placeholder.propTypes = {
	// 	selectProps: PropTypes.object.isRequired,
	// 	innerProps: PropTypes.object.isRequired,
	// 	children: PropTypes.string.isRequired,
	// };
	return (
		<Typography color="textSecondary" className={placeholder} {...innerProps}>
			{children}
		</Typography>
	);
}

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

function renderForPreview(
	vehicleType,
	licensePlate,
	preview,
	handleChange,
	client,
	datatype,
	milage,
	chassisNumber
) {
	if (preview) {
		return (
			<React.Fragment>
				{vehicleType && (
					<div className="disp-flex">
						<Typography variant="subtitle2" className="m-right-xx-small">
							Voertuig:{' '}
						</Typography>
						<Typography>{vehicleType}</Typography>
					</div>
				)}
				{licensePlate && (
					<div className="disp-flex">
						<Typography variant="subtitle2" className="m-right-xx-small">
							Kenteken:{' '}
						</Typography>
						<Typography>{licensePlate}</Typography>
					</div>
				)}
				{milage && (
					<div className="disp-flex">
						<Typography variant="subtitle2" className="m-right-xx-small">
							Kilometerstand:{' '}
						</Typography>
						<Typography>{milage}</Typography>
					</div>
				)}
				{chassisNumber && (
					<div className="disp-flex">
						<Typography variant="subtitle2" className="m-right-xx-small">
							Chassisnummer:{' '}
						</Typography>
						<Typography>{chassisNumber}</Typography>
					</div>
				)}
			</React.Fragment>
		);
	}

	if (preview && !licensePlate && !vehicleType) {
		return null;
	}
	return (
		<React.Fragment>
			<div className="disp-flex">
				<Typography variant="subtitle2" className="m-right-xx-small">
					Voertuig:
				</Typography>
				<BareInput
					handleChange={handleChange}
					placeholder="voertuig type"
					value={vehicleType}
					field="vehicleType"
				/>
			</div>
			<div className="disp-flex">
				<Typography variant="subtitle2" className="m-right-xx-small">
					Kenteken:{' '}
				</Typography>
				<BareInput
					handleChange={handleChange}
					placeholder="xx-xx-xx"
					value={licensePlate}
					field="licensePlate"
				/>
			</div>
			{datatype && (
				<div className="disp-flex">
					<Typography variant="subtitle2" className="m-right-xx-small">
						Chassisnummer:{' '}
					</Typography>
					<BareInput
						handleChange={handleChange}
						placeholder="xxxxxxxxxxxx"
						value={chassisNumber}
						field="chassisNumber"
					/>
				</div>
			)}
			<div className="disp-flex">
				<Typography variant="subtitle2" className="m-right-xx-small">
					Kilometerstand:{' '}
				</Typography>
				<BareInput
					handleChange={handleChange}
					placeholder="123.000km"
					value={milage}
					field="milage"
				/>
			</div>
		</React.Fragment>
	);
}

const InvoiceClientData = ({
	client: { name, street, houseNumber, city, postalCode },
	handleChange,
	preview,
	classes,
	editMode,
	setClient,
	client,
	handleAddClientDialogOpen,
	clients,
	vehicleType,
	licensePlate,
	datatype,
	milage,
	chassisNumber,
}) => {
	const [single, setSingle] = React.useState(null);
	function handleChangeSingle(value) {
		setSingle(value);
	}
	const selectStyles = {
		input: (base) => ({
			...base,
			'& input': {
				font: 'inherit',
			},
		}),
	};

	const suggestions = clients.map((suggestion) => ({
		value: suggestion,
		label: suggestion.name,
	}));
	return (
		<div>
			{preview || editMode ? (
				client.value && (
					<React.Fragment>
						<Typography>{client.value.name}</Typography>
						<Typography>{`${client.value.street} ${client.value.houseNumber}`}</Typography>
						<Typography>{`${client.value.postalCode} ${client.value.city}`}</Typography>
					</React.Fragment>
				)
			) : (
				<React.Fragment>
					<Select
						classes={classes}
						styles={selectStyles}
						options={suggestions}
						components={components}
						value={client}
						onChange={setClient}
						placeholder="Selecteer Klant"
						handleAddClientDialogOpen={handleAddClientDialogOpen}
					/>
					<div className="m-bottom-small" />
					{client.value && (
						<React.Fragment>
							<Typography>{`${client.value.street} ${client.value.houseNumber}`}</Typography>
							<Typography>{`${client.value.postalCode} ${client.value.city}`}</Typography>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
			<div className="m-top-medium" />
			{renderForPreview(
				vehicleType,
				licensePlate,
				preview,
				handleChange,
				client,
				datatype,
				milage,
				chassisNumber
			)}
		</div>
	);
};

InvoiceClientData.propTypes = {
	client: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	setClient: PropTypes.func.isRequired,
	handleAddClientDialogOpen: PropTypes.func.isRequired,
	editMode: PropTypes.bool.isRequired,
	preview: PropTypes.bool,
	clients: PropTypes.array,
	classes: PropTypes.object.isRequired,
};

InvoiceClientData.defaultProps = {
	client: {},
	clients: [],
	preview: false,
};

export default withStyles(styles)(InvoiceClientData);
