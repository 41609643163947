import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import BareTextArea from '../bareInput/BareTextArea';

const InvoiceRemarks = ({ remarks, handleChange }) => (
	<Grid container>
		<Grid item xs={4}>
			<Typography variant="body2">Voeg een opmerking toe:</Typography>
			<BareTextArea
				value={remarks}
				handleChange={handleChange}
				placeholder="Opmerkingen"
				field="remarks"
				fullWidth
			/>
		</Grid>
	</Grid>
);

InvoiceRemarks.propTypes = {
	remarks: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default InvoiceRemarks;
