const colors = {
	primary: '#2c2c54',
	secondary: '#706fd3',
	text: '#ffffff',
	success: '#75a643',
	failure: '#f00',
	lightBackground: '#f5f5f5',
	pageBackground: '#fbfbfb',
	border: '#aaa',
	borderDark: '#666',
	white: '#fff',
	headerPaper: '#2c2c54',
	pending: '#FFC107',
};

export default colors;
