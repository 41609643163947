import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Grid, TextField, withStyles, Button } from '@material-ui/core';

const styles = {
	imageContainer: {
		width: '100%',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
	},
	uploadBoxContainer: {
		height: '100%',
		width: '100%',
	},
	placeholder: {
		height: '100%',
		width: '100%',
		border: '2px dashed #ddd',
		paddingTop: '40%',
		textAlign: 'center',
		color: '#0c0c0',
		fontSize: 40,
		backgroundSize: 'cover !important',
		backgroundPosition: 'cover',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		'&:hover': {
			backgroundImage: 'none !important',
		},
	},
};

const EditAccountDetailsForm = ({
	accountInfo: {
		name,
		companyImage,
		postalCode,
		tel,
		email,
		houseNumber,
		street,
		city,
		kvk,
		btw,
		iban,
		web,
	},
	classes,
	handleChange,
	handleChangeKeyValuePair,
}) => {
	let imageContainer;
	return (
		<Grid container spacing={8}>
			<Grid item xs={7}>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="name"
						label="Bedrijfsnaam"
						value={name}
						onChange={handleChange('name')}
						margin="normal"
						type="text"
					/>
				</Grid>

				<Grid container spacing={4}>
					<Grid xs={8} item>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="street"
							label="Straat"
							value={street}
							onChange={handleChange('street')}
							margin="normal"
							type="text"
						/>
					</Grid>
					<Grid xs={4} item>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="houseNumber"
							label="Huis Nr."
							value={houseNumber}
							onChange={handleChange('houseNumber')}
							margin="normal"
							type="number"
						/>
					</Grid>
				</Grid>

				<Grid container spacing={4}>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="postalCode"
							label="Postcode"
							value={postalCode}
							onChange={handleChange('postalCode')}
							margin="normal"
							type="text"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="city"
							label="Stad"
							value={city}
							onChange={handleChange('city')}
							margin="normal"
							type="text"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							id="email"
							readOnly
							label="E-mail"
							value={email}
							onChange={handleChange('email')}
							margin="normal"
							type="text"
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="telephone"
							label="Telefoonnummer"
							value={tel}
							onChange={handleChange('tel')}
							margin="normal"
							type="text"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="website"
							label="Website"
							value={web}
							onChange={handleChange('web')}
							margin="normal"
							type="text"
						/>
					</Grid>
				</Grid>

				<Grid container spacing={4}>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="kvk"
							label="KVK nummer"
							value={kvk}
							onChange={handleChange('kvk')}
							margin="normal"
							type="text"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="btw"
							label="BTW nummer"
							value={btw}
							onChange={handleChange('btw')}
							margin="normal"
							type="text"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							id="iban"
							label="Rekening nummer IBAN"
							value={iban}
							onChange={handleChange('iban')}
							margin="normal"
							type="text"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={5}>
				<Button
					color="primary"
					className="m-bottom-xx-small"
					onClick={() => handleChangeKeyValuePair('companyImage', null)}
				>
					Verwijder afbeelding
				</Button>
				<Dropzone
					accept="image/jpeg, image/png, image/bmp"
					className={classes.uploadBoxContainer}
					onDrop={(accepted) => {
						imageContainer.style.backgroundImage = `url(${accepted[0].preview})`;
						imageContainer.style.backgroundSize = 'contain';
						handleChangeKeyValuePair('companyImage', accepted[0]);
					}}
				>
					<div
						ref={(el) => {
							imageContainer = el;
						}}
						className={classes.placeholder}
						style={
							companyImage && {
								backgroundImage: `url(${companyImage})`,
							}
						}
					>
						+
					</div>
				</Dropzone>
			</Grid>
		</Grid>
	);
};

EditAccountDetailsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleChangeKeyValuePair: PropTypes.func.isRequired,
	accountInfo: PropTypes.shape({
		name: PropTypes.string,
		street: PropTypes.string,
		houseNumber: PropTypes.string,
		city: PropTypes.string,
		postalCode: PropTypes.string,
		tel: PropTypes.string,
		email: PropTypes.string,
		web: PropTypes.string,
		kvk: PropTypes.string,
		iban: PropTypes.string,
	}).isRequired,
};

export default withStyles(styles)(EditAccountDetailsForm);
