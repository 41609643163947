import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
	Stepper,
	Step,
	StepLabel,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	Fade,
	withStyles,
} from '@material-ui/core';
import WelcomeStep from './steps/WelcomeStep';
import CompanyInfoStep from './steps/CompanyInfoStep';
import FinancialInfoStep from './steps/FinancialInfoStep';
import FinalConfirmStep from './steps/FinalConfirmStep';
import ContactInfoStep from './steps/ContactInfoStep';
import UploadLogoStep from './steps/UploadLogoStep';

const styles = {
	dialogContent: {
		minHeight: '300px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const FirstTimeStepper = ({
	classes,
	steps,
	activeStep,
	handleBack,
	handleNext,
	handleChange,
	handleChangeKeyValuePair,
	name,
	street,
	postalCode,
	city,
	houseNumber,
	tel,
	email,
	web,
	kvk,
	btw,
	iban,
	loggedInUser,
	companyImage,
	readyForNext,
	loading,
}) => {
	const [buttonFade, setButtonFade] = React.useState(false);

	const functionThatTakesTime = (callback, time) =>
		new Promise((resolve) => {
			setTimeout(() => {
				callback();
				resolve(true);
			}, time);
		});

	function fadeButtons() {
		setButtonFade(true);
	}

	function formAccountObject() {
		let account = {
			name,
			street,
			postalCode,
			city,
			houseNumber,
			tel,
			email,
			web,
			kvk,
			btw,
			iban,
		};
		if (companyImage) {
			account = { ...account, companyImage: companyImage.preview };
		}

		return account;
	}

	function getButtonLabel(currentStep, allSteps) {
		if (currentStep === allSteps.length - 1) {
			return 'Bevestigen';
		}
		if (currentStep === 0) {
			return 'Start';
		}
		return 'Volgende';
	}

	React.useEffect(() => {
		functionThatTakesTime(fadeButtons, 2000);
	});

	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return <WelcomeStep functionThatTakesTime={functionThatTakesTime} />;
			case 1:
				return (
					<CompanyInfoStep
						handleChange={handleChange}
						name={name}
						street={street}
						postalCode={postalCode}
						city={city}
						houseNumber={houseNumber}
					/>
				);
			case 2:
				return (
					<ContactInfoStep
						handleChange={handleChange}
						email={email}
						web={web}
						tel={tel}
					/>
				);
			case 3:
				return (
					<FinancialInfoStep
						kvk={kvk}
						btw={btw}
						iban={iban}
						handleChange={handleChange}
					/>
				);
			case 4:
				return (
					<UploadLogoStep
						handleChangeKeyValuePair={handleChangeKeyValuePair}
						companyImage={companyImage}
					/>
				);
			case 5:
				return (
					<FinalConfirmStep
						account={formAccountObject()}
						loggedInUser={loggedInUser}
						loading={loading}
					/>
				);
			default:
				return 'Something went wrong';
		}
	}
	const stepCheck = activeStep !== 0;
	return (
		<React.Fragment>
			{stepCheck && (
				<DialogTitle>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</DialogTitle>
			)}
			<DialogContent
				className={cn(
					{
						'p-left-xx-large': activeStep > 0,
					},
					classes.dialogContent
				)}
			>
				{getStepContent(activeStep)}
			</DialogContent>
			<Fade in={buttonFade}>
				<DialogActions>
					<div>
						{activeStep > 1 && (
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className="m-right-medium"
							>
								terug
							</Button>
						)}
						<Button
							variant="contained"
							color="primary"
							onClick={handleNext}
							disabled={readyForNext(activeStep)}
						>
							{getButtonLabel(activeStep, steps)}
						</Button>
					</div>
				</DialogActions>
			</Fade>
		</React.Fragment>
	);
};

FirstTimeStepper.propTypes = {
	classes: PropTypes.object.isRequired,
	steps: PropTypes.array.isRequired,
	activeStep: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	street: PropTypes.string.isRequired,
	postalCode: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
	houseNumber: PropTypes.string.isRequired,
	tel: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	web: PropTypes.string.isRequired,
	kvk: PropTypes.string.isRequired,
	iban: PropTypes.string.isRequired,
	btw: PropTypes.string.isRequired,
	handleBack: PropTypes.func.isRequired,
	readyForNext: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	handleChangeKeyValuePair: PropTypes.func.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	companyImage: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FirstTimeStepper);
