import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const styles = {
	paperContainer: {
		margin: '10px',
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: colors.headerPaper,
	},
};

const ActionPaper = ({
	action,
	actionName,
	children,
	title,
	classes,
	className,
}) => (
	<Paper>
		<div className={`${classes.headerContainer} p-around-medium	`}>
			<Typography
				variant="h6"
				component="p"
				color="secondary"
				className="text-color"
			>
				{title}
			</Typography>
			{actionName && (
				<Button
					variant="outlined"
					onClick={action}
					className="text-color"
					style={{ backgroundColor: '#8BC34A' }}
				>
					{actionName}
				</Button>
			)}
		</div>
		<div className={className}>{children}</div>
	</Paper>
);

ActionPaper.propTypes = {
	action: PropTypes.func,
	actionName: PropTypes.string,
	children: PropTypes.any.isRequired,
	title: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
};

ActionPaper.defaultProps = {
	action: () => {},
	actionName: '',
	className: '',
};

export default withStyles(styles)(ActionPaper);
