import {
	updateObjectInArray,
	insertItem,
	removeItem,
} from '../../helpers/immutableHelpers';

// Don't forget to update the defaultState when adding new props
const defaultState = {
	quotes: [],
};

// The cases where a string is checked, are the generated actions from the graphql.js file.
// The other actions are defined in the imports.

export function quotesReducer(state = defaultState, action) {
	switch (action.type) {
		case 'GET_ALL_QUOTES_DONE':
			return {
				...state,
				quotes: action.data,
			};
		case 'NEW_QUOTE_DONE':
			return {
				...state,
				quotes: insertItem(state.quotes, action.data),
			};
		case 'UPDATED_QUOTES_DONE':
			return {
				...state,
				quotes: updateObjectInArray(state.quotes, action.data),
			};
		case 'DELETE_DOC_FROM_QUOTES_DONE':
			return {
				...state,
				quotes: removeItem(state.quotes, action.data),
			};
		default:
			return state;
	}
}

export default quotesReducer;
