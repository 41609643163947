import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../loading/Loading';
import NoAccess from './NoAccess';
import { SIGNIN } from '../../constants/routes';

class AccessControl extends React.Component {
	state = {
		loading: true,
		permitted: false,
		redirectedToSignIn: false,
	};

	componentDidMount = () => {
		this.checkPermissions();
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.checkedForAuth !== this.props.checkedForAuth) {
			this.checkPermissions();
		}
	};

	checkPermissions = () => {
		const { authUser, checkedForAuth, history } = this.props;
		if (authUser && checkedForAuth) {
			this.setState({ loading: false, permitted: true });
		}
		if (!authUser && checkedForAuth) {
			this.setState({ redirectedToSignIn: true });
			history.push(SIGNIN.path);
		}
	};

	render() {
		const { children } = this.props;
		const { loading, permitted, redirectedToSignIn } = this.state;

		if (permitted && !loading) {
			return children;
		}
		if (!permitted && !loading) {
			return <NoAccess />;
		}
		if (redirectedToSignIn) {
			return null;
		}

		return <Loading />;
	}
}

AccessControl.propTypes = {
	children: PropTypes.node.isRequired,
	authUser: PropTypes.object,
	checkedForAuth: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
};

AccessControl.defaultProps = {
	authUser: null,
};

export default AccessControl;
