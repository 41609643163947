import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	noAccessContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '16px',
		minHeight: '100vh',
		justifyContent: 'space-around',
	},
};

const NoAccess = ({ classes }) => (
	<div className={classes.noAccessContainer} id="noAccess">
		<Typography variant="h6">No Access</Typography>
	</div>
);

NoAccess.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoAccess);
