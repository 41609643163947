import calculateTotalPayment from '../helpers/caclulateTotalPayment';

export function calculateTotalInvoiced(invoices) {
	const reducer = (sum, invoice) => sum + invoice.totalInclVat;
	return invoices.reduce(reducer, 0);
}

export function calculateTotalReceivedPayments(invoices) {
	const filteredArray = invoices.filter(
		(invoice) => invoice.payments.length > 0
	);

	const reducer = (sum, invoice) => sum + invoice.payments[0].amount;
	return filteredArray.reduce(reducer, 0);
}

export function numberOfExpiredInvoices(invoices) {
	return invoices.filter(
		(invoice) =>
			new Date(invoice.expirationDate) < Date.now() &&
			calculateTotalPayment(invoice.payments) < invoice.totalInclVat
	);
}
