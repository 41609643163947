import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const FinancialInfo = ({ account }) => {
	return (
		<React.Fragment>
			<Typography variant="subtitle2">Financiele gegevens:</Typography>
			<Typography variant="caption">
				{account.iban}
				<br />
				{account.btw}
				<br />
				{account.kvk}
			</Typography>
		</React.Fragment>
	);
};

FinancialInfo.propTypes = {
	account: PropTypes.object.isRequired,
};

export default FinancialInfo;
