import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import InvoiceClientData from '../../createInvoiceComponents/InvoiceClientData';
import InvoiceCompanyData from '../../createInvoiceComponents/InvoiceCompanyData';
import InvoicePreviewTable from './InvoicePreviewTable';

const styles = {
	clientData: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	companyData: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
};

const InvoicePreviewDialog = ({
	invoiceToRender,
	openInvoicePreview,
	handleClosePreviewDialog,
	classes,
	account,
}) => (
	<Dialog
		open={openInvoicePreview}
		onClose={handleClosePreviewDialog}
		fullWidth
		maxWidth="md"
	>
		{openInvoicePreview && (
			<DialogContent>
				<div>
					<div className={classes.companyData}>
						<InvoiceCompanyData company={account} preview />
					</div>
					<div className={classes.clientData}>
						<InvoiceClientData
							client={invoiceToRender.client}
							preview
							handleChange={() => {}}
							licensePlate={invoiceToRender.licensePlate}
							vehicleType={invoiceToRender.vehicleType}
							chassisNumber={invoiceToRender.chassisNumber}
							milage={invoiceToRender.milage}
						/>
					</div>
					<div>
						<InvoicePreviewTable
							invoiceItems={invoiceToRender.invoiceItems}
							totalExVat={invoiceToRender.totalExVat}
							totalInclVat={invoiceToRender.totalInclVat}
							totalVatHigh={invoiceToRender.totalVatHigh}
							totalVatMarge={invoiceToRender.totalVatMarge}
							totalVatLow={invoiceToRender.totalVatLow}
						/>
					</div>
					<Typography variant="caption" className="m-top-medium">
						{invoiceToRender.remarks}
					</Typography>
				</div>
			</DialogContent>
		)}
	</Dialog>
);

InvoicePreviewDialog.propTypes = {
	invoiceToRender: PropTypes.object,
	classes: PropTypes.object.isRequired,
	openInvoicePreview: PropTypes.bool.isRequired,
	handleClosePreviewDialog: PropTypes.func.isRequired,
	account: PropTypes.object,
};

InvoicePreviewDialog.defaultProps = {
	invoiceToRender: {},
	account: {},
};

const mapStateToProps = (state) => ({
	account: state.Session.account,
});

export default connect(
	mapStateToProps,
	null
)(withStyles(styles)(InvoicePreviewDialog));
