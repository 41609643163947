import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDeletionDialog = ({
	openDeletionDialog,
	handleCloseDeletionDialog,
	datatype,
	activeId,
	deleteDoc,
}) => {
	function determineLabel() {
		switch (datatype) {
			case 'invoices':
				return 'factuur';
			case 'clients':
				return 'klant';
			case 'quotes':
				return 'offerte';
			default:
				return 'factuur';
		}
	}

	async function doDelete() {
		await deleteDoc(datatype, activeId, `delete_doc_from_${datatype}`);
		handleCloseDeletionDialog();
	}

	return (
		<Dialog open={openDeletionDialog} onClose={handleCloseDeletionDialog}>
			<DialogTitle id="alert-dialog-title">
				{`Weet u zeker dat u deze ${determineLabel()} wilt verwijderen?`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{`Als u een ${determineLabel()} verwijderd kan deze niet meer hersteld worden, alle informatie
					zal verwijderd worden.`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDeletionDialog} color="primary">
					Annuleren
				</Button>
				<Button onClick={doDelete} color="primary" autoFocus>
					Verwijderen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ConfirmDeletionDialog.propTypes = {
	openDeletionDialog: PropTypes.bool.isRequired,
	handleCloseDeletionDialog: PropTypes.func.isRequired,
	datatype: PropTypes.string.isRequired,
	activeId: PropTypes.string.isRequired,
	deleteDoc: PropTypes.func.isRequired,
};

export default ConfirmDeletionDialog;
