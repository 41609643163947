import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	text: {
		fontSize: 10,
		marginBottom: 2,
		fontFamily: 'Lato',
		fontWeight: 200,
	},
	textBold: {
		fontSize: 10,
		marginBottom: 5,
		fontFamily: 'Lato',
		fontStyle: 'bold',
		fontWeight: 700,
	},
	viewMargin: {
		marginTop: 10,
		marginBottom: 10,
	},
});

const InvoiceRemarks = ({ invoiceToRender: { remarks } }) => {
	if (remarks) {
		return (
			<View style={styles.viewMargin}>
				<Text style={styles.textBold}>Bijzonderheden: </Text>
				<Text style={styles.text}>{remarks}</Text>
			</View>
		);
	}
	return <View />;
};

InvoiceRemarks.propTypes = {
	invoiceToRender: PropTypes.object.isRequired,
};

export default InvoiceRemarks;
