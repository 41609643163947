import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.primary,
			contrastText: colors.text,
		},
		secondary: {
			main: colors.secondary,
			contrastText: colors.text,
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#fff',
		},
	},
});

export default theme;
