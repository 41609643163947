import React from 'react';
import { Grid, Fade, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const CompanyInfoStep = ({
	handleChange,
	name,
	street,
	postalCode,
	city,
	houseNumber,
}) => {
	return (
		<Fade in timeout={200}>
			<Grid container>
				<Grid item xs={8}>
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						fullWidth
						id="name"
						label="Bedrijfsnaam"
						value={name}
						onChange={handleChange('name')}
						margin="normal"
						type="text"
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid xs={6} item>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="street"
								label="Straat"
								value={street}
								onChange={handleChange('street')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid xs={2} item>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="houseNumber"
								label="Huis Nr."
								value={houseNumber}
								onChange={handleChange('houseNumber')}
								margin="normal"
								type="number"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="postalCode"
								label="Postcode"
								value={postalCode}
								onChange={handleChange('postalCode')}
								margin="normal"
								type="text"
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								fullWidth
								id="city"
								label="Stad"
								value={city}
								onChange={handleChange('city')}
								margin="normal"
								type="text"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Fade>
	);
};

CompanyInfoStep.propTypes = {
	handleChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	street: PropTypes.string.isRequired,
	postalCode: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
	houseNumber: PropTypes.string.isRequired,
};

export default CompanyInfoStep;
