import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PreviewIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/CreateOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';
import { EDITCLIENT } from '../../constants/routes';

const styles = {
	tableRow: {
		minHeight: '64px',
	},
	tableItem: {
		flex: '1 0 auto',
	},
	icons: {
		fontSize: '1px',
		padding: '3px',
	},
};

const ClientsRow = ({
	client,
	classes,
	handleOpenDeletionDialog,
	history,
	handleOpenPreviewClientDialog,
}) => {
	const buttons = [
		{
			title: 'Preview',
			icon: <PreviewIcon fontSize="default" />,
			action: handleOpenPreviewClientDialog(client),
		},
		{
			title: 'Bewerken',
			icon: <EditIcon fontSize="default" />,
			action: () => history.push(`${EDITCLIENT.pathWithoutParam}/${client.id}`),
		},
		{
			title: 'Verwijderen',
			icon: <DeleteIcon fontSize="default" />,
			action: handleOpenDeletionDialog(client.id),
		},
	];
	return (
		<React.Fragment>
			<Grid
				container
				className={`p-around-medium table-row ${classes.tableRow}`}
				alignItems="center"
			>
				<Grid item xs={4}>
					<Typography variant="body2">{client.name}</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="body2">{`${client.street} ${
						client.houseNumber
					}`}</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="body2">{client.email}</Typography>
				</Grid>
				<Grid item xs={1}>
					<div className="action-buttons-row">
						{buttons.map((button) => (
							<IconButton
								key={button.title}
								color="inherit"
								className={classes.icons}
								onClick={button.action}
							>
								<Tooltip title={button.title}>{button.icon}</Tooltip>
							</IconButton>
						))}
					</div>
				</Grid>
			</Grid>
			<Divider />
		</React.Fragment>
	);
};

ClientsRow.propTypes = {
	client: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	handleOpenDeletionDialog: PropTypes.func.isRequired,
	handleOpenPreviewClientDialog: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ClientsRow));
