import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Grid } from '@material-ui/core';
import { openSnackbar } from '../redux/actions/snackbarActions';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';
import { LANDING, SETTINGS } from '../constants/routes';
import AccountDetails from '../components/accountOverview/AccountDetails';
import EditAccountDetails from '../components/dialogs/editAccountDetails/EditAccountDetails';
import { updateDocument } from '../services/firebase/firebaseMutations';
import { getDocument } from '../services/firebase/firebaseQueries';
import { storageRef } from '../services/firebase/firebase';

const styles = {
	root: {
		flexGrow: 1,
	},
};

class Settings extends React.Component {
	state = {
		editMode: false,
		accountInfo: {
			name: '',
			companyImage: '',
			postalCode: '',
			tel: '',
			email: '',
			houseNumber: '',
			street: '',
			city: '',
			kvk: '',
			btw: '',
			iban: '',
			web: '',
		},
	};

	toggleEditMode = () => {
		const { account } = this.props;
		this.setState((prevState) => ({
			accountInfo: { ...account },
			editMode: !prevState.editMode,
		}));
	};

	handleChange = (name) => (event) => {
		const { accountInfo } = this.state;
		this.setState({
			accountInfo: {
				...accountInfo,
				[name]: event.target.value,
			},
		});
	};

	handleChangeKeyValuePair = (key, value) => {
		const { accountInfo } = this.state;
		this.setState({
			accountInfo: {
				...accountInfo,
				[key]: value,
			},
		});
	};

	updateAccountDetails = async () => {
		const {
			updateAccountDetails,
			account: { id, companyImage },
			getAccountInfo,
		} = this.props;
		const { accountInfo } = this.state;
		let updatedAccountInfo = accountInfo;
		if (!Object.values(accountInfo).some((value) => value === '')) {
			if (
				accountInfo.companyImage !== companyImage &&
				accountInfo.companyImage !== null
			) {
				await storageRef
					.child(`${id}/companyImage.jpg`)
					.put(accountInfo.companyImage);
				const url = await storageRef
					.child(`${id}/companyImage.jpg`)
					.getDownloadURL();
				updatedAccountInfo = { ...accountInfo, companyImage: url };
			}
			updateAccountDetails(updatedAccountInfo, 'organisations', id);
			this.toggleEditMode();
			getAccountInfo('organisations', id, 'UPDATE_ACCOUNT_INFO');
		}
	};

	componentDidMount = () => {
		const { account } = this.props;
		this.setState({
			accountInfo: {
				...account,
			},
		});
	};

	componentDidUpdate = (prevProps) => {
		const { account } = this.props;
		if (prevProps.account !== account) {
			this.setState({
				accountInfo: { ...account },
			});
		}
	};

	render() {
		const { account, classes, loggedInUser } = this.props;
		const { editMode, accountInfo } = this.state;
		return (
			<div className={`${classes.root} m-top-large`}>
				<Breadcrumbs
					pathArray={[
						{
							link: LANDING.path,
							title: LANDING.label,
						},
						{
							link: SETTINGS.path,
							title: SETTINGS.label,
						},
					]}
				/>
				<Grid container spacing={4}>
					<Grid item xs={5}>
						{account && loggedInUser && (
							<AccountDetails
								account={account}
								loggedInUser={loggedInUser}
								toggleEditMode={this.toggleEditMode}
							/>
						)}
					</Grid>
				</Grid>
				<EditAccountDetails
					editMode={editMode}
					toggleEditMode={this.toggleEditMode}
					accountInfo={accountInfo}
					handleChange={this.handleChange}
					handleChangeKeyValuePair={this.handleChangeKeyValuePair}
					updateAccountDetails={this.updateAccountDetails}
				/>
			</div>
		);
	}
}

Settings.propTypes = {
	account: PropTypes.object,
	classes: PropTypes.object.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	updateAccountDetails: PropTypes.func.isRequired,
	getAccountInfo: PropTypes.func.isRequired,
};

Settings.defaultProps = {
	account: null,
};

const mapStateToProps = (state) => ({
	authUser: state.Session.authUser,
	loggedInUser: state.Session.loggedInUser,
	account: state.Session.account,
	clients: state.Clients.clients,
});

const mapDispatchToProps = (dispatch) => ({
	openSnackbar: (message, variant) => dispatch(openSnackbar(message, variant)),
	updateAccountDetails: (data, collection, userId) =>
		dispatch(updateDocument(data, collection, userId)),
	getAccountInfo: (collection, userId, actionName) =>
		dispatch(getDocument(collection, userId, actionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Settings));
